import React from 'react';
import { Card, CardContent, Grid, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useStyles } from './ComplianceVideo.styles';
import { Link } from 'react-router-dom';

interface IComplianceVideoProps {
  title: string;
  src: string;
  buttonLink: string;
  buttonLabelKey: string;
}

export const ComplianceVideo = (props: IComplianceVideoProps) => {
  const { buttonLink, buttonLabelKey } = props;
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container justify="center">
          <Button className={classes.button} size="large" component={Link} to={buttonLink}>
            <FormattedMessage id={buttonLabelKey} />
          </Button>
        </Grid>
      </CardContent>
      {/* <CardMedia>
        <div className={classes.container}>
          <iframe title={title} src={src} frameBorder="0" allowFullScreen className={classes.video} />
        </div>
      </CardMedia> */}
    </Card>
  );
};
