import React, { FC, useContext, useMemo, useState, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserContext } from '../../Context/UserContext/UserContext';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Select } from '../';
import { entityToSelectOption } from '../../Utilities';
import { SelectOption } from '../Form/Form.types';
import { ValueType } from 'react-select';
import { useStyles } from '../Field/Field.styles';

interface IProps {
  open: boolean;
  urlTemplate: string;
  close: () => void;
  title: ReactNode;
  selectPlaceholder: ReactNode;
}

export const CompanyUnitSelectDialog: FC<IProps> = ({ open, title, selectPlaceholder, close, urlTemplate }) => {
  const { companyUnits, getCompanyUnitName } = useContext(UserContext);
  const { formatMessage } = useIntl();

  const [selectedCompanyUnit, setSelectedCompanyUnit] = useState<ValueType<SelectOption>>();
  const classes = useStyles();

  const companyUnitUrl = useMemo(() => {
    const unit = selectedCompanyUnit as SelectOption;
    if (unit?.value) {
      return urlTemplate.replace('{id}', unit.value.toString());
    }
    return '';
  }, [selectedCompanyUnit, urlTemplate]);

  const disableLink = useMemo(() => (selectedCompanyUnit as SelectOption)?.value === undefined, [selectedCompanyUnit]);

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{overflowY: "visible"}}>
        <Box component="form" style={{ display: 'flex', flexWrap: 'wrap', minHeight: '120px' }}>
          <FormControl className={classes.formControl}>
            <Typography className={classes.label} component="label">
              {`${getCompanyUnitName(formatMessage)}`}
            </Typography>
            <Select
              selectProps={{
                isClearable: false,
                options: companyUnits.map(entityToSelectOption),
                placeholder: selectPlaceholder,
                onChange: setSelectedCompanyUnit,
              }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          <FormattedMessage id="form.cancel" />
        </Button>
        <Button disabled={disableLink} component={Link} to={companyUnitUrl}>
          <FormattedMessage id="form.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
