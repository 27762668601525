import React, { FC, useContext, useState } from 'react';
import { EditablePanel, Panel } from '../../../../../Components';
import { FormValues, SelectOption } from '../../../../../Components/Form/Form.types';
import { ComplianceIntervalView } from './View';
import { ComplianceIntervalForm } from './Form';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../Context/UserContext/UserContext';
import { AptorApi } from '../../../../../Api';
import { IGridQueryRequest, IGridQueryResult } from '../../../../../Api/AptorApi';

export interface IComplianceInterval {
  companyUnitId: number;
  companyUnitName: string;
  start: Date;
  end: Date;
}

interface IProps {
  titleKey: string;
  companyUnits: SelectOption[];
  dataFetcher: (request: IGridQueryRequest) => Promise<IGridQueryResult<IComplianceInterval>>;
  editInterval: (unitId: number, form: FormValues, api: AptorApi, onSuccess: () => void) => Promise<void>;
  deleteInterval: (unitId: number) => Promise<void>;
}

export const ComplianceInterval: FC<IProps> = ({
  titleKey,
  companyUnits,
  dataFetcher,
  editInterval,
  deleteInterval,
}) => {
  const { hasAccessToFeature } = useContext(UserContext);
  const canEditIntervals = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  const [revision, setRevision] = useState<number>(0);
  const [selectedInterval, setSelectedInterval] = useState<IComplianceInterval>();

  const submitInterval = (form: FormValues, api: AptorApi, onSuccess: () => void) => {
    var unitId = selectedInterval ? selectedInterval.companyUnitId : (form['unit'] as number);
    return editInterval(unitId, form, api, onSuccess).then((_) => setSelectedInterval(undefined));
  };
  const onDeleteInterval = (unitId: number) => deleteInterval(unitId).then(() => setRevision(revision + 1));
  const closeCreateForm = () => setRevision(revision + 1);
  const closeEditForm = () => setSelectedInterval(undefined);

  if (canEditIntervals) {
    if (selectedInterval) {
      return (
        <Panel titleKey={titleKey}>
          <ComplianceIntervalForm
            interval={selectedInterval}
            units={companyUnits}
            submit={submitInterval}
            close={closeEditForm}
          />
        </Panel>
      );
    }

    return (
      <EditablePanel
        titleKey={titleKey}
        iconType="add"
        view={
          <ComplianceIntervalView
            key={revision}
            dataFetcher={dataFetcher}
            onDelete={onDeleteInterval}
            onEdit={setSelectedInterval}
          />
        }
        form={<ComplianceIntervalForm units={companyUnits} submit={submitInterval} close={closeCreateForm} />}
        dependencies={[revision]}
      />
    );
  }

  return (
    <Panel titleKey={titleKey}>
      <ComplianceIntervalView dataFetcher={dataFetcher} />
    </Panel>
  );
};
