import { makeStyles } from '@material-ui/core';
import theme from '../../Theme/theme';

export const useStyles = makeStyles(() => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));
