import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { CreateLawComplianceReport } from './Create';
import { LawCompliance as LawCompliancePage } from './Compliance';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../../Context/UserContext/UserContext';

export const LawCompliance = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const user = useContext(UserContext);
  const isManager = user.hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  const isComplianceUser = user.complianceUserFor.laws.some((x) => x.id === id);
  if (!isManager && !isComplianceUser) return <Redirect to={`/law-portal/our-laws/${id}`} />;
  return (
    <Switch>
      <Route path="/law-portal/our-laws/:id(\d+)/compliance/report" component={CreateLawComplianceReport} />
      <Route path="/law-portal/our-laws/:id(\d+)/compliance" component={LawCompliancePage} />
    </Switch>
  );
};
