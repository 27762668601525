import React, { useContext } from 'react';
import { IListItemLinkProps } from '../../../../Components/ListItemLink/ListItemLink';
import { Sidebar, ISidebarProps } from '../../../../Components/Sidebar/Sidebar';
import { Dashboard, Gavel, FormatListBulleted, BarChart, Settings } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../Context/UserContext/UserContext';

export const LawPortalSidebar = (props: ISidebarProps) => {
  const { hasAccessToFeature } = useContext(UserContext);
  const accessToOurLaws = hasAccessToFeature(LawPortalFeature.OurLaws, AccessLevel.Read);
  const accessToOtherRequirements = hasAccessToFeature(LawPortalFeature.OtherRequirements, AccessLevel.Read);
  const { formatMessage } = useIntl();

  const items: IListItemLinkProps[] = [];
  items.push({
    to: '/law-portal/dashboard',
    text: formatMessage({ id: 'law-portal.dashboard.header', defaultMessage: 'Dashboard' }),
    icon: <Dashboard />,
  });

  if (accessToOurLaws) {
    items.push({
      to: '/law-portal/our-laws',
      text: formatMessage({ id: 'law-portal.our-laws.header', defaultMessage: 'Our laws' }),
      icon: <Gavel />,
    });
  }

  if (accessToOtherRequirements) {
    items.push({
      to: '/law-portal/other-requirements',
      text: formatMessage({ id: 'law-portal.requirements.header', defaultMessage: 'Other Requirements' }),
      icon: <FormatListBulleted />,
    });
  }

  items.push({
    to: '/law-portal/statistics',
    text: formatMessage({ id: 'law-portal.statistics.header', defaultMessage: 'Statistics' }),
    icon: <BarChart />,
  });
  items.push({
    to: '/law-portal/settings',
    text: formatMessage({ id: 'law-portal.settings.header', defaultMessage: 'Settings' }),
    icon: <Settings />,
  });

  return (
    <Sidebar
      title={formatMessage({ id: 'component.home.law-portal', defaultMessage: 'Lawportal' })}
      items={items}
      sidebarOpen={props.sidebarOpen}
      handleSidebarToggle={props.handleSidebarToggle}
    />
  );
};
