import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useAptorApi } from '../../../../Api';
import { Loading } from '../../../../Components';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArrowForward } from '@material-ui/icons';

interface ILawChange {
  lawId: number;
  lawName: string;
}

interface IDashboardResult {
  changes: ILawChange[];
}

export const NewLawChangesWidget = () => {
  const { api, abortController } = useAptorApi();
  const { formatMessage } = useIntl();
  const [changes, setChanges] = useState<{ lawId: number; lawName: string }[]>();
  useEffect(() => {
    const init = async () => {
      const result = await api.getDashboardChanges<IDashboardResult>();
      if (abortController.current.signal.aborted) {
        return;
      }
      setChanges(result.changes);
    };
    init();
  }, [api, abortController]);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h3">
            <FormattedMessage id="law-portal.dashboard.new-law-changes"></FormattedMessage>
          </Typography>
        }
      />
      <CardContent>
        {(changes === undefined && <Loading />) || (
          <List>
            {changes?.map((change) => (
              <ListItem component={Link} to={'/law-portal/our-laws/' + change.lawId} button key={change.lawId}>
                <ListItemText primary={change.lawName}></ListItemText>
                <ListItemSecondaryAction>
                  <Tooltip title={formatMessage({ id: 'law-portal.dashboard.new-law-changes.showLaw' })}>
                    <IconButton component={Link} to={'/law-portal/our-laws/' + change.lawId} edge="end">
                      <ArrowForward />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};
