import React from 'react';
import { IconButton, Menu, MenuItem, Hidden, Button } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { getAuthProvider } from '../../../../Auth/AuthProvider';
import { useStyles } from './LogoutButton.styles';

export const LogoutButton = () => {
  const handleLogout = () => {
    const authProvider = getAuthProvider();
    localStorage.clear();
    authProvider.logout();
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <>
      <Hidden smDown>
        {/* Desktop */}
        <Button onClick={handleLogout} className={classes.root}>
          <FormattedMessage id="component.sidebar.logout" defaultMessage="Log out" description="Label for Logout-button" />
        </Button>
      </Hidden>
      <Hidden mdUp>
        {/* Tablet & Mobile */}
        <div className={classes.menu}>
          <IconButton
            className={classes.iconButton}
            onClick={handleClick}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null} //Needed because of: https://github.com/mui-org/material-ui/issues/7961
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleLogout}>
              <FormattedMessage id="component.sidebar.logout" defaultMessage="Log out" description="Label for Logout-button" />
            </MenuItem>
          </Menu>
        </div>
      </Hidden>
    </>
  );
};
