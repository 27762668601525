import React from 'react';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useStyles } from './Home.styles';

export const ServerError = () => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.heading} variant="h1" align="center" color="primary" gutterBottom>
        <FormattedMessage
          id="component.home.serverError"
          defaultMessage="Temporary server error"
          description="Temporary server error message"
        />
      </Typography>
      <Typography className={classes.subHeading} variant="h2" align="center">
        <FormattedMessage
          id="component.home.contactAdministrator"
          defaultMessage="Please contact an administrator"
          description="Contact administrator message"
        />
      </Typography>
    </>
  );
};
