import React, { FC } from 'react';
import { Grid, FormControl, Typography } from '@material-ui/core';
import moment from 'moment';
import { useStyles } from '../../../../../Components/Field/Field.styles';

interface IFieldProps {
  label: string;
  value: string;
}
const Field: FC<IFieldProps> = ({ label, value }) => {
  const classes = useStyles();
  return (
    <FormControl>
      <Typography className={classes.label} component="label">
        {label}
      </Typography>
      <Typography className={classes.value} paragraph>
        {value}
      </Typography>
    </FormControl>
  );
};

interface IProps {
  name: string;
  number: string;
  effectiveFrom?: Date;
  category: { name: string };
  area: { name: string };
  tags: Array<{ name: string }>;
  country: { name: string };
}

export const EditLawInformationView: FC<IProps> = ({ name, number, effectiveFrom, category, area, tags, country }) => {
  return (
    <Grid container>
      <Grid item sm={6} xs={12}>
        <Grid container direction="column">
          <Field label="Namn" value={name} />
          <Field label="Område" value={area.name} />
          <Field label="Taggar" value={tags.map((t) => t.name).join(', ')} />
        </Grid>
      </Grid>
      <Grid item sm xs={12}>
        <Grid container direction="column">
          <Field label="Nummer" value={number} />
          <Field label="Kategori" value={category.name} />
        </Grid>
      </Grid>
      <Grid item sm xs={12}>
        <Grid container direction="column">
          <Field label="Träder i kraft" value={moment(effectiveFrom).format('ll')} />
          <Field label="Land" value={country.name} />
        </Grid>
      </Grid>
    </Grid>
  );
};
