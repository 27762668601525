import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Typography } from '@material-ui/core';
import { HtmlView } from '..';
import { useStyles } from './OwnNotes.styles';
import { INamedEntity } from '../../Api/AptorApi';

interface IProps {
  companyUnits: Array<INamedEntity & { note: string | null }>;
  linkTo?: string;
}

export const OwnNotes: FC<IProps> = ({ companyUnits, linkTo }) => {
  const classes = useStyles();
  const hasMore = companyUnits.filter((x) => x.note).length > 1;
  const firstNote = companyUnits.find((x) => x.note);

  if (!firstNote) {
    return null;
  }

  return (
    <>
      <Typography variant="overline" component="h4">
        {firstNote.name}
      </Typography>
      <HtmlView variant="body2" value={firstNote.note!} />
      {hasMore &&
        ((linkTo && (
          <Typography variant="body2">
            <Link href={linkTo}>
              <FormattedMessage
                id="component.own-notes.seeMore"
                defaultMessage="View more"
                description="View other notes"
              />
            </Link>
          </Typography>
        )) || (
          <Typography color="primary" variant="body2" className={classes.fakeLink}>
            <FormattedMessage
              id="component.own-notes.hasMore"
              defaultMessage="View more"
              description="View other notes"
            />
          </Typography>
        ))}
    </>
  );
};
