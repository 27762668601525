import React, { FC, useContext, useEffect, useRef } from 'react';
import { OptionsType } from 'react-select';
import { useIntl } from 'react-intl';
import { Panel, Form } from '../../../../Components';
import { FormValues, SelectOption } from '../../../../Components/Form/Form.types';
import { useFormField } from '../../../../Components/Form/FormField';
import { UserContext } from '../../../../Context/UserContext/UserContext';
import { AptorApi } from '../../../../Api';
import { INamedEntity } from '../../../../Api/AptorApi';

export interface ICompanyUnit extends INamedEntity {
  users: INamedEntity[];
}

interface IProps {
  isNew: boolean;
  units: OptionsType<SelectOption>;
  users: OptionsType<SelectOption>;
  dataFetcher: () => Promise<ICompanyUnit>;
  handleSubmit: (data: FormValues, api: AptorApi) => Promise<void>;
}

export const CompanyUnitEditForm: FC<IProps> = ({ isNew, units, users, dataFetcher, handleSubmit }) => {
  const initFlag = useRef(false);
  const { formatMessage } = useIntl();
  const { getCompanyUnitName } = useContext(UserContext);

  const nameField = useFormField({
    label: formatMessage({ id: 'form.name' }),
    name: 'units',
    type: isNew ? 'multi-select' : 'readonly',
    options: units,
    required: true,
  });
  const setUnitName = nameField.setValue;

  const usersField = useFormField({
    label: formatMessage({ id: 'law-portal.manage.edit-law.complianceUsers' }),
    name: 'complianceUsers',
    type: 'multi-select',
    options: users,
    required: false,
  });
  const setUnitResponsible = usersField.setValue;

  //Initialization
  useEffect(() => {
    const initializeFields = async () => {
      initFlag.current = true;
      const info = await dataFetcher();
      setUnitName({ value: info.name });
      setUnitResponsible(
        info.users.map((x) => {
          return { label: x.name, value: x.id };
        }),
      );
    };
    if (!isNew && !initFlag.current) {
      initializeFields();
    }
  }, [isNew, dataFetcher, setUnitName, setUnitResponsible]);

  return (
    <Panel
      accordion={!isNew}
      titleKey={isNew ? 'law-portal.manage.edit-law.addCompanyUnit' : 'law-portal.manage.edit-law.editCompanyUnit'}
      titleValues={{ alias: getCompanyUnitName(formatMessage, true) }}
    >
      <Form
        submit={handleSubmit}
        groups={[
          {
            grid: {
              type: 'row',
              items: [{ type: 'row', items: [nameField, usersField] }],
            },
          },
        ]}
      />
    </Panel>
  );
};
