import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container, Form, Panel } from '../../Components';
import { useFormField } from '../Form/FormField';
import { FormValues } from '../Form/Form.types';
import { AptorApi, useAptorApi } from '../../Api';
import { UserContext } from '../../Context/UserContext/UserContext';
import { useApiValidationSnackbar } from '../../Api/useValidationSnackbar';
import { Typography } from '@material-ui/core';
import { useStyles } from './Home.styles';

export const EnterName = () => {
  const classes = useStyles();
  const { firstName, lastName, fetchUser } = useContext(UserContext);
  const { notifyUnhandledException, notifyValidationErrors } = useApiValidationSnackbar();
  const { formatMessage } = useIntl();

  const { abortController } = useAptorApi();

  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => {
    const action = (a: AptorApi) =>
      a.updateName(data).then(() => {
        fetchUser(true);
      });

    await api.invoke(action, abortController.current, notifyValidationErrors, notifyUnhandledException);
  };

  const firstNameField = useFormField({
    label: formatMessage({ id: 'form.firstName' }),
    name: 'firstName',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInFirstName' }),
    required: true,
    initialState: { value: firstName },
  });

  const lastNameField = useFormField({
    label: formatMessage({ id: 'form.lastName' }),
    name: 'lastName',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInLastName' }),
    required: true,
    initialState: { value: lastName },
  });

  return (
    <>
      <Typography className={classes.heading} variant="h1" align="center" color="primary" gutterBottom>
        <FormattedMessage id="component.home.welcome" defaultMessage="Start page" description="Welcome message" />!
      </Typography>
      <Container>
        <Panel titleKey="component.home.enterName">
          <Form
            submit={handleSubmit}
            submitLabel={formatMessage({ id: 'form.save' })}
            groups={[
              {
                grid: {
                  type: 'column',
                  items: [{ type: 'row', items: [firstNameField, lastNameField, null] }],
                },
              },
            ]}
          />
        </Panel>
      </Container>
    </>
  );
};
