import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Container, Panel, Form } from '../../../../Components';
import { FormValues, SelectOption } from '../../../../Components/Form/Form.types';
import { BackButton } from '../../../../Components';
import { useFormField } from '../../../../Components/Form/FormField';
import { AptorApi } from '../../../../Api';
import { OptionsType } from 'react-select';

interface IProps {
  languages: OptionsType<SelectOption>;
  countries: OptionsType<SelectOption>;
  features: OptionsType<SelectOption>;
  submit: (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => Promise<void>;
}

export const CreateCustomerForm: FC<IProps> = ({ languages, features, countries, submit }) => {
  const { formatMessage } = useIntl();

  //Company information
  const companyField = useFormField({
    label: formatMessage({ id: 'form.company' }),
    name: 'company',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInCompany' }),
    required: true,
  });

  const organizationNumberField = useFormField({
    label: formatMessage({ id: 'form.organizationNumber' }),
    name: 'organizationNr',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInOrganizationNumber' }),
    required: true,
  });

  const contactPersonField = useFormField({
    label: formatMessage({ id: 'form.contactPerson' }),
    name: 'contactPerson',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInPersonName' }),
    required: true,
  });

  const companyAddressField = useFormField({
    label: formatMessage({ id: 'form.visitingAddress' }),
    name: 'companyAddress',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInAddress' }),
    required: false,
  });

  const cityField = useFormField({
    label: formatMessage({ id: 'form.city' }),
    name: 'city',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInCity' }),
    required: false,
  });

  const postalCodeField = useFormField({
    label: formatMessage({ id: 'form.postalCode' }),
    name: 'postalCode',
    type: 'postalCode',
    placeholder: formatMessage({ id: 'form.fillInPostalCode' }),
    required: false,
  });

  const nrCompanyUnitsField = useFormField({
    label: formatMessage({ id: 'form.nrCompanyUnits' }),
    name: 'numberOfAllowedCompanyUnits',
    type: 'integer',
    placeholder: formatMessage({ id: 'form.fillInCompanyUnits' }),
    required: false,
  });

  const nrUsersField = useFormField({
    label: formatMessage({ id: 'form.nrUsers' }),
    name: 'numberOfAllowedUsers',
    type: 'integer',
    placeholder: formatMessage({ id: 'form.fillInUsers' }),
    required: false,
  });

  //Billing information
  const billingAddressField = useFormField({
    label: formatMessage({ id: 'form.billingAddress' }),
    name: 'billingAddress',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInAddress' }),
    required: true,
  });

  const billingEmailField = useFormField({
    label: formatMessage({ id: 'form.billingEmail' }),
    name: 'billingEmail',
    type: 'email',
    placeholder: formatMessage({ id: 'form.fillInEmail' }),
    required: false,
  });

  const billingCityField = useFormField({
    label: formatMessage({ id: 'form.city' }),
    name: 'billingCity',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInCity' }),
    required: true,
  });

  const billingPostalCodeField = useFormField({
    label: formatMessage({ id: 'form.postalCode' }),
    name: 'billingPostalCode',
    type: 'postalCode',
    placeholder: formatMessage({ id: 'form.fillInPostalCode' }),
    required: true,
  });

  const referencePersonField = useFormField({
    label: formatMessage({ id: 'form.referencePerson' }),
    name: 'referencePerson',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInPersonName' }),
    required: true,
  });

  const referencePhoneNumberField = useFormField({
    label: formatMessage({ id: 'form.phoneNumber' }),
    name: 'referencePhoneNumber',
    type: 'phoneNumber',
    placeholder: formatMessage({ id: 'form.fillInPhoneNumber' }),
    required: false,
  });

  //Administrator's information
  const firstNameField = useFormField({
    label: formatMessage({ id: 'form.firstName' }),
    name: 'firstName',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInFirstName' }),
    required: true,
  });

  const lastNameField = useFormField({
    label: formatMessage({ id: 'form.lastName' }),
    name: 'lastName',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInLastName' }),
    required: true,
  });

  const emailField = useFormField({
    label: formatMessage({ id: 'form.email' }),
    name: 'email',
    type: 'email',
    placeholder: formatMessage({ id: 'form.fillInEmail' }),
    required: true,
  });

  const languageField = useFormField({
    label: formatMessage({ id: 'form.language' }),
    name: 'language',
    type: 'single-select',
    placeholder: formatMessage({ id: 'form.selectLanguage' }),
    options: languages,
    initialState: languages[0],
    required: true,
  });

  const customerAccess = useFormField({
    label: '',
    name: `selectedModuleFeatures`,
    type: 'multi-select',
    options: features,
    initialState: features as SelectOption[],
    required: false,
  });

  const selectedCountries = useFormField({
    label: formatMessage({ id: 'form.countries' }),
    placeholder: formatMessage({ id: 'form.selectCountries' }),
    name: `selectedCountries`,
    type: 'multi-select',
    options: countries,
    required: true,
  });

  return (
    <Container>
      <BackButton link="/admin/customers" />
      <Panel titleKey="admin.customers.addFormTitle">
        <Form
          submit={submit}
          submitLabel={formatMessage({ id: 'form.save' })}
          groups={[
            {
              label: 'admin.customers.information',
              grid: {
                type: 'column',
                items: [
                  { type: 'row', items: [companyField, organizationNumberField] },
                  { type: 'row', items: [contactPersonField, companyAddressField] },
                  { type: 'row', items: [cityField, postalCodeField] },
                  { type: 'row', items: [nrCompanyUnitsField, nrUsersField] },
                  { type: 'row', items: [selectedCountries, null] },
                ],
              },
            },
            {
              label: 'admin.customers.billing',
              grid: {
                type: 'column',
                items: [
                  { type: 'row', items: [billingAddressField, billingEmailField] },
                  { type: 'row', items: [billingCityField, billingPostalCodeField] },
                  { type: 'row', items: [referencePersonField, referencePhoneNumberField] },
                ],
              },
            },
            {
              label: 'admin.customers.access',
              grid: {
                type: 'column',
                items: [customerAccess],
              },
            },
            {
              label: 'admin.customers.firstUser',
              grid: {
                type: 'column',
                items: [
                  { type: 'row', items: [firstNameField, lastNameField] },
                  { type: 'row', items: [emailField, languageField] },
                ],
              },
            },
          ]}
        />
      </Panel>
    </Container>
  );
};
