import { useRef, useEffect } from 'react';

export const useApiAbort = () => {
    const abortController = useRef<AbortController>(new AbortController());

    useEffect(() => {
        const abortCtrl = abortController.current;
        return () => {
            abortCtrl.abort();
        };
    }, []);

    return abortController;
};

export default useApiAbort;
