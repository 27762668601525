import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  input: {
    alignItems: 'flex-end',
  },
  adornment: {
    height: 'auto',
  },
  textField: {
    width: '100%',
  },
  actions: {
    paddingLeft: theme.spacing(2), //Align timestamp with comment text
  },
  userName: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));
