import React, { FC, memo, useCallback } from 'react';
import clsx from 'clsx';
import { ListItemText, ListItem, ListItemSecondaryAction, IconButton, Tooltip } from '@material-ui/core';
import { Delete, VisibilityOff, Visibility } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { useStyles } from './Checklist.Question.styles';

interface IQuestionBaseProps {
  name: string;
  hidden?: boolean;
  action: React.ReactNode;
}

const QuestionBase: FC<IQuestionBaseProps> = memo(({ name, hidden, action }) => {
  const classes = useStyles();
  return (
    <ListItem className={clsx(classes.root, { [classes.hidden]: hidden })} classes={{ container: classes.container }}>
      <ListItemText>{name}</ListItemText>
      <ListItemSecondaryAction>{action}</ListItemSecondaryAction>
    </ListItem>
  );
});

interface ILawQuestionProps {
  id: number;
  name: string;
  hidden: boolean;
  toggleHidden: (questionId: number, groupId: number | undefined, hidden: boolean) => void;
}

export const LawQuestion: FC<ILawQuestionProps> = memo(({ id, name, hidden, toggleHidden }) => {
  const { formatMessage } = useIntl();
  const buttonTranslationKey = hidden
    ? 'law-portal.manage.checklist.question.show-button'
    : 'law-portal.manage.checklist.question.hide-button';
  const buttonIcon = hidden ? <VisibilityOff /> : <Visibility />;
  const onToggleHidden = useCallback(() => toggleHidden(id, undefined, !hidden), [id, hidden, toggleHidden]);

  return (
    <QuestionBase
      name={name}
      hidden={hidden}
      action={
        <Tooltip title={formatMessage({ id: buttonTranslationKey })}>
          <IconButton onClick={onToggleHidden} aria-label={formatMessage({ id: buttonTranslationKey })}>
            {buttonIcon}
          </IconButton>
        </Tooltip>
      }
    />
  );
});

interface ICustomerQuestionProps {
  index: number;
  name: string;
  remove: (index: number) => void;
}

export const CustomerQuestion: FC<ICustomerQuestionProps> = memo(({ index, name, remove }) => {
  const { formatMessage } = useIntl();
  const onRemove = useCallback(() => remove(index), [index, remove]);
  return (
    <QuestionBase
      name={name}
      action={
        <Tooltip title={formatMessage({ id: 'utils.icon.delete' })}>
          <IconButton aria-label={formatMessage({ id: 'utils.icon.delete' })} onClick={onRemove}>
            <Delete />
          </IconButton>
        </Tooltip>
      }
    />
  );
});
