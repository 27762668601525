import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Container } from '../../../../Components/Container/Container';
import { Query } from 'material-table';
import MTable from '../../../../Components/MTable/MTable';
import { IGridQueryRequest, OrderDirection } from '../../../../Api/AptorApi';
import { useAptorApi } from '../../../../Api';
import { UserContext, AdminFeature, AccessLevel } from '../../../../Context/UserContext/UserContext';
import moment from 'moment';
import { useConfirmation } from '../../../../Context/ConfirmationContext/ConfirmationContext';
import { CustomerListFilter } from './Filter';
import { useFilter } from '../../../../Components/ListFilter/useFilter';

export type CustomerData = {
  id: number;
  savedate: string;
  name: string;
  url: string;
  usersnr: number;
  lastlogin: string;
  active: boolean;
};

export const CustomerTable = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { api, abortController } = useAptorApi();
  const { hasAccessToFeature } = useContext(UserContext);
  const [filters, setFilter] = useFilter();

  const hasCreateAccess = hasAccessToFeature(AdminFeature.Administrate, AccessLevel.Manage);
  const tableRef = React.createRef();

  const { deleteConfirmation } = useConfirmation();

  const inactivateConfirmation = (customerName: string) => ({
    title: formatMessage({ id: 'admin.customers.inactivate.label' }),
    description: formatMessage({ id: 'admin.customers.inactivate.confirmation' }, { customerName }),
  });

  const activateConfirmation = (customerName: string) => ({
    title: formatMessage({ id: 'admin.customers.activate.label' }),
    description: formatMessage({ id: 'admin.customers.activate.confirmation' }, { customerName }),
  });

  const handleDataFetch = useCallback(
    async (query: Query<CustomerData>) => {
      return await api.searchCustomers<CustomerData>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  const handleEditCustomer = (id: number) => () => {
    history.push(`/admin/customers/${id}`);
  };

  const toggleCustomerActivation = (id: number, activate: boolean, tableRef: any) => async () => {
    await api.toggleCustomerActivation(id, activate);
    if (abortController.current.signal.aborted) {
      return;
    }

    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  useEffect(() => {
    (tableRef.current as any).onQueryChange();
  }, [filters, tableRef]);

  const handleActivateCustomer = (id: number, name: string) => () => {
    deleteConfirmation(toggleCustomerActivation(id, true, tableRef.current as any), activateConfirmation(name));
  };

  const handleInactivateCustomer = (id: number, name: string) => () => {
    deleteConfirmation(toggleCustomerActivation(id, false, tableRef.current as any), inactivateConfirmation(name));
  };

  return (
    <Container
      fullWidth
      linkButtonTo={hasCreateAccess ? '/admin/customers/create' : undefined}
      linkButtonLabel={hasCreateAccess ? 'admin.customers.button.addCustomer' : undefined}
      secondaryLinkButtonTo={hasCreateAccess ? '/admin/customers/reinvite' : undefined}
      secondaryLinkButtonLabel={hasCreateAccess ? 'admin.customers.resendInviteToUser' : undefined}
    >
      <CustomerListFilter setFilter={setFilter} />
      <MTable
        tableRef={tableRef}
        style={{ width: '100%' }}
        columns={[
          {
            title: formatMessage({ id: 'admin.customers.table.created' }),
            field: 'savedate',
            render: (data) => (data.savedate ? moment(data.savedate).format('lll UTC') : undefined),
          },
          { title: formatMessage({ id: 'admin.customers.table.customer' }), field: 'name' },
          { title: formatMessage({ id: 'admin.customers.table.usersNr' }), field: 'usersnr' },
          {
            title: formatMessage({ id: 'admin.customers.table.lastLogin' }),
            field: 'lastlogin',
            render: (data) =>
              data.lastlogin ? (
                moment(data.lastlogin).format('lll UTC')
              ) : (
                <i>{formatMessage({ id: 'admin.users.table.never' })}</i>
              ),
          },
        ]}
        data={handleDataFetch}
        actions={
          hasAccessToFeature(AdminFeature.Administrate, AccessLevel.Manage)
            ? [
                (row) => ({
                  tooltip: formatMessage({ id: 'admin.customers.table.editing' }),
                  icon: 'edit',
                  onClick: handleEditCustomer(row.id),
                }),
                (row) =>
                  row.active === true
                    ? {
                        tooltip: formatMessage({ id: 'admin.customers.inactivate.label' }),
                        icon: 'block',
                        onClick: handleInactivateCustomer(row.id, row.name),
                      }
                    : {
                        tooltip: formatMessage({ id: 'admin.customers.activate.label' }),
                        icon: 'check',
                        onClick: handleActivateCustomer(row.id, row.name),
                      },
              ]
            : undefined
        }
      />
    </Container>
  );
};
