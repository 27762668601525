import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import { DescriptionOutlined } from '@material-ui/icons';
import { IState } from './CustomerLaws.Update.State';
import { green, red } from '@material-ui/core/colors';

interface IProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  state: IState;
}

export const UpdateLawConnectionsDialog: FC<IProps> = ({ open, onClose, onSubmit, state }) => {
  const { formatMessage } = useIntl();
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <FormattedMessage
          id="law-portal.manage.update-laws.update.dialog.title"
          values={{
            type: state.type
              ? formatMessage({
                  id: `law-portal.manage.update-laws.type.options.${state.type?.key}`,
                }).toLowerCase()
              : undefined,
            connection: state.connection?.name,
            em: (connectionName: any) => <em>{connectionName}</em>,
          }}
        />
      </DialogTitle>
      <DialogContent>
        {state.connectionAddedToLaws.length > 0 && (
          <List>
            <ListSubheader>
              <Typography>
                <FormattedMessage id="law-portal.manage.update-laws.update.dialog.added-laws.message" />
              </Typography>
            </ListSubheader>
            {state.connectionAddedToLaws.map((law) => (
              <ListItem key={law.id}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: green[200] }} variant="rounded">
                    <DescriptionOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>{`${law.number} - ${law.name}`}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
        {state.connectionRemovedFromLaws.length > 0 && (
          <List>
            <ListSubheader>
              <Typography>
                <FormattedMessage id="law-portal.manage.update-laws.update.dialog.removed-laws.message" />
              </Typography>
            </ListSubheader>
            {state.connectionRemovedFromLaws.map((law) => (
              <ListItem key={law.id}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: red[200] }} variant="rounded">
                    <DescriptionOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>{`${law.number} - ${law.name}`}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          <FormattedMessage id="form.cancel" />
        </Button>
        <Button onClick={onSubmit}>
          <FormattedMessage id="form.save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
