import React, { useContext, useState, useEffect } from 'react';
import Form from '../../../Form/Form';
import { useAptorApi } from '../../../../Api/useAptorApi';
import { UserContext } from '../../../../Context/UserContext/UserContext';
import { useStyles } from './ImpersonationForm.styles';
import { useIntl } from 'react-intl';
import { Hidden, Divider } from '@material-ui/core';
import { useFormField } from '../../../Form/FormField';
import { SelectOption } from '../../../Form/Form.types';

export const ImpersonationForm = () => {
  const { impersonateUser, userImpersonation, supportInvitation } = useContext(UserContext);
  const { formatMessage } = useIntl();

  const [options, setOptions] = useState<SelectOption[]>([]);
  const { api, abortController } = useAptorApi();
  useEffect(() => {
    if (userImpersonation === undefined && supportInvitation === undefined) {
      api.getImpersonationUsers().then((result) => {
        if (abortController.current.signal.aborted) {
          return;
        }
        setOptions(result.users);
      });
    }
  }, [api, abortController, userImpersonation, supportInvitation]);

  const impersonationField = useFormField({
    label: formatMessage({ id: 'form.impersonation' }),
    name: 'email',
    required: false,
    type: 'single-select',
    options: options,
  });

  const classes = useStyles();

  return (
    (supportInvitation && <></>) ||
    (options && !userImpersonation && (
      <>
        <div className={classes.mobile}>
          <Form
            className={classes.root}
            submit={(form, _, onSuccess) => {
              const email = form.email as string | undefined;
              impersonateUser(email);
              onSuccess();
              return Promise.resolve();
            }}
            singleField={impersonationField}
          ></Form>
        </div>
        <Hidden mdUp>
          <Divider />
        </Hidden>
      </>
    )) || <></>
  );
};
