import { MessageDescriptor } from 'react-intl';
import { INamedEntity, INestedEntity, PropertyError } from '../Api/AptorApi';
import { FormFieldValueType, SelectOption } from '../Components/Form/Form.types';

export const entityToSelectOption = (entity: INamedEntity) => ({
  value: entity.id,
  label: entity.name,
});

export const nestedEntityToSelectOption = (entity: INestedEntity) =>
  ({
    label: entity.name,
    options: entity.entities.map(entityToSelectOption),
  } as any);

export const selectOptionToEntity = <T extends FormFieldValueType>(option: SelectOption) => {
  if (option.value === undefined) {
    return undefined;
  }
  return { id: option.value as T, name: option.label };
};

function isNested(opts: INamedEntity[] | INestedEntity[]) {
  return (opts as INestedEntity[]).some((x) => x.entities !== undefined);
}

export type EntityList = { [name: string]: INamedEntity[] | INestedEntity[] };

export type OptionsEntity = { [name: string]: SelectOption[] };

export const mapEntityListToOptions = (list: EntityList) => {
  var options: OptionsEntity = {};

  Object.keys(list).forEach((entity) => {
    let opts = list[entity];
    if (isNested(opts)) {
      options[entity] = (opts as INestedEntity[]).map(nestedEntityToSelectOption);
    } else {
      options[entity] = (opts as INamedEntity[]).map(entityToSelectOption);
    }
  });

  return options;
};

export const formValidationErrors = (propertyName: string, errors: PropertyError[]): string[] => {
  return errors
    .filter((e) => e.name.toLowerCase() === propertyName.toLowerCase())
    .map((e) => e.errors)
    .reduce((a, b) => [...a, ...b], [])
    .filter((e) => e.type === 'property')
    .map((e) => e.message);
};

export const notificationValidationErrors = (errors: PropertyError[]): string[] => {
  return errors
    .map((e) => e.errors)
    .reduce((a, b) => [...a, ...b], [])
    .filter((e) => e.type === 'notification')
    .map((e) => e.message);
};

export const getConnectedEntitiesString = (
  connections: { [name: string]: string },
  formatter: (descriptor: MessageDescriptor, values?: Record<string, string>) => string,
  getAlias?: (formatMessage: (params: { id: string }) => string, lowercase?: boolean | undefined) => string,
) => {
  let count = 0;
  let connector = '';
  let str = '';
  let alias = (getAlias && getAlias(formatter, true)) || formatter({ id: 'utils.connections.alias' });
  Object.keys(connections).forEach((key) => {
    if (key !== 'name' && +connections[key] > 0) {
      count += 1;
      if (count === 2) connector = formatter({ id: 'utils.connections.and' });
      else if (count > 2) connector = ', ';
      str = formatter({ id: 'utils.connections.' + key }, { [key]: connections[key], alias: alias }) + connector + str;
    }
  });
  if (str === '') return undefined;
  if (connections.name === undefined)
    return formatter({ id: 'utils.connections.basic-description' }, { connections: str });
  return formatter({ id: 'utils.connections.name-description' }, { connections: str, name: connections.name });
};
