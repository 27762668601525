import React, { useCallback, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form } from '../../../../Components/Form';
import { AptorApi } from '../../../../Api/AptorApi';
import { Container } from '../../../../Components/Container/Container';
import { Panel } from '../../../../Components/Panel/Panel';
import { OptionsType } from 'react-select';
import { useAptorApi } from '../../../../Api';
import { useFormField } from '../../../../Components/Form/FormField';
import { FormValues, SelectOption } from '../../../../Components/Form/Form.types';
import { BackButton } from '../../../../Components';
export const CreateAptorUserForm = () => {
  const intl = useIntl();
  const { api, abortController } = useAptorApi();

  const [availableLanguages, setAvailableLanguages] = useState<OptionsType<SelectOption>>([]);

  const formatMessage = useCallback(
    (id: string) => {
      return intl.formatMessage({ id: id, defaultMessage: id });
    },
    [intl],
  );

  const emailField = useFormField({
    label: formatMessage('form.email'),
    name: 'email',
    type: 'email',
    placeholder: formatMessage('form.fillInEmail'),
    required: true,
  });

  const languageField = useFormField({
    label: formatMessage('form.language'),
    name: 'language',
    type: 'single-select',
    placeholder: formatMessage('form.selectLanguage'),
    options: availableLanguages,
    required: true,
  });

  const [languageValue] = languageField.state;
  const setLanguage = languageField.setValue;

  const firstNameField = useFormField({
    label: formatMessage('form.firstName'),
    name: 'firstName',
    type: 'text',
    placeholder: formatMessage('form.fillInFirstName'),
    required: true,
  });

  const lastNameField = useFormField({
    label: formatMessage('form.lastName'),
    name: 'lastName',
    type: 'text',
    placeholder: formatMessage('form.fillInLastName'),
    required: true,
  });

  useEffect(() => {
    const fetchAvailableLanguages = async () => {
      const result = await api.getLanguages();
      if (abortController.current.signal.aborted) {
        return;
      }
      const languages = result.items.map((l) => ({
        value: l.id,
        label: l.name,
      }));

      setLanguage(languageValue);
      setAvailableLanguages(languages);
    };

    if (availableLanguages.length === 0) {
      fetchAvailableLanguages();
    }
  }, [api, abortController, formatMessage, availableLanguages, languageValue, setLanguage]);

  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: (successMessage: string) => void) => {
    await api.inviteAdminUser(data);
    if (abortController.current.signal.aborted) {
      return;
    }
    onSuccess('admin.users.invite.successfullyInvitedUser');
  };

  return (
    <Container>
      <BackButton link="/admin/users" />
      <Panel titleKey="admin.users.invite.addUser">
        <Form
          submit={handleSubmit}
          submitLabel={formatMessage('admin.users.invite.invite')}
          groups={[
            {
              grid: {
                type: 'column',
                items: [
                  { type: 'row', items: [emailField, languageField] },
                  { type: 'row', items: [firstNameField, lastNameField] },
                ],
              },
            },
          ]}
        />
      </Panel>
    </Container>
  );
};
