import React, { FC, ElementType } from 'react';
import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';

interface IProps {
  value: string;
  variant?: Variant;
  component?: ElementType;
}

export const HtmlView: FC<IProps> = ({ value, variant, component }) => {
  return (
    <Typography
      variant={variant}
      component={component ? component : 'div'}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};
