import React from 'react';
import { Grid } from '@material-ui/core';
import { LawSettingsList } from './List';
import { LawSettingsForm } from './Form';
import { Panel } from '../../../../../Components/Panel/Panel';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLawSettingsState } from './State';
import Loading from '../../../../../Components/Loading';
import { DeleteDescription } from './DeleteDescription';
import { ILawSettingsEntity } from '../../../../../Api/AptorApi';

const TagDeleteDescription = (props: { item: ILawSettingsEntity }) => {
  return (
    <DeleteDescription item={props.item} typeTranslationKey="admin.law-portal.settings.tag" canDelete>
      <FormattedMessage
        id="admin.law-portal.settings.tags.delete-description"
        values={{
          laws: props.item.connectedLaws,
        }}
      />
    </DeleteDescription>
  );
};

export const LawSettingsTags = () => {
  const { formatMessage } = useIntl();
  const { items, submit, loading, removeItem, editing, editItem, cancelEdit } = useLawSettingsState('tags', {
    canDelete: () => true,
    getDeleteDescription: (item) => <TagDeleteDescription item={item} />,
  });

  return (
    <Panel titleKey="admin.law-portal.settings.tags" accordion>
      {(loading && <Loading />) || (
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <LawSettingsForm
              id="admin.law-portal.settings.tag"
              submit={submit}
              edit={editing}
              cancelEdit={cancelEdit}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <LawSettingsList
              type={formatMessage({ id: 'admin.law-portal.settings.tags' })}
              items={items}
              deleteItem={removeItem}
              onEditItem={editItem}
            />
          </Grid>
        </Grid>
      )}
    </Panel>
  );
};
