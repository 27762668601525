import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { HtmlView, Panel, LawLink, BackButton } from '../../../../../../Components';
import { LawComplianceInterval } from './ComplianceInterval';
import { ICompliance, ComplianceUsers } from '../../../../Components/Compliance/ComplianceUsers';
import { ILawChange, LatestChange } from '../Changes';
import { Application } from './Application';
import { LawNotes } from './Notes';
import { IGridQueryRequest, INamedEntity } from '../../../../../../Api/AptorApi';
import { useAptorApi } from '../../../../../../Api';

interface ILawLink {
  url: string;
  text?: string;
}

export interface ICompanyUnitLaw extends INamedEntity {
  hasNextComplianceInterval: boolean;
  applicationHidden: boolean;
  note: string | null;
}

interface IState {
  shortDescription: string;
  chapter: string;
  application: string;
  link: ILawLink;
  latestChange?: ILawChange;
  companyUnits: ICompanyUnitLaw[];
  internalComments: Array<{ from: string; comment: string; createdAt: Date }>;
}

export const LawOverview = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api, abortController } = useAptorApi();
  const [overview, setOverview] = useState<IState>();

  useEffect(() => {
    api.getLawPortalLawOverview<IState>(id).then((overview) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setOverview(overview);
    });
  }, [api, abortController, id]);

  const updateState = useCallback((state: Partial<IState>) => overview && setOverview({ ...overview, ...state }), [
    overview,
  ]);

  const dataFetcher = useCallback(
    (request: IGridQueryRequest) => api.searchLawComplianceUsers<ICompliance>(id, request),
    [id, api],
  );

  if (overview === undefined) {
    return <></>;
  }
  return (
    <>
      <Grid item xs={12}>
        <BackButton disableMargin link="/law-portal/our-laws/" />
      </Grid>
      <Grid item md={9} xs={12}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Panel titleKey="law-portal.our-laws.overview.short-description.label">
              <HtmlView value={overview.shortDescription} />
              <LawLink type="law-description" url={overview.link.url} urlText={overview.link.text} />
            </Panel>
          </Grid>
          <Grid item xs={12}>
            <LawComplianceInterval lawId={id} companyUnits={overview.companyUnits} />
          </Grid>
          {overview.latestChange && (
            <Grid item xs={12}>
              <LatestChange lawId={id} change={overview.latestChange} onChange={updateState} />
            </Grid>
          )}
          <Grid item xs={12}>
            <ComplianceUsers dataFetcher={dataFetcher} />
          </Grid>
          <Grid item xs={12}>
            <Panel titleKey="law-portal.our-laws.overview.chapter.label">{<HtmlView value={overview.chapter} />}</Panel>
          </Grid>
          <Grid item xs={12}>
            <Application
              lawId={id}
              application={overview.application}
              companyUnits={overview.companyUnits}
              onChange={updateState}
            >
              <LawLink variant="caption" type="law-application" url={overview.link.url} urlText={overview.link.text} />
            </Application>
          </Grid>
          <Grid item xs={12}>
            <LawNotes lawId={id} companyUnits={overview.companyUnits} onChange={updateState} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
