import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Query } from 'material-table';
import { Form, MTable } from '../../../../../Components';
import { FormValues, SelectOption } from '../../../../../Components/Form/Form.types';
import { useFormField } from '../../../../../Components/Form/FormField';
import { useAptorApi, AptorApi } from '../../../../../Api';
import { IGridQueryRequest, OrderDirection } from '../../../../../Api/AptorApi';
import { entityToSelectOption } from '../../../../../Utilities';

const EditRelatedLawsForm: FC<{ id: number; onComplete: () => void }> = ({ id, onComplete }) => {
  const { api, abortController } = useAptorApi();
  const { formatMessage } = useIntl();
  const loadOptions = useCallback(
    async (search) => {
      const result = await api.searchAvailableLaws({ id, search, unrelated: true });
      if (abortController.current.signal.aborted) {
        return undefined;
      }
      return result.items.map(entityToSelectOption);
    },
    [api, abortController, id],
  );

  const relatedLaw = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.related-laws.add.label' }),
    name: 'relatedLawId',
    type: 'single-select-async',
    loadOptions: loadOptions,
    required: true,
  });

  const handleAddLaw = async (_: FormValues, api: AptorApi, onSuccess: () => void) => {
    const selected = relatedLaw.state[0] as SelectOption;
    if (selected?.value) {
      await api.addRelatedLaw(id, selected.value as number);
      onSuccess();
      if (api.abortController?.signal.aborted) {
        return;
      }
      onComplete();
      relatedLaw.clear();
    }
  };

  return <Form singleField={relatedLaw} submit={handleAddLaw} submitLabel={formatMessage({ id: 'form.add' })} />;
};

interface IRelatedLaw {
  id: number;
  number: string;
  name: string;
}

interface IProps {
  id: number;
  formOn?: boolean;
}

export const EditLawRelatedLaws: FC<IProps> = ({ id, formOn }) => {
  const { api, abortController } = useAptorApi();
  const history = useHistory();
  const tableRef = React.createRef();
  const { formatMessage } = useIntl();

  const reloadTable = () => {
    (tableRef.current as any).onQueryChange();
  };

  const handleDataFetch = useCallback(
    async (query: Query<IRelatedLaw>) => {
      return await api.searchRelatedLaws<IRelatedLaw>(id, {
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
      } as IGridQueryRequest);
    },
    [id, api],
  );

  const remove = (relatedLaw: IRelatedLaw) => ({
    tooltip: formatMessage({ id: 'component.mtable.deleteTooltip' }),
    icon: 'delete',
    onClick: async () => {
      await api.removeRelatedLaw(id, relatedLaw.id);
      if (abortController.current.signal.aborted) {
        return;
      }
      (tableRef.current as any).onQueryChange();
    },
  });

  return (
    <>
      {formOn && <EditRelatedLawsForm id={id} onComplete={reloadTable} />}
      <MTable
        hideToolbar
        tableRef={tableRef}
        columns={[
          { title: formatMessage({ id: 'admin.law-portal.law.related-laws.table.number' }), field: 'number' },
          { title: formatMessage({ id: 'admin.law-portal.law.related-laws.table.name' }), field: 'name' },
        ]}
        data={handleDataFetch}
        onRowClick={(_, row) => row && history.push(`/admin/law-portal/laws/${row.id}`)}
        actions={formOn ? [remove] : []}
      />
    </>
  );
};
