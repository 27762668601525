import { IconButton, Tooltip } from '@material-ui/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ArrowForward } from '@material-ui/icons';

interface IProps {
  id: number;
}

export const UserLink: FC<IProps> = ({ id }) => {
  const { formatMessage } = useIntl();

  return (
    <Tooltip
      title={formatMessage({
        id: 'law-portal.dashboard.new-users.show',
      })}
    >
      <IconButton component={Link} to={`/organization/users/${id}`}>
        <ArrowForward />
      </IconButton>
    </Tooltip>
  );
};
