import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  grid: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  fieldSet: {
    border: 0,
    padding: 0,
    margin: 0,
    '& legend': {
      padding: `0 0 ${theme.spacing(2)}`,
    },
  },
  readonly: {
    backgroundColor: '#fafafa',
    '&:hover': {
      border: '1px solid #bdbdbd',
    },
  },
  singleField: {
    maxWidth: '24rem',
  },
  singleFieldButton: {
    //Aligns submit button with form field
    marginTop: '1.5rem', // field label height
  },
}));
