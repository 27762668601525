import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderWidth: '2px',
  },
  list: {
    padding: 0,
  },
  header: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  hidden: {
    opacity: '50%',
    borderBottom: 'none',
  },
}));
