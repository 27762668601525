import React from 'react';
import { Fade } from '@material-ui/core';
import FadeElement from '../FadeElement';
import Loading from '../Loading';
import { useStyles } from './Transition.styles';

const Transition: React.FC<{
  isLoading: boolean;
  hideSpinner?: boolean;
}> = (props) => {
  const classes = useStyles();
  const { isLoading, hideSpinner, children } = props;
  return (
    <>
      {!hideSpinner && (
        <div className={classes.loadingContainer}>
          <Fade in={isLoading} unmountOnExit>
            <FadeElement>
              <Loading />
            </FadeElement>
          </Fade>
        </div>
      )}

      <Fade in={!isLoading}>
        <FadeElement>{children}</FadeElement>
      </Fade>
    </>
  );
};

export default Transition;
