import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CreateRequirementComplianceReport } from './Create';
import { RequirementCompliance as RequirementCompliancePage } from './Compliance';

export const RequirementCompliance = () => {
  return (
    <Switch>
      <Route
        path="/law-portal/other-requirements/:id(\d+)/compliance/report"
        component={CreateRequirementComplianceReport}
      />
      <Route
        path="/law-portal/other-requirements/:id(\d+)/compliance"
        component={RequirementCompliancePage}
      />
    </Switch>
  );
};
