import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CreateComplianceReport, IReport, IChecklist } from '../../../../Components/Compliance/CreateReport';
import { SelectOption } from '../../../../../../Components/Form/Form.types';
import { AptorApi, useAptorApi } from '../../../../../../Api';
import { entityToSelectOption } from '../../../../../../Utilities';
import { BackButton } from '../../../../../../Components';
import { Grid } from '@material-ui/core';

export const CreateRequirementComplianceReport = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api, abortController } = useAptorApi();
  const history = useHistory();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loadingChecklist, setLoadingChecklist] = useState<boolean>(false);

  const [companyUnits, setCompanyUnits] = useState<SelectOption[]>([]);
  const [companyUnitId, setCompanyUnitId] = useState<number>();
  const [checklist, setChecklist] = useState<IChecklist>();

  useEffect(() => {
    if (companyUnitId !== undefined) {
      setLoadingChecklist(true);
      api.getLawPortalRequirementChecklist<IChecklist>(companyUnitId, id).then((chkl) => {
        if (!abortController.current.signal.aborted) {
          setChecklist(chkl);
          setLoadingChecklist(false);
        }
      });
    } else {
      api.getUserCompanyUnitsForRequirementCompliance(id).then((userCompanyUnits) => {
        if (!abortController.current.signal.aborted) {
          setCompanyUnits(userCompanyUnits.items.map(entityToSelectOption));
          setLoaded(true);
        }
      });
    }
  }, [api, abortController, id, companyUnitId]);

  const submitAction = (unitId: number, a: AptorApi, report: IReport) =>
    a.createLawPortalRequirementComplianceReport(id, unitId, report);

  const handleFetchComplianceUsers = useCallback(
    async (companyUnitId: number) => {
      const result = await api.getParticipantsForRequirementCompliance(id, companyUnitId);
      return result.items.map(entityToSelectOption);
    },
    [api, id],
  );
  return (
    <>
      <Grid item xs={12}>
        <BackButton link={`/law-portal/other-requirements/${id}/compliance`} />
      </Grid>
      <Grid item md={9} xs={12}>
        <CreateComplianceReport
          key={`requirement-${id}`}
          requirementId={id}
          titleKey="law-portal.requirements.compliance.heading"
          summaryKey="law-portal.requirements.compliance.summary.label"
          storageKey={`compliance-state-for-requirement-${id}`}
          loaded={loaded}
          loadingChecklist={loadingChecklist}
          units={companyUnits}
          onCompanyUnitChange={setCompanyUnitId}
          fetchComplianceUsers={handleFetchComplianceUsers}
          checklist={checklist}
          disableHide={true}
          submitAction={submitAction}
          onCreateSuccess={() => history.push(`/law-portal/other-requirements/${id}`)}
        />
      </Grid>
    </>
  );
};
