import React, { FC, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Button, IconButton, Typography, Card, CardContent, CardHeader, Tooltip } from '@material-ui/core';
import { Delete, Edit, KeyboardArrowDown, KeyboardArrowUp, Mail } from '@material-ui/icons';
import { LawChange } from '../../../../../Components';
import { EditLawEditChange } from './Changes.Form';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';
import { useAptorApi } from '../../../../../Api';
import { useSnackbar } from 'notistack';
import { ILawChange } from '.';
import { useStyles } from './Changes.View.styles';

interface IChangeView {
  lawId: number;
  change: ILawChange;
  onDelete: (id: number) => void;
  openEdit: (id: number) => void;
}

const Change: FC<IChangeView> = ({ lawId, change, openEdit, onDelete }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const { enqueueSnackbar } = useSnackbar();
  const { deleteConfirmation } = useConfirmation();

  const sendoutConfirmation = {
    title: formatMessage({ id: 'component.law-change.sendout.label' }),
    description: formatMessage({ id: 'component.law-change.sendout.confirmation' }),
    hideSavedNotification: true,
  };

  const manualSendout = (lawChangeId: number) => async () => {
    await api.manualSendout(lawChangeId);
    if (abortController.current.signal.aborted) {
      return;
    }

    enqueueSnackbar(formatMessage({ id: 'component.law-change.sendout.sent' }));
  };

  const handleSendout = (lawChangeId: number) => () => {
    deleteConfirmation(manualSendout(lawChangeId), sendoutConfirmation);
  };

  const deleteChange = async (lawChangeId: number) => {
    await api.deleteLawChange(lawId, lawChangeId);
    if (abortController.current.signal.aborted) {
      return;
    }
    onDelete(lawChangeId);
  };

  return (
    <Card variant="outlined" className={classes.root}>
      <CardHeader
        action={
          <>
            <Tooltip title={formatMessage({ id: 'utils.icon.edit', defaultMessage: 'Edit' })}>
              <IconButton
                aria-label={formatMessage({ id: 'utils.icon.edit', defaultMessage: 'Edit' })}
                onClick={() => openEdit(change.id)}
              >
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
            {change.isPosibleToDelete &&
              <Tooltip title={formatMessage({ id: 'utils.icon.delete', defaultMessage: 'Delete' })}>
                <IconButton
                  aria-label={formatMessage({ id: 'utils.icon.delete', defaultMessage: 'Delete' })}
                  onClick={() => deleteChange(change.id)}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip>
            }
          </>
        }
        title={<Typography variant="h4">{change.number}</Typography>}
      />
      <CardContent>
        <LawChange lawId={lawId} change={change} />
        <Grid container justify="flex-end">
          <Button variant="outlined" startIcon={<Mail />} onClick={handleSendout(change.id)}>
            {formatMessage({ id: 'component.law-change.sendout.buttonLabel' })}
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};

interface IProps {
  id: number;
  changes: ILawChange[];
  onChange: (params: { changes: ILawChange[] }) => void;
}

export const EditLawChangesView: FC<IProps> = ({ id, changes, onChange }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [editingChange, setEditingChange] = useState<ILawChange | undefined>(undefined);
  const filterChanges = (_: ILawChange, index: number) => expanded || index === 0;
  const { formatMessage } = useIntl();

  useEffect(() => {
    setExpanded(false);
    setEditingChange(undefined);
  }, [changes]);

  const onCancelEdit = () => {
    setEditingChange(undefined);
  };

  const onDeleteChange = (changeId: number) => {
    onChange({
      changes: [...changes.filter((c) => c.id !== changeId)]
    });
  };

  if (editingChange) {
    return (
      <EditLawEditChange change={editingChange} id={id} changes={changes} onChange={onChange} cancel={onCancelEdit} />
    );
  }

  return (
    <>
      {changes.filter(filterChanges).map((c) => (
        <Change key={c.id} lawId={id} change={c} onDelete={onDeleteChange} openEdit={() => setEditingChange(c)} />
      ))}
      <Grid container justify="flex-end">
        {!expanded && changes.length > 1 && (
          <Button
            variant="text"
            startIcon={<KeyboardArrowDown />}
            aria-label={formatMessage({
              id: 'admin.law-portal.law.form.latest-change.expand',
              defaultMessage: 'Show older changes',
            })}
            onClick={() => setExpanded(true)}
          >
            <FormattedMessage id="admin.law-portal.law.form.latest-change.expand" defaultMessage="Show older changes" />
          </Button>
        )}
        {expanded && (
          <Button
            variant="text"
            startIcon={<KeyboardArrowUp />}
            aria-label={formatMessage({
              id: 'admin.law-portal.law.form.latest-change.collapse',
              defaultMessage: 'Hide older changes',
            })}
            onClick={() => setExpanded(false)}
          >
            <FormattedMessage
              id="admin.law-portal.law.form.latest-change.collapse"
              defaultMessage="Hide older changes"
            />
          </Button>
        )}
      </Grid>
    </>
  );
};
