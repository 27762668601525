import React, { FC, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Query } from 'material-table';
import { MTable } from '../../../../../Components';
import { IComplianceInterval } from '.';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { IGridQueryRequest, IGridQueryResult, OrderDirection } from '../../../../../Api/AptorApi';
import moment from 'moment';

interface IProps {
  dataFetcher: (request: IGridQueryRequest) => Promise<IGridQueryResult<IComplianceInterval>>;
  onEdit?: (interval: IComplianceInterval) => void;
  onDelete?: (unitId: number) => Promise<void>;
}

export const ComplianceIntervalView: FC<IProps> = ({ dataFetcher, onEdit, onDelete }) => {
  const tableRef = React.createRef();
  const { formatMessage } = useIntl();
  const { getCompanyUnitName } = useContext(UserContext);

  const handleDataFetch = useCallback(
    async (query: Query<IComplianceInterval>) => {
      return await dataFetcher({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'companyUnitName',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
      } as IGridQueryRequest);
    },
    [dataFetcher],
  );

  const renderDateTime = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
  };

  const edit = (interval: IComplianceInterval) => ({
    tooltip: formatMessage({ id: 'component.mtable.editTooltip' }),
    icon: 'edit',
    onClick: () => onEdit!(interval),
  });

  const remove = (interval: IComplianceInterval) => ({
    tooltip: formatMessage({ id: 'component.mtable.deleteTooltip' }),
    icon: 'delete',
    onClick: () => onDelete!(interval.companyUnitId),
  });

  return (
    <MTable
      tableRef={tableRef}
      columns={[
        { title: getCompanyUnitName(formatMessage), field: 'companyUnitName' },
        {
          title: formatMessage({ id: 'law-portal.compliance.interval.start.label' }),
          field: 'start',
          render: (interval: IComplianceInterval) => renderDateTime(interval.start),
        },
        {
          title: formatMessage({ id: 'law-portal.compliance.interval.end.label' }),
          field: 'end',
          render: (interval: IComplianceInterval) => renderDateTime(interval.end),
        },
      ]}
      data={handleDataFetch}
      actions={onEdit && onDelete ? [edit, remove] : undefined}
    />
  );
};
