import React, { useCallback, useContext } from 'react';
import MTable from '../../../../Components/MTable/MTable';
import { useIntl } from 'react-intl';
import { useAptorApi } from '../../../../Api/useAptorApi';
import { Query } from 'material-table';
import { IGridQueryRequest, OrderDirection } from '../../../../Api/AptorApi';
import { Container } from '../../../../Components/Container/Container';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { UserContext, AdminFeature, AccessLevel, UserStatus } from '../../../../Context/UserContext/UserContext';
import moment from 'moment';
import { useConfirmation } from '../../../../Context/ConfirmationContext/ConfirmationContext';

type UserData = {
  id: number;
  name: string;
  email: string;
  status: UserStatus;
  lastSignIn: string;
};

export const AptorUserTable = () => {
  const intl = useIntl();
  const { api, abortController } = useAptorApi();
  const tableRef = React.createRef();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { hasAccessToFeature, id } = useContext(UserContext);
  const handleDataFetch = useCallback(
    async (query: Query<UserData>) => {
      return await api.searchUsers<UserData>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
      } as IGridQueryRequest);
    },
    [api],
  );

  const { deleteConfirmation } = useConfirmation();

  const toggleUserActivation = async (id: number, activate: boolean) => {
    await api.toggleAptorUserActivation(id, activate);
    if (abortController.current.signal.aborted) {
      return;
    }
    (tableRef.current as any).onQueryChange();
  };

  const resendUserInvite = async (id: number) => {
    await api.resendAptorUserInvite(id);
    if (abortController.current.signal.aborted) {
      return;
    }
    enqueueSnackbar(intl.formatMessage({ id: 'admin.users.table.userInviteSent' }));
  };

  const handleActivateUser = (id: number) => () => {
    toggleUserActivation(id, true);
  };

  const handleInactivateUser = (id: number) => () => {
    toggleUserActivation(id, false);
  };

  const handleSendInvite = (id: number) => () => {
    resendUserInvite(id);
  };

  const handleEditUser = (id: number) => () => {
    history.push(`/admin/users/${id}`);
  };

  const deleteUser = (user: UserData) => ({
    tooltip: intl.formatMessage({ id: 'component.mtable.deleteTooltip' }),
    icon: 'delete',
    onClick: async () => {
      const currentTableRef = tableRef.current as any;

      deleteConfirmation(
        async () => {
          await api.deleteAptorUser(user.id);
          if (abortController.current.signal.aborted) {
            return;
          }
          currentTableRef.onQueryChange();
        },
        {
          description: intl.formatMessage({ id: 'organization.users.table.action.deleteUser.confirmation' }),
        },
      );
    },
    hidden: user.id === id,
  });

  const hasCreateAccess = hasAccessToFeature(AdminFeature.Administrate, AccessLevel.Manage);
  return (
    <Container
      fullWidth
      linkButtonTo={hasCreateAccess ? '/admin/users/create' : undefined}
      linkButtonLabel={hasCreateAccess ? 'admin.users.button.addUser' : undefined}
    >
      <MTable
        tableRef={tableRef}
        style={{ width: '100%' }}
        columns={[
          { title: intl.formatMessage({ id: 'admin.users.table.name' }), field: 'name' },
          { title: intl.formatMessage({ id: 'admin.users.table.email' }), field: 'email' },
          {
            title: intl.formatMessage({ id: 'admin.users.table.lastSignIn' }),
            field: 'lastSignIn',
            render: (data) =>
              data.lastSignIn ? (
                moment(data.lastSignIn).format('lll UTC')
              ) : (
                <i>{intl.formatMessage({ id: 'admin.users.table.never' })}</i>
              ),
          },
        ]}
        data={handleDataFetch}
        actions={
          hasAccessToFeature(AdminFeature.Administrate, AccessLevel.Manage)
            ? [
                (row) => ({
                  tooltip: intl.formatMessage({ id: 'admin.users.table.edit' }),
                  icon: 'edit',
                  onClick: handleEditUser(row.id),
                }),
                (row) =>
                  row.status === UserStatus.Active
                    ? {
                        tooltip: intl.formatMessage({ id: 'admin.users.table.inactivate' }),
                        icon: 'block',
                        onClick: handleInactivateUser(row.id),
                        hidden: row.id === id,
                      }
                    : row.status === UserStatus.Inactive
                    ? {
                        tooltip: intl.formatMessage({ id: 'admin.users.table.activate' }),
                        icon: 'check',
                        onClick: handleActivateUser(row.id),
                      }
                    : {
                        tooltip: intl.formatMessage({ id: 'admin.users.table.resendInvite' }),
                        icon: 'send',
                        onClick: handleSendInvite(row.id),
                      },
                deleteUser,
              ]
            : undefined
        }
      />
    </Container>
  );
};
