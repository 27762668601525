import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  topMenu: {
    padding: theme.spacing(3),
    marginBottom: '4vh',
  },
  topMenuMobile: {
    padding: theme.spacing(1),
  },
  heading: {
    marginBottom: '4vh',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  subHeading: {
    marginBottom: '3vh',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  },
}));
