import React, { FC, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Grid, Button } from '@material-ui/core';
import { TextField } from '../../../../../../../Components/Field';
import { TypedFormFieldValue } from '../../../../../../../Components/Form/Form.types';
import { FormFieldRef } from '../../../../../../../Components/Field/Field.props';

interface IProps {
  add: (question: string) => void;
}

export const CustomerQuestionForm: FC<IProps> = ({ add }) => {
  const { formatMessage } = useIntl();
  const [question, setQuestion] = useState<TypedFormFieldValue<string>>();
  let questionRef: FormFieldRef<string> | null = null;
  const onAddQuestion = () => {
    add(question?.value ?? '');
    questionRef?.setValue({ value: '' });
  };

  return (
    <Grid item>
      <Grid container spacing={2} alignItems="flex-end" wrap="nowrap">
        <Grid item md={6} xs={9}>
          <TextField
            label={formatMessage({ id: 'law-portal.manage.checklist.form.question.label' })}
            ref={(r) => (questionRef = r)}
            name="question"
            errorTexts={[]}
            onChange={setQuestion}
          />
        </Grid>
        <Grid item>
          <Button disabled={question?.value === undefined} variant="outlined" size="small" onClick={onAddQuestion}>
            <FormattedMessage id="form.add" defaultMessage="Add" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
