import React from 'react';
import { useIntl } from 'react-intl';
import ReactSelect, { Props } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { SelectOption } from '../Form/Form.types';
import { styles } from './Select.styles';

interface ISelectProps {
  className?: string;
  selectProps?: Props<SelectOption>;
  autoComplete?: boolean;
  creatable?: boolean;
  createLabel?: string;
}

function Select(props: ISelectProps) {
  const { formatMessage } = useIntl();
  const innerProps = {
    ...props.selectProps,
    className: props.className,
    styles,
    noOptionsMessage: () => formatMessage({ id: 'no-options' }),
  };

  return props.creatable ? (
    <CreatableSelect
      formatCreateLabel={
        props.createLabel
          ? (input) => (
              <>
                {props.createLabel}: {input}
              </>
            )
          : undefined
      }
      {...innerProps}
    />
  ) : (
    <ReactSelect {...innerProps} />
  );
}

export default Select;
