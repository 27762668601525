import { makeStyles, Theme } from '@material-ui/core';

// https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  button: {
    height: 'auto',
  },
  card: {
    marginBottom: theme.spacing(4),
  },
}));
