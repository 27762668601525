import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import clsx from 'clsx';
import { FormControl } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { FormFieldRef, RichTextFieldProps } from './Field.props';
import { RichTextEditor } from '../RichTextEditor/RichTextEditor';
import { FieldHelperOrErrors } from './FieldHelperOrErrors';
import { useStyles } from './Field.styles';
import { shallowCompare } from './shallowCompare';
import { TypedFormFieldValue } from '../Form/Form.types';

export const RichTextField = memo(
  forwardRef<FormFieldRef<string>, RichTextFieldProps>((props, ref) => {
    const classes = useStyles();
    const { isDisabled, label, helperText, errorTexts, onChange, height, required, initialState, ...rest } = props;
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState<string>((initialState as TypedFormFieldValue<string> | undefined)?.value || '');

    useImperativeHandle(ref, () => ({
      setValue: (value: TypedFormFieldValue<string> | TypedFormFieldValue<string>[] | undefined) => {
        if (!Array.isArray(value)) {
          setValue(value?.value ?? '');
        }
      },
    }));

    useEffect(() => {
      if (onChange) {
        if (value === '' && !required) {
          onChange(undefined);
        } else {
          onChange({ value: value, isValid: !required || value !== '' });
        }
      }
    }, [onChange, value, required]);

    const getoverrideConfig = () => {
      if (height === 'large') {
        return { height: 600 };
      } else if (height === 'medium') {
        return { height: 400 };
      } else if (height === 'small') {
        return { height: 200 };
      } else if (height) {
        return { height };
      }

      return undefined;
    };

    return (
      <FormControl error={props.error} className={classes.formControl}>
        <Typography className={classes.label} component="label">
          {props.label}
          {props.required && !props.hideRequiredAsterisk && <span>*</span>}
        </Typography>
        <RichTextEditor
          className={clsx({ [classes.richTextError]: rest.error && !isFocused })}
          value={value}
          onChange={setValue}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          overrideConfig={getoverrideConfig()}
        />
        <FieldHelperOrErrors helperText={helperText} errorTexts={errorTexts} />
      </FormControl>
    );
  }),
  (newProps, oldProps) => shallowCompare(newProps, oldProps, ['errorTexts']),
);

export default RichTextField;
