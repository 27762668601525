import React, { FC } from 'react';
import { Typography, List, ListItem } from '@material-ui/core';
import { useStyles } from './Field.styles';

interface IProps {
  helperText?: string;
  errorTexts: string[];
}

export const FieldHelperOrErrors: FC<IProps> = ({ helperText, errorTexts }) => {
  const classes = useStyles();
  return (
    <>
      {helperText && errorTexts.length === 0 && (
        <Typography className={classes.helpLabel} variant="caption">
          {helperText}
        </Typography>
      )}

      {errorTexts.length > 0 && (
        <List className={classes.errorList}>
          {errorTexts.map((err, index) => (
            <ListItem key={`error-list-${index}`}>
              <Typography color="error" className={classes.helpLabel} variant="caption">
                {err}
              </Typography>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};
