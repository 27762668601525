import React, { FC, useCallback } from 'react';
import { Form } from '../../../../Components/Form';
import { useIntl } from 'react-intl';
import { AptorApi } from '../../../../Api/AptorApi';
import { useAptorApi } from '../../../../Api';
import { entityToSelectOption } from '../../../../Utilities';
import { useFormField } from '../../../../Components/Form/FormField';
import { FormValues, SelectOption } from '../../../../Components/Form/Form.types';

interface IProps {
  submit: (
    form: FormValues,
    api: AptorApi,
    onSuccess: (successMessage?: string | undefined | null) => void,
  ) => Promise<void>;
  areas: SelectOption[];
  categories: SelectOption[];
  tags: SelectOption[];
  countries: SelectOption[];
}

export const CreateLawForm: FC<IProps> = ({ submit, areas, categories, tags, countries }) => {
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();

  const name = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.name.label', defaultMessage: 'Name' }),
    name: 'name',
    type: 'text',
    required: true,
  });

  const number = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.number.label', defaultMessage: 'Number' }),
    name: 'number',
    type: 'text',
    required: true,
  });

  const category = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.category.label', defaultMessage: 'Category' }),
    name: 'category',
    type: 'single-select',
    options: categories,
    required: true,
  });

  const area = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.area.label', defaultMessage: 'Area' }),
    name: 'area',
    type: 'single-select',
    options: areas,
    required: true,
  });

  const tag = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.tags.label', defaultMessage: 'Tags' }),
    name: 'tags',
    type: 'multi-select',
    options: tags,
    required: false,
  });

  const effectiveFrom = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.effectiveFrom.label', defaultMessage: 'Effective from' }),
    name: 'effectiveFrom',
    type: 'date',
    required: true,
  });

  const url = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.url.label', defaultMessage: 'Url' }),
    name: 'url',
    type: 'text',
    required: true,
  });

  const urlName = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.urlName.label', defaultMessage: 'Link name' }),
    name: 'urlName',
    type: 'text',
    placeholder: formatMessage({
      id: 'admin.law-portal.law.form.urlName.placeholder',
      defaultMessage: 'Read the law in its entirety',
    }),
    required: false,
  });

  const country = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.country.label', defaultMessage: 'Country' }),
    name: 'country',
    type: 'single-select',
    options: countries,
    required: true,
  });

  const loadOptions = useCallback(
    async (search) => {
      const result = await api.searchAvailableLaws({ search });
      if (abortController.current.signal.aborted) {
        return undefined;
      }
      return result.items.map(entityToSelectOption);
    },
    [api, abortController],
  );

  const replacesLaw = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.replacesLaw.label', defaultMessage: 'Replaces law' }),
    name: 'replacedLawId',
    type: 'single-select-async',
    loadOptions: loadOptions,
    required: false,
  });

  const description = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.description.label', defaultMessage: 'Short description' }),
    name: 'description',
    type: 'rich-text',
    required: true,
  });

  const chapter = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.chapter.label', defaultMessage: 'Chapter/Paragraph' }),
    name: 'chapter',
    type: 'rich-text',
    required: false,
    richTextEditorHeight: 'small',
  });

  const application = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.form.application.label', defaultMessage: 'Application' }),
    name: 'application',
    type: 'rich-text',
    required: false,
  });

  return (
    <Form
      submit={submit}
      groups={[
        {
          grid: {
            type: 'column',
            items: [
              {
                type: 'row',
                items: [
                  { type: 'column', items: [name, effectiveFrom, category, url, country] },
                  { type: 'column', items: [number, area, tag, urlName, replacesLaw] },
                  { type: 'column', flex: 2, items: [description] },
                ],
              },
              chapter,
              application,
            ],
          },
        },
      ]}
    />
  );
};
