import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Form } from '../../../../../Components';
import { FormValues, SelectOption } from '../../../../../Components/Form/Form.types';
import { useFormField } from '../../../../../Components/Form/FormField';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { AptorApi } from '../../../../../Api';
import { IComplianceInterval } from '.';

interface IProps {
  interval?: IComplianceInterval;
  units: SelectOption[];
  submit: (form: FormValues, api: AptorApi, onSuccess: () => void) => Promise<void>;
  close: () => void;
}

export const ComplianceIntervalForm: FC<IProps> = ({ interval, units, submit, close }) => {
  const { formatMessage } = useIntl();
  const { getCompanyUnitName } = useContext(UserContext);

  const unitField = useFormField({
    type: interval ? 'readonly' : 'single-select',
    name: 'unit',
    required: true,
    options: units,
    label: getCompanyUnitName(formatMessage),
    initialState: interval ? { value: interval.companyUnitName } : undefined,
  });

  const startField = useFormField({
    type: 'date',
    name: 'start',
    required: true,
    label: formatMessage({ id: 'law-portal.compliance.interval.start.label' }),
    initialState: interval ? { value: interval.start } : undefined,
  });

  const endField = useFormField({
    type: 'date',
    name: 'end',
    required: true,
    label: formatMessage({ id: 'law-portal.compliance.interval.end.label' }),
    initialState: interval ? { value: interval.end } : undefined,
  });

  return (
    <Form
      groups={[{ grid: { type: 'column', items: [unitField, startField, endField] } }]}
      submit={submit}
      cancel={close}
    />
  );
};
