import React, { FC, useEffect } from 'react';
import { Form } from '../../../../../Components/Form/Form';
import { AptorApi } from '../../../../../Api/AptorApi';
import { useIntl } from 'react-intl';
import { useStyles } from './Form.styles';
import { useFormField } from '../../../../../Components/Form/FormField';
import { FormValues } from '../../../../../Components/Form/Form.types';

interface IProps {
  id: string;
  edit?: { name: string; description?: string };
  cancelEdit: () => void;
  submit: (
    form: FormValues,
    api: AptorApi,
    onSuccess: (successMessage?: string | undefined | null) => void,
  ) => Promise<void>;
}

export const LawSettingsForm: FC<IProps> = ({ id, submit, edit, cancelEdit }) => {
  const { formatMessage } = useIntl();

  const classes = useStyles();

  const nameField = useFormField({
    label: formatMessage({ id, defaultMessage: 'Name' }),
    name: 'name',
    type: 'text',
    required: true,
  });

  const descriptionField = useFormField({
    label: formatMessage({ id: 'admin.law-portal.settings.form.description.label', defaultMessage: 'Description' }),
    name: 'description',
    type: 'text',
    multilineRows: 5,
    required: false,
  });

  useEffect(() => {
    if (edit) {
      nameField.formFieldRef.current?.setValue({ value: edit?.name ?? '' });
      descriptionField.formFieldRef.current?.setValue({ value: edit?.description ?? '' });
    }
  }, [edit, nameField.formFieldRef, descriptionField.formFieldRef]);

  const handleSubmit = async (
    form: FormValues,
    api: AptorApi,
    onSuccess: (successMessage?: string | undefined | null) => void,
  ) => {
    await submit(form, api, onSuccess);
    nameField.clear();
    descriptionField.clear();
  };

  const handleCancel = () => {
    cancelEdit();
    nameField.clear();
    descriptionField.clear();
  };

  return (
    <Form
      className={classes.root}
      submit={handleSubmit}
      cancel={edit ? handleCancel : undefined}
      groups={[{ grid: { type: 'column', items: [nameField, descriptionField] } }]}
    />
  );
};
