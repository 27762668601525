import { IconButton, Tooltip } from '@material-ui/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ArrowForward } from '@material-ui/icons';
import { Metadata, EntityTypes } from '.';

interface IProps {
  id: number;
  type: EntityTypes;
}

export const TaggedLink: FC<IProps> = ({ id, type }) => {
  const { formatMessage } = useIntl();

  return (
    <Tooltip
      title={formatMessage({
        id: `law-portal.dashboard.tagged-in-laws.show.${type.toLowerCase()}`,
      })}
    >
      <IconButton component={Link} to={Metadata[type].url(id)}>
        <ArrowForward />
      </IconButton>
    </Tooltip>
  );
};
