import React, { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { UserContext } from '../../../../../../Context/UserContext/UserContext';
import { Reports } from './Reports';
import { BackButton } from '../../../../../../Components';
import { ComplianceVideo } from '../../../../Components/Compliance/ComplianceVideo';
import { Grid } from '@material-ui/core';

export const RequirementCompliance = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { complianceUserFor } = useContext(UserContext);
  const isComplianceUser = complianceUserFor.requirements.some((x) => x.id === id);
  return (
    <>
      <Grid item xs={12}>
        <BackButton disableMargin link="/law-portal/other-requirements" />
      </Grid>
      <Grid item md={9} xs={12}>
        {isComplianceUser && (
          <ComplianceVideo
            title="Compliance report instructions"
            src="https://player.vimeo.com/video/330020704"
            buttonLink={`/law-portal/other-requirements/${id}/compliance/report`}
            buttonLabelKey="law-portal.requirements.compliance.create.button"
          />
        )}
        <Reports reqId={id} />
      </Grid>
    </>
  );
};
