import { makeStyles, Theme } from '@material-ui/core/styles';
import { sidebarWidth, miniSidebarWidth } from '../Sidebar/Sidebar.styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
  shift: {
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${sidebarWidth}rem)`,
      marginLeft: `${sidebarWidth}rem`,
    },
  },
  positionFixed: {
    [theme.breakpoints.up('lg')]: {
      left: `${miniSidebarWidth}rem`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  active: {
    borderBottom: `4px solid ${theme.palette.primary.main}`,
  },
  inactive: {
    color: theme.palette.primary.dark,
    borderBottom: '4px solid transparent',
    '& .MuiButton-label': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  subMenu: {
    backgroundColor: theme.palette.primary.light,
    paddingLeft: 0,
    alignItems: 'stretch',
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  subMenuWrapper: {
    display: 'flex',
  },
  subMenuButton: {
    borderRadius: 0,
    minWidth: '10rem',
    height: '3rem',
    flexShrink: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  toolbar: theme.mixins.toolbar,
  subMenuToolbar: {
    minHeight: '3rem',
  },
}));
