import React, { useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { getAppConfiguration } from '../../Config';
import { UserContext } from '../../Context/UserContext/UserContext';

export const RichTextEditor = (props: {
  className?: string;
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  overrideConfig?: Record<string, any>;
}) => {
  const user = useContext(UserContext);
  const getLanguageCode = () => {
    if (user.locale === 'sv') {
      return 'sv_SE';
    } else {
      return 'en_US';
    }
  };

  const config = {
    height: 400,
    menubar: false,
    branding: false,
    theme: 'silver',
    skin: 'CUSTOM',
    skin_url: `${process.env.PUBLIC_URL}/tinymce/skins/ui/CUSTOM`,
    mobile: {
      theme: 'silver',
      plugins: ['autosave', 'lists', 'autolink'],
    },
    plugins: [
      'advlist autolink lists link charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime table paste code',
    ],
    toolbar_mode: 'floating',
    toolbar: 'undo redo | bold italic removeformat | bullist numlist  | code',
    browser_spellcheck: true,
    language: getLanguageCode(),
    urlconverter_callback: function (url: string) {
      if (!url.startsWith('http')) {
        if (url.indexOf('.') > -1) {
          return `http://${url}`;
        }
      }
      return url;
    },
  } as Record<string, any>;

  const init = props.overrideConfig ? { ...config, ...props.overrideConfig } : config;
  const apiKey = getAppConfiguration().tinyMCEApiKey;
  return (
    <Editor
      init={init}
      value={props.value}
      onEditorChange={props.onChange}
      apiKey={apiKey}
      onFocus={() => props.onFocus && props.onFocus()}
      onBlur={() => props.onBlur && props.onBlur()}
    />
  );
};
