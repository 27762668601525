import { Link, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Variant } from '@material-ui/core/styles/createTypography';

interface IProps {
  type?: 'law-description' | 'law-application' | 'law-change';
  url: string;
  urlText?: string;
  variant?: Variant;
}

/* TODO: Break this component into two. It's currently serving two different purposes which breaks SRP */
export const LawLink: FC<IProps> = ({ url, urlText, type, variant }) => {
  const keys = {
    'law-description': 'component.law-link.description.link',
    'law-application': 'component.law-link.application.link',
    'law-change': 'component.law-link.law-change.link',
  };
  const translationKey = type && keys[type];
  return (
    <Typography variant={variant}>
      {(urlText && (
        <Link href={url} target="_blank">
          {urlText}
        </Link>
      )) || (
        <FormattedMessage
          id={translationKey}
          values={{
            a: (chunks: any) => (
              <Link href={url} target="_blank">
                {chunks}
              </Link>
            ),
          }}
        />
      )}
    </Typography>
  );
};
