import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ILawSettingsEntity } from '../../../../../Api/AptorApi';
import { ConfirmationMessage } from '../../../../../Context/ConfirmationContext/ConfirmationMessage';

interface IDeleteDescriptionProps {
  item: ILawSettingsEntity;
  typeTranslationKey: string;
  canDelete: boolean;
}

export const DeleteDescription: FC<IDeleteDescriptionProps> = ({ item, typeTranslationKey, children, canDelete }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <ConfirmationMessage name={formatMessage({ id: item.name })} />
      {children ||
        (!canDelete && (
          <FormattedMessage
            id="admin.law-portal.settings.delete-description"
            values={{
              type: formatMessage({ id: typeTranslationKey }),
              laws: item.connectedLaws,
              requirements: item.connectedCustomerRequirements ?? 0,
            }}
          />
        ))}
    </>
  );
};
