import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ComplianceInterval, IComplianceInterval } from '../../../../Components/Compliance/ComplicanceInterval';
import { FormValues } from '../../../../../../Components/Form/Form.types';
import { AptorApi, useAptorApi } from '../../../../../../Api';
import { IGridQueryRequest } from '../../../../../../Api/AptorApi';
import { entityToSelectOption } from '../../../../../../Utilities';
import { ICompanyUnitLaw } from '.';

interface IProps {
  lawId: number;
  companyUnits: Array<ICompanyUnitLaw>;
}

export const LawComplianceInterval: FC<IProps> = ({ lawId, companyUnits }) => {
  const { api } = useAptorApi();
  const [units, setUnits] = useState<ICompanyUnitLaw[]>([]);
  useEffect(() => {
    setUnits(companyUnits);
  }, [companyUnits]);
  const selectUnits = useMemo(() => units.filter((x) => !x.hasNextComplianceInterval).map(entityToSelectOption), [
    units,
  ]);

  const fetchIntervals = useCallback(
    (request: IGridQueryRequest) => api.searchLawComplianceIntervals<IComplianceInterval>(lawId, request),
    [lawId, api],
  );

  const updateState = (unitId: number, hasInterval: boolean) => {
    var unit = units.find((x) => x.id === unitId);
    if (unit === undefined) return;
    unit.hasNextComplianceInterval = hasInterval;
    setUnits([...units]);
  };

  const updateInterval = async (unitId: number, form: FormValues, api: AptorApi, onSuccess: () => void) => {
    await api.setLawComplianceInterval(lawId, unitId, form);
    onSuccess();
    updateState(unitId, true);
  };

  const deleteInterval = async (unitId: number) => {
    await api.deleteLawComplianceInterval(lawId, unitId);
    updateState(unitId, false);
  };

  return (
    <ComplianceInterval
      titleKey="law-portal.our-laws.overview.compliance-interval.label"
      companyUnits={selectUnits}
      dataFetcher={fetchIntervals}
      editInterval={updateInterval}
      deleteInterval={deleteInterval}
    />
  );
};
