import React, { useCallback, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Query } from 'material-table';
import MTable from '../../../../../Components/MTable/MTable';
import { RequirementComplianceFilter } from '../Filters';
import { useFilter } from '../../../../../Components/ListFilter';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { useAptorApi } from '../../../../../Api';
import { IGridQueryRequest, OrderDirection } from '../../../../../Api/AptorApi';
import { useIsMounted } from '../../../../../Hooks/useIsMounted';
import moment from 'moment';

type ComplianceControlDue = {
  requirementId: number;
  requirementName: string;
  effectiveFrom: Date;
  customerCompanyUnit: string;
  userId: number;
  userName: string;
  nextComplianceIntervalStart: Date;
  nextComplianceIntervalEnd: Date;
};

export const CompliancesDue = () => {
  const { getCompanyUnitName } = useContext(UserContext);
  const { formatMessage } = useIntl();
  const { api } = useAptorApi();
  const [filters, setFilter] = useFilter();
  const tableRef = React.createRef();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted) {
      tableRef.current && (tableRef.current as any).onQueryChange();
    }
  }, [filters, tableRef, isMounted]);

  const handleDataFetch = useCallback(
    async (query: Query<ComplianceControlDue>) => {
      return await api.searchStatisticsRequirementCompliancesDue<ComplianceControlDue>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'requirementName',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  const renderDate = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
  };

  return (
    <>
      <RequirementComplianceFilter filters={filters} setFilter={setFilter} />
      <MTable
        tableRef={tableRef}
        columns={[
          {
            title: formatMessage({ id: 'law-portal.statistics.table.requirement' }),
            field: 'requirementName',
            render: (row) => (
              <Link to={`/law-portal/other-requirements/${row.requirementId}`}>{row.requirementName}</Link>
            ),
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.effectiveFrom' }),
            field: 'effectiveFrom',
            render: (row) => renderDate(row.effectiveFrom),
          },
          {
            title: getCompanyUnitName(formatMessage),
            field: 'customerCompanyUnit',
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.user' }),
            field: 'userName',
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.compliance-interval' }),
            field: 'compliance-interval',
            render: (row) => (
              <span>
                {renderDate(row.nextComplianceIntervalStart)} - {renderDate(row.nextComplianceIntervalEnd)}
              </span>
            ),
            sorting: false,
          },
        ]}
        data={handleDataFetch}
      />
    </>
  );
};
