import { Button, Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useComplianceSession } from '../../../../../Context/ComplianceSessionContext';
import { Close, ArrowForward } from '@material-ui/icons';

interface IProps {
  complianceEnabled: boolean;
  enableCompliance?: () => void;
  disableCompliance: () => void;
  hasCompliance: (predicate: () => boolean) => boolean;
  startComplianceSession: () => void;
}

export const ComplianceActions: FC<IProps> = ({
  enableCompliance,
  complianceEnabled,
  disableCompliance,
  hasCompliance,
  startComplianceSession,
}) => {
  const { clearComplianceSession, complianceSession } = useComplianceSession();
  const hasNoCompliances = !hasCompliance(() => true);
  const nextLawInCompliance = complianceSession?.next();
  if (nextLawInCompliance) {
    return (
      <>
        <Grid item>
          <Button color="secondary" startIcon={<Close />} onClick={clearComplianceSession}>
            <FormattedMessage
              id="law-portal.our-laws.table.abort-current-compliance-session.button"
              defaultMessage="Abort compliance session"
            />
          </Button>
        </Grid>
        <Grid item>
          <Button startIcon={<ArrowForward />} component={Link} to={nextLawInCompliance.complianceUrl}>
            <FormattedMessage
              id="law-portal.our-laws.table.continue-compliance-session.button"
              defaultMessage="Continue compliance session"
            />
          </Button>
        </Grid>
      </>
    );
  } else if (enableCompliance === undefined) {
    return null;
  } else if (complianceEnabled === false) {
    return (
      <Grid item>
        <Button variant="outlined" onClick={enableCompliance}>
          <FormattedMessage
            id="law-portal.our-laws.table.enable-compliance-selection.button"
            defaultMessage="Select laws for compliance session"
          />
        </Button>
      </Grid>
    );
  } else if (complianceEnabled && hasNoCompliances) {
    return (
      <Grid item>
        <Button color="secondary" onClick={disableCompliance}>
          <FormattedMessage
            id="law-portal.our-laws.table.disable-compliance-selection.button"
            defaultMessage="Disable law selection"
          />
        </Button>
      </Grid>
    );
  }
  return (
    <Grid item>
      <Button startIcon={<ArrowForward />} disabled={hasNoCompliances} onClick={startComplianceSession}>
        <FormattedMessage
          id="law-portal.our-laws.table.start-compliance-session.button"
          defaultMessage="Start compliance session"
        />
      </Button>
    </Grid>
  );
};
