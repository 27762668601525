import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    padding: `${theme.spacing(2)} 0`,
  },
  addButton: {
    marginBottom: theme.spacing(2),
  },

  companyUnitsExceeded: {
    marginBottom: '1em',
  },
  companyUnitFooterContainer: {
    width: '100%',
  },
  companyUnitFooterLabel: {
    paddingLeft: '1em',
    display: 'flex',
    alignItems: 'center',
  },
  companyUnitFooterPagination: {
    position: 'relative',
  },
}));
