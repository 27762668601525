import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Query } from 'material-table';
import { Container, MailDialog, MTable } from '../../../../../Components';
import { SendoutsFilter } from './Filter';
import { useFilter } from '../../../../../Components/ListFilter/useFilter';
import { IGridQueryRequest, OrderDirection } from '../../../../../Api/AptorApi';
import { useAptorApi } from '../../../../../Api';
import moment from 'moment';

type SendoutData = {
  sendoutId: number;
  sendoutDateUtc: Date;
  sendoutType: string;
  recipient: string;
  sent: boolean;
  failed: boolean;
  lastRunUtc?: Date;
};

type MailContent = {
  subject: string;
  content: string;
};

export const SendoutsTable = () => {
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const tableRef = React.createRef();
  const [filters, setFilter] = useFilter();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [subject, setSubject] = React.useState<string>();
  const [content, setContent] = React.useState<string>('');

  const openMail = async (id: number) => {
    setLoading(true);
    let response = await api.getSendout<MailContent>(id);
    if (abortController.current.signal.aborted) {
      return;
    }
    setSubject(response.subject);
    setContent(response.content);
    setLoading(false);
    setOpen(true);
  };

  useEffect(() => {
    tableRef.current && (tableRef.current as any).onQueryChange();
  }, [tableRef]);

  const handleDataFetch = useCallback(
    async (query: Query<SendoutData>) => {
      return await api.searchSendouts<SendoutData>({
        orderDirection: query.orderDirection === 'asc' ? OrderDirection.Ascending : OrderDirection.Descending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'sendoutDateUtc',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  return (
    <Container fullWidth>
      <SendoutsFilter setFilter={setFilter} />
      <MTable
        tableRef={tableRef}
        columns={[
          {
            title: formatMessage({ id: 'admin.sendouts.table.sendoutDate' }),
            field: 'sendoutDateUtc',
            render: (data) => moment.utc(data.sendoutDateUtc).local().format('lll'),
          },

          { title: formatMessage({ id: 'admin.sendouts.table.recipients' }), field: 'recipient' },
          {
            title: formatMessage({ id: 'admin.sendouts.table.type' }),
            field: 'sendoutType',
          },
          {
            title: formatMessage({ id: 'admin.sendouts.table.status' }),
            field: 'sent',
            render: (data) =>
              data.sent
                ? formatMessage({ id: 'admin.sendouts.table.sent' })
                : data.failed
                ? formatMessage({ id: 'admin.sendouts.table.failed' })
                : formatMessage({ id: 'admin.sendouts.table.pending' }),
          },
          {
            title: formatMessage({ id: 'admin.sendouts.table.lastRun' }),
            field: 'lastRunUtc',
            render: (data) => (data.lastRunUtc ? moment.utc(data.lastRunUtc).local().format('lll') : '-'),
          },
        ]}
        data={handleDataFetch}
        actionLabel={''}
        actions={[
          (row) => ({
            tooltip: formatMessage({ id: 'component.mtable.viewContentTooltip' }),
            icon: 'mail',
            onClick: () => openMail(row.sendoutId),
          }),
        ]}
        isLoading={loading}
      />
      <MailDialog subject={subject} htmlContent={content} isOpen={open} handleClose={() => setOpen(false)} />
    </Container>
  );
};
