import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Query } from 'material-table';
import { MTable, Form } from '../../../../Components';
import { FormValues, FormFieldValueType } from '../../../../Components/Form/Form.types';
import { useFormField } from '../../../../Components/Form/FormField';
import { AptorApi, useAptorApi, useFetchSelectOptions } from '../../../../Api';
import {
  INamedEntity,
  IListResult,
  IGridQueryResult,
  IGridQueryRequest,
  OrderDirection,
} from '../../../../Api/AptorApi';
import { useStyles } from '../Organization/Organization.styles';

export interface IProcess extends INamedEntity {}

interface IProps {
  optionFetcher: () => Promise<IListResult<INamedEntity>>;
  dataFetcher: (request: IGridQueryRequest) => Promise<IGridQueryResult<IProcess>>;
  addNew: (api: AptorApi, data: FormValues) => Promise<void>;
  remove: (api: AptorApi, processid: number) => Promise<void>;
}

export const Processes: FC<IProps> = ({ optionFetcher, dataFetcher, addNew, remove }) => {
  const { api, abortController } = useAptorApi();
  const tableRef = React.createRef();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [processes, , , refetch] = useFetchSelectOptions(optionFetcher, abortController);

  const newProcessField = useFormField({
    label: formatMessage({ id: 'law-portal.manage.edit-law.addProcess' }),
    name: 'processId',
    type: 'single-select',
    options: processes,
    required: true,
  });
  const setProcess = newProcessField.setValue;

  //Initialization
  const handleDataFetch = useCallback(
    async (query: Query<IProcess>) => {
      return await dataFetcher({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
      } as IGridQueryRequest);
    },
    [dataFetcher],
  );

  const handleAddProcess = async (data: FormValues, api: AptorApi, onSuccess: () => void) => {
    await addNew(api, { ...data, processes: [data.processId as FormFieldValueType] });
    if (api.abortController?.signal.aborted) {
      return;
    }

    onSuccess();
    (tableRef.current as any).onQueryChange();
    setProcess(undefined);
    refetch();
  };

  const removeProcess = (process: IProcess) => ({
    tooltip: formatMessage({ id: 'component.mtable.deleteTooltip' }),
    icon: 'delete',
    onClick: async () => {
      await remove(api, process.id);
      if (abortController.current.signal.aborted) {
        return;
      }
      (tableRef.current as any).onQueryChange();
      refetch();
    },
  });

  return (
    <>
      <Form
        className={classes.form}
        singleField={newProcessField}
        submit={handleAddProcess}
        submitLabel={formatMessage({ id: 'form.add' })}
      />

      <MTable
        hideToolbar
        tableRef={tableRef}
        columns={[{ title: formatMessage({ id: 'organization.processes' }), field: 'name' }]}
        data={handleDataFetch}
        actions={[removeProcess]}
      />
    </>
  );
};
