import React, { useCallback, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Query } from 'material-table';
import MTable from '../../../../../Components/MTable/MTable';
import { HtmlView } from '../../../../../Components/Html/Html.View';
import { OwnNotes } from '../../../../../Components/OwnNotes';
import { useFilter } from '../../../../../Components/ListFilter';
import { RequirementListFilter } from './Filter';
import { ModuleContext } from '../../../../../Context/ModuleContext/ModuleContext';
import { IGridQueryRequest, INamedEntity, OrderDirection } from '../../../../../Api/AptorApi';
import { useAptorApi } from '../../../../../Api';
import { useIsMounted } from '../../../../../Hooks/useIsMounted';
import { useStyles } from '../../../LawPortal.styles';
import moment from 'moment';
import { RepealableName } from '../../../../../Components';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../Context/UserContext/UserContext';

type RequirementData = {
  id: number;
  name: string;
  description: string;
  area: string;
  effectiveTo?: Date;
  changedDate?: Date;
  companyUnits: Array<INamedEntity & { note: string | null }>;
};

export const RequirementList = () => {
  const { formatMessage } = useIntl();
  const { api } = useAptorApi();
  const [filters, setFilter] = useFilter();
  const tableRef = React.createRef();
  const history = useHistory();
  const isMounted = useIsMounted();
  const { setHeader } = useContext(ModuleContext);
  const user = useContext(UserContext);
  const isManager = user.hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  const classes = useStyles();

  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.requirements.header', defaultMessage: 'Other requirements' }));
  }, [setHeader, formatMessage]);

  useEffect(() => {
    if (isMounted) {
      tableRef.current && (tableRef.current as any).onQueryChange();
    }
  }, [filters, tableRef, isMounted]);

  const handleDataFetch = useCallback(
    async (query: Query<RequirementData>) => {
      return await api.searchLawPortalRequirements<RequirementData>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  const renderDate = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
  };

  return (
    <div className={classes.listWrapper}>
      <RequirementListFilter filters={filters} setFilter={setFilter} />
      <MTable
        tableRef={tableRef}
        columns={[
          {
            title: formatMessage({ id: 'law-portal.requirements.table.name' }),
            field: 'name',
            render: (row) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
          },
          {
            title: formatMessage({ id: 'law-portal.requirements.table.description' }),
            field: 'description',
            render: (row) => <HtmlView variant="body2" value={row.description} />,
            sorting: false,
          },
          { title: formatMessage({ id: 'law-portal.requirements.table.area' }), field: 'area' },
          {
            title: formatMessage({ id: 'law-portal.requirements.table.changedDate' }),
            field: 'changedDate',
            render: (row) => renderDate(row.changedDate),
          },
          {
            title: formatMessage({ id: 'law-portal.requirements.table.notes' }),
            field: 'notes',
            render: (row) => <OwnNotes {...row} />,
            sorting: false,
          },
        ]}
        data={handleDataFetch}
        onRowClick={(_, row) =>
          row &&
          (isManager || user.complianceUserFor.requirements.some((x) => x.id === row.id)) &&
          history.push(`/law-portal/other-requirements/${row.id}`)
        }
        options={{
          rowStyle: (row) =>
            (isManager || user.complianceUserFor.requirements.some((x) => x.id === row.id)) === false
              ? { pointerEvents: 'none' }
              : {},
        }}
      />
    </div>
  );
};
