import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: '14rem',
    padding: theme.spacing(2),
  },
  disablePadding: {
    padding: 0,
  },
  small: {
    width: '10rem',
  },
}));
