import React, { FC, useContext } from 'react';
import { EditablePanel, Panel } from '../../../../Components';
import { NotesView } from './View';
import { NotesForm } from './Form';
import { AptorApi } from '../../../../Api';
import { INamedEntity } from '../../../../Api/AptorApi';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../Context/UserContext/UserContext';

interface IProps {
  companyUnits: Array<INamedEntity & { note: string | null }>;
  editNote: (companyUnitId: number, note: string | null, api: AptorApi, onSuccess: () => void) => Promise<void>;
}

export const Notes: FC<IProps> = ({ companyUnits, editNote }) => {
  const { hasAccessToFeature } = useContext(UserContext);
  const canAdministerNotes = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);

  if (canAdministerNotes) {
    return (
      <EditablePanel
        titleKey="law-portal.overview-notes.label"
        iconType="add"
        view={<NotesView companyUnits={companyUnits} editNote={editNote} />}
        form={<NotesForm companyUnits={companyUnits.filter((x) => x.note === null)} submit={editNote} />}
        dependencies={[companyUnits]}
      />
    );
  }

  return (
    <Panel titleKey="law-portal.overview-notes.label">
      <NotesView companyUnits={companyUnits} />
    </Panel>
  );
};
