import React from 'react';
import { Card } from '@material-ui/core';
import { useStyles } from './SnackbarMessageContainer.styles';

interface IProps {
  children: React.ReactNode;
}

export const SnackbarMessageContainer = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const classes = useStyles();
  return (
    <Card ref={ref} className={classes.root}>
      {props?.children}
    </Card>
  );
});
