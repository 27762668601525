import React, { FC, useContext } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { UserContext } from '../../Context/UserContext/UserContext';
import translations_sv from '../../Translations/sv.json';
import translations_en from '../../Translations/en.json';

export const IntlProvider: FC = ({ children }) => {
  const messages = {
    sv: translations_sv,
    en: translations_en,
  };
  const user = useContext(UserContext);

  return (
    <ReactIntlProvider locale={user.locale} messages={messages[user.locale]}>
      {children}
    </ReactIntlProvider>
  );
};
