import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { ListFilter, createSelectFilter } from '../../../../Components/ListFilter';

interface IProps {
  setFilter: (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => void;
}

export const CustomerListFilter: FC<IProps> = ({ setFilter, children }) => {
  const { formatMessage } = useIntl();
  const activeOption = { value: 1, label: formatMessage({ id: 'utils.filters.status.active' }) };
  const inactiveOption = { value: 0, label: formatMessage({ id: 'utils.filters.status.inactive' }) };

  const statusFilter = createSelectFilter(
    'active',
    formatMessage({ id: 'utils.filters.status' }),
    [activeOption, inactiveOption],
    (options) => `${options.value}`,
    activeOption,
  );

  return <ListFilter filters={[statusFilter]} setFilter={setFilter} children={children} />;
};
