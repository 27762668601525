import React, { FC } from 'react';
import Form from '../../../../../../Components/Form/Form';
import { useIntl } from 'react-intl';
import { AptorApi } from '../../../../../../Api';
import { useFormField } from '../../../../../../Components/Form/FormField';
import { FormValues } from '../../../../../../Components/Form/Form.types';

export interface IRequirementChange {
  id: number;
  name: string;
  effectiveFrom: Date;
  shortDescription: string;
  isPosibleToDelete: boolean;
}

interface IFormProps {
  submit: (form: FormValues, api: AptorApi, onSuccess: (key?: string) => void) => Promise<void>;
  cancel?: () => void;
  initialState?: IRequirementChange;
}

const EditRequirementChangesForm: FC<IFormProps> = ({ submit, cancel, initialState }) => {
  const { formatMessage } = useIntl();
  const name = useFormField({
    label: formatMessage({ id: 'law-portal.manage.requirements.change.name', defaultMessage: 'Change name' }),
    name: 'name',
    type: 'text',
    required: true,
    initialState: initialState && { value: initialState.name },
  });

  const effectiveFrom = useFormField({
    label: formatMessage({
      id: 'law-portal.manage.requirements.change.effectiveFrom.label',
      defaultMessage: 'Effective from',
    }),
    name: 'effectiveFrom',
    type: 'date',
    required: true,
    initialState: initialState && { value: initialState.effectiveFrom },
  });

  const description = useFormField({
    label: formatMessage({
      id: 'law-portal.manage.requirements.change.description',
      defaultMessage: 'Short description',
    }),
    name: 'description',
    type: 'rich-text',
    richTextEditorHeight: 'small',
    required: true,
    initialState: initialState && { value: initialState.shortDescription },
  });

  return (
    <Form
      submit={submit}
      cancel={cancel}
      groups={[
        {
          grid: {
            type: 'column',
            items: [
              {
                type: 'row',
                items: [
                  { type: 'column', flex: 2, items: [name, effectiveFrom] },
                  { type: 'column', flex: 3, items: [description] },
                ],
              },
            ],
          },
        },
      ]}
    />
  );
};

interface IProps {
  id: number;
  change: IRequirementChange;
  changes?: IRequirementChange[];
  onChange: (params: { changes: IRequirementChange[] }) => void;
  cancel?: () => void;
}

const parseForm = (form: FormValues, id: number, removable: boolean = true) => {
  return {
    id: id,
    name: form['name'] as string,
    effectiveFrom: (form['effectiveFrom'] as unknown) as Date,
    shortDescription: form['description'] as string,
    isPosibleToDelete: removable,
  };
};

export const RequirementAddChange: FC<Omit<IProps, 'change'>> = ({ id, changes, onChange }) => {
  const submit = async (form: FormValues, api: AptorApi, onSuccess: () => void) => {
    if (!changes) changes = [];
    const response = await api.addRequirementChange(id, form);
    onSuccess();
    onChange({
      changes: [parseForm(form, response.id), ...changes],
    });
  };

  return <EditRequirementChangesForm submit={submit} />;
};
export const RequirementEditChange: FC<IProps> = ({ id, change, changes, onChange, cancel }) => {
  const submit = async (form: FormValues, api: AptorApi, onSuccess: () => void) => {
    if (!changes) changes = [];
    await api.updateRequirementChange(id, change.id, form);
    onSuccess();
    onChange({
      changes: [parseForm(form, change.id), ...changes.filter((c) => c.id !== change.id)].sort((a, b) => {
        return new Date(b.effectiveFrom).getTime() - new Date(a.effectiveFrom).getTime();
      }),
    });
  };

  return <EditRequirementChangesForm submit={submit} initialState={change} cancel={cancel}/>;
};
