import React, { useContext, FC } from 'react';
import { useIntl } from 'react-intl';
import { Form } from '../../../../../Components';
import { FormValues, SelectOption } from '../../../../../Components/Form/Form.types';
import { useFormField } from '../../../../../Components/Form/FormField';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { AptorApi } from '../../../../../Api';
import { INamedEntity } from '../../../../../Api/AptorApi';
import { entityToSelectOption } from '../../../../../Utilities';
import { OptionsType } from 'react-select';

export interface IPermissions {
  manageOrganization: IFeatureAccess;
  manageLaws: IFeatureAccess;
  statistics: IFeatureAccess;
  laws: IFeatureAccess;
  invitations: IFeatureAccess;
  other: IFeatureAccess;
}

export interface ICustomerUser {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  section: string;
  position: string;
  language: INamedEntity;
  companyUnits: INamedEntity[];
}

interface IFeatureAccess extends INamedEntity {
  options: INamedEntity[];
}

interface IProps {
  user?: ICustomerUser;
  companyUnit?: SelectOption;
  submit: (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => Promise<void>;
  submitLabelKey?: string;
  availableLanguages: OptionsType<SelectOption>;
  companyUnits: OptionsType<SelectOption>;
  permissions: IPermissions;
}

export const UserForm: FC<IProps> = ({
  user,
  companyUnit,
  availableLanguages,
  companyUnits,
  permissions,
  submit,
  submitLabelKey,
}) => {
  const { getCompanyUnitName } = useContext(UserContext);
  const { formatMessage } = useIntl();

  const firstNameField = useFormField({
    label: formatMessage({ id: 'form.firstName' }),
    name: 'firstName',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInFirstName' }),
    required: true,
    initialState: user ? { value: user.firstName } : undefined,
  });

  const lastNameField = useFormField({
    label: formatMessage({ id: 'form.lastName' }),
    name: 'lastName',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInLastName' }),
    required: true,
    initialState: user ? { value: user.lastName } : undefined,
  });

  const emailField = useFormField({
    label: formatMessage({ id: 'form.email' }),
    name: 'email',
    type: user ? 'readonly' : 'email',
    placeholder: formatMessage({ id: 'form.fillInEmail' }),
    required: true,
    initialState: user ? { value: user.email } : undefined,
  });

  const phoneNumberField = useFormField({
    label: formatMessage({ id: 'form.phoneNumber' }),
    name: 'phoneNumber',
    type: 'phoneNumber',
    placeholder: formatMessage({ id: 'form.fillInPhoneNumber' }),
    required: false,
    initialState: user ? { value: user.phoneNumber } : undefined,
  });

  const sectionField = useFormField({
    label: formatMessage({ id: 'form.section' }),
    name: 'section',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInSection' }),
    required: false,
    initialState: user ? { value: user.section } : undefined,
  });

  const positionField = useFormField({
    label: formatMessage({ id: 'form.position' }),
    name: 'position',
    type: 'text',
    placeholder: formatMessage({ id: 'form.fillInPosition' }),
    required: false,
    initialState: user ? { value: user.position } : undefined,
  });

  const languageField = useFormField({
    label: formatMessage({ id: 'form.selectLanguage' }),
    name: 'language',
    type: 'single-select',
    options: availableLanguages,
    required: true,
    initialState: user ? entityToSelectOption(user.language) : availableLanguages[0],
  });

  const companyUnitsField = useFormField({
    label: formatMessage(
      { id: 'organization.users.connect-company-unit' },
      { alias: getCompanyUnitName(formatMessage, true) },
    ),
    placeholder: formatMessage({ id: 'form.selectCompanyUnits' }),
    name: 'companyUnits',
    type: 'multi-select',
    options: companyUnits,
    required: true,
    initialState: user ? user.companyUnits.map(entityToSelectOption) : companyUnit ? [companyUnit] : [],
  });

  //Permissions
  const manageOrganization = useFormField({
    label: formatMessage({ id: 'organization.users.permissions.manageOrganization' }),
    icon: 'business',
    name: 'manageOrganizationAccessLevel',
    type: 'single-select-card',
    options: permissions.manageOrganization.options.map(entityToSelectOption),
    required: true,
    initialState: entityToSelectOption(permissions.manageOrganization),
  });

  const manageLaws = useFormField({
    label: formatMessage({ id: 'organization.users.permissions.manageLaws' }),
    icon: 'gavel',
    name: 'manageLawsAccessLevel',
    type: 'single-select-card',
    options: permissions.manageLaws.options.map(entityToSelectOption),
    required: true,
    initialState: entityToSelectOption(permissions.manageLaws),
  });

  const statisticsField = useFormField({
    label: formatMessage({ id: 'organization.users.permissions.statistics' }),
    icon: 'bar_chart',
    name: 'statisticsAccessLevel',
    type: 'single-select-card',
    options: permissions.statistics.options.map(entityToSelectOption),
    required: true,
    initialState: entityToSelectOption(permissions.statistics),
  });

  const lawsField = useFormField({
    label: formatMessage({ id: 'organization.users.permissions.laws' }),
    icon: 'gavel',
    name: 'lawsAccessLevel',
    type: 'single-select-card',
    options: permissions.laws.options.map(entityToSelectOption),
    required: true,
    initialState: entityToSelectOption(permissions.laws),
  });

  const invitationsField = useFormField({
    label: formatMessage({ id: 'organization.users.permissions.invitations' }),
    icon: 'settings',
    name: 'invitationsAccessLevel',
    type: 'single-select-card',
    options: permissions.invitations.options.map(entityToSelectOption),
    required: true,
    initialState: entityToSelectOption(permissions.invitations),
  });

  const otherField = useFormField({
    label: formatMessage({ id: 'organization.users.permissions.other' }),
    icon: 'format_list_bulleted',
    name: 'otherAccessLevel',
    type: 'single-select-card',
    options: permissions.other.options.map(entityToSelectOption),
    required: true,
    initialState: entityToSelectOption(permissions.other),
  });

  return (
    <Form
      submit={submit}
      submitLabel={submitLabelKey ? formatMessage({ id: submitLabelKey }) : undefined}
      groups={[
        {
          label: 'organization.users.information',
          grid: {
            type: 'column',
            items: [
              { type: 'row', items: [firstNameField, lastNameField, null] },
              { type: 'row', items: [emailField, phoneNumberField, languageField] },
              { type: 'row', items: [sectionField, positionField, companyUnitsField] },
            ],
          },
        },
        {
          label: 'organization.users.permissions',
          grid: {
            type: 'column',
            items: [
              { type: 'row', items: [manageOrganization, manageLaws] },
              { type: 'row', items: [lawsField, otherField] },
              { type: 'row', items: [statisticsField, invitationsField] },
            ],
          },
        },
      ]}
    />
  );
};
