import React, { useCallback, useEffect } from 'react';
import MTable from '../../../../Components/MTable/MTable';
import { useIntl } from 'react-intl';
import { Container } from '../../../../Components/Container/Container';
import { useAptorApi } from '../../../../Api';
import { Query } from 'material-table';
import { IGridQueryRequest, OrderDirection } from '../../../../Api/AptorApi';
import { LawListFilter } from './List.Filter';
import { useHistory } from 'react-router-dom';
import { useFilter } from '../../../../Components/ListFilter';
import moment from 'moment';
import { RepealableName } from '../../../../Components';

type LawData = {
  id: number;
  number: string;
  name: string;
  changeEffectiveFrom?: Date;
  changeNumber?: string;
  effectiveTo?: Date;
  tags?: string;
};

export const LawTable = () => {
  const { formatMessage } = useIntl();
  const { api } = useAptorApi();
  const [filters, setFilter] = useFilter();
  const tableRef = React.createRef();
  const history = useHistory();

  useEffect(() => {
    tableRef.current && (tableRef.current as any).onQueryChange();
  }, [filters, tableRef]);

  const handleDataFetch = useCallback(
    async (query: Query<LawData>) => {
      return await api.searchLaws<LawData>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'number',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  const renderDate = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
  };

  return (
    <Container fullWidth linkButtonTo="/admin/law-portal/laws/new" linkButtonLabel="admin.law-portal.add-law">
      <LawListFilter setFilter={setFilter} />
      <MTable
        tableRef={tableRef}
        columns={[
          { title: formatMessage({ id: 'admin.law-portal.table.number' }), field: 'number' },
          {
            title: formatMessage({ id: 'admin.law-portal.table.law' }),
            field: 'name',
            render: (row) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
          },
          { title: formatMessage({ id: 'admin.law-portal.table.changeNumber' }), field: 'changeNumber' },
          {
            title: formatMessage({ id: 'admin.law-portal.table.changeDate' }),
            field: 'changeEffectiveFrom',
            render: (row) => renderDate(row.changeEffectiveFrom),
          },
          { title: formatMessage({ id: 'admin.law-portal.table.tags' }), field: 'tags', sorting: false },
        ]}
        data={handleDataFetch}
        actions={[
          (row) => ({
            tooltip: formatMessage({ id: 'component.mtable.editTooltip' }),
            icon: 'edit',
            onClick: () => history.push(`/admin/law-portal/laws/${row.id}`),
          }),
        ]}
      />
    </Container>
  );
};
