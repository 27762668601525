import React, { FC } from 'react';
import { List } from '@material-ui/core';
import { ChecklistItem } from '../Checklist.Item/Checklist.Item';
import { ChecklistGroupItem } from '../Checklist.Group/Checklist.Group';
import { useStyles } from './Checklist.List.styles';

interface IQuestion {
  id?: number;
  name: string;
}

export interface IListItem extends IQuestion {
  type: 'group' | 'question';
  groupItems: IQuestion[];
}

interface IProps {
  items: IListItem[];
  updateItems: (items: IListItem[]) => void;
}

export const Checklist: FC<IProps> = ({ items, updateItems }) => {
  /* Move a question up, down or into a group */
  const swapItems = (indexA: number, indexB: number) => {
    let newOrder = [...items];
    const a = newOrder[indexA];
    const b = newOrder[indexB];
    if (a.type === b.type || a.type === 'group') {
      // swap
      newOrder[indexA] = b;
      newOrder[indexB] = a;
    } else if (a.type === 'question' && b.type === 'group') {
      // move into group
      const question = { id: a.id, name: a.name };
      newOrder[indexB].groupItems = indexA > indexB ? [...b.groupItems, question] : [question, ...b.groupItems];
      newOrder = newOrder.filter((i) => i !== a);
    }
    updateItems(newOrder);
  };

  const removeQuestion = (index: number) => updateItems(items.filter((_, i) => i !== index));

  /* Removes a group but leaves the questions */
  const removeGroup = (index: number) => {
    const newItems = [...items];
    const group = newItems[index];
    newItems.splice(index, 1);
    if (group.groupItems.length > 0) {
      const questions = group.groupItems.map(
        (question) => ({ ...question, ...{ type: 'question', groupItems: [] } } as IListItem),
      );
      newItems.splice(index, 0, ...questions);
    }
    updateItems(newItems);
  };

  /* Update items within a group */
  const onGroupItemsChange = (groupIndex: number, groupItems: IQuestion[]) => {
    let newItems = [...items];
    newItems[groupIndex].groupItems = groupItems;
    updateItems(newItems);
  };

  /* Move a first group question out of group and place before group */
  const onGroupMoveTopItemOut = (groupIndex: number) => {
    let newItems = [...items];
    const question = newItems[groupIndex].groupItems[0];
    newItems[groupIndex].groupItems.splice(0, 1);
    newItems.splice(groupIndex, 0, { ...question, ...{ type: 'question', groupItems: [] } });
    updateItems(newItems);
  };

  /* Move a last group question out of group and place after group */
  const onGroupMoveBottomItemOut = (groupIndex: number) => {
    let newItems = [...items];
    const bottomIndex = newItems[groupIndex].groupItems.length - 1;
    const question = newItems[groupIndex].groupItems[bottomIndex];
    newItems[groupIndex].groupItems.splice(bottomIndex, 1);
    newItems.splice(groupIndex + 1, 0, { ...question, ...{ type: 'question', groupItems: [] } });
    updateItems(newItems);
  };

  const classes = useStyles();

  return (
    <List className={classes.root}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {item.type === 'question' && (
            <ChecklistItem
              label={item.name}
              moveUp={() => swapItems(index, index - 1)}
              moveDown={() => swapItems(index, index + 1)}
              disableUp={index === 0}
              disableDown={index === items.length - 1}
              remove={() => Promise.resolve(removeQuestion(index))}
            />
          )}
          {item.type === 'group' && (
            <ChecklistGroupItem
              label={item.name}
              items={item.groupItems}
              moveUp={() => swapItems(index, index - 1)}
              moveDown={() => swapItems(index, index + 1)}
              disableUp={index === 0}
              disableDown={index === items.length - 1}
              onItemsChange={(items) => onGroupItemsChange(index, items)}
              moveTopItemOut={() => onGroupMoveTopItemOut(index)}
              moveBottomItemOut={() => onGroupMoveBottomItemOut(index)}
              remove={() => removeGroup(index)}
            />
          )}
        </React.Fragment>
      ))}
    </List>
  );
};
