import React, { FC } from 'react';
import { Card, List } from '@material-ui/core';
import { ChecklistItem, IProps as IChecklistProps } from '../Checklist.Item/Checklist.Item';
import { useStyles } from './Checklist.Group.styles';

interface IQuestion {
  id?: number;
  name: string;
}

interface IProps extends Omit<IChecklistProps, 'skipRemoveConfirmation'> {
  items: IQuestion[];
  moveTopItemOut: () => void;
  moveBottomItemOut: () => void;
  onItemsChange: (items: IQuestion[]) => void;
}

export const ChecklistGroupItem: FC<IProps> = ({
  label,
  items,
  moveUp,
  moveDown,
  remove,
  disableUp,
  disableDown,
  moveTopItemOut,
  moveBottomItemOut,
  onItemsChange,
}) => {
  const swapGroupItems = (indexA: number, indexB: number) => {
    if (indexB === -1) {
      moveTopItemOut();
    } else if (indexB === items.length) {
      moveBottomItemOut();
    } else {
      const groupItems = [...items];
      groupItems[indexA] = items[indexB];
      groupItems[indexB] = items[indexA];
      onItemsChange(groupItems);
    }
  };

  const removeGroupItem = (index: number) => onItemsChange(items.filter((_, i) => i !== index));

  const classes = useStyles();

  return (
    <Card variant="outlined" component="li" className={classes.card}>
      <List
        className={classes.list}
        aria-labelledby={label}
        subheader={
          <ChecklistItem
            header
            label={label}
            moveUp={moveUp}
            moveDown={moveDown}
            disableUp={disableUp}
            disableDown={disableDown}
            remove={remove}
            skipRemoveConfirmation
          />
        }
      >
        {items.map((question, index) => {
          return (
            <ChecklistItem
              key={`group-${index}`}
              label={question.name}
              moveUp={() => swapGroupItems(index, index - 1)}
              moveDown={() => swapGroupItems(index, index + 1)}
              remove={() => removeGroupItem(index)}
            />
          );
        })}
      </List>
    </Card>
  );
};
