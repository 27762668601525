import React from 'react';
import { Grid } from '@material-ui/core';
import { LawSettingsAreas } from './Areas';
import { LawSettingsCategories } from './Categories';
import { LawSettingsTags } from './Tags';
import { Container } from '../../../../../Components';

export const LawSettings = () => {
  return (
    <Container>
      <Grid container spacing={4} direction="column" justify="space-evenly">
        <Grid item>
          <LawSettingsAreas />
        </Grid>
        <Grid item>
          <LawSettingsCategories />
        </Grid>
        <Grid item>
          <LawSettingsTags />
        </Grid>
      </Grid>
    </Container>
  );
};
