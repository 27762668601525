import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { InputBase } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { FieldHelperOrErrors } from './FieldHelperOrErrors';
import { useStyles } from './Field.styles';
import { FormFieldRef, InputFieldProps } from './Field.props';
import { shallowCompare } from './shallowCompare';
import { useDebounce } from 'use-lodash-debounce';
import { TypedFormFieldValue } from '../Form/Form.types';

export const TextField = memo(
  forwardRef<FormFieldRef<string>, InputFieldProps>((props, ref) => {
    const classes = useStyles();
    const {
      isDisabled,
      label,
      helperText,
      errorTexts,
      onChange,
      inputMaskProps,
      required,
      initialState,
      hideRequiredAsterisk,
      ...rest
    } = props;

    const [text, setText] = useState((initialState as TypedFormFieldValue<string> | undefined)?.value ?? '');
    const debouncedText = useDebounce(text, 200);

    const triggerOnChange = useCallback(
      (text: string) => {
        if (onChange) {
          if (!required && text === '') {
            onChange(undefined);
          } else {
            onChange({ value: text, isValid: !required || text !== '' });
          }
        }
      },
      [required, onChange],
    );

    useImperativeHandle(
      ref,
      () => ({
        setValue: (value: TypedFormFieldValue<string> | TypedFormFieldValue<string>[] | undefined) => {
          if (!Array.isArray(value)) {
            setText(value?.value ?? '');
          }
        },
      }),
      [],
    );

    useEffect(() => {
      triggerOnChange(debouncedText);
    }, [debouncedText, triggerOnChange]);

    return (
      <FormControl error={props.error} className={classes.formControl}>
        <Typography className={classes.label} component="label" htmlFor={props.name}>
          {props.label}
          {props.required && !hideRequiredAsterisk && <span aria-hidden="true">*</span>}
        </Typography>
        {(inputMaskProps && (
          <InputMask {...inputMaskProps} onChange={(event) => setText(event.target.value)} value={text}>
            {(inputProps: any) => (
              <InputBase
                {...rest}
                classes={{ focused: classes.focused, error: classes.error }}
                disabled={isDisabled}
                {...inputProps}
              />
            )}
          </InputMask>
        )) || (
          <InputBase
            {...rest}
            onChange={(event) => setText(event.target.value)}
            value={text}
            disabled={isDisabled}
            classes={{ focused: classes.focused, error: classes.error }}
          />
        )}
        <FieldHelperOrErrors helperText={helperText} errorTexts={errorTexts} />
      </FormControl>
    );
  }),
  (newProps, oldProps) => {
    return shallowCompare(newProps, oldProps, ['errorTexts']);
  },
);

export default TextField;
