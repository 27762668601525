import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { BackButton, Container, Loading, Panel } from '../../../../../Components';
import { FormValues, SelectOption } from '../../../../../Components/Form/Form.types';
import { AptorApi, useAptorApi } from '../../../../../Api';
import { entityToSelectOption } from '../../../../../Utilities';
import { UserForm, IPermissions } from './Users.Form';
import { useApiValidationSnackbar } from '../../../../../Api/useValidationSnackbar';

interface IState {
  newUserInfo: { permissions: IPermissions };
  languages: SelectOption[];
  companyUnits: SelectOption[];
}

export const UserInviteForm = () => {
  const { notifyUnhandledException, notifyValidationErrors } = useApiValidationSnackbar();
  const { api, abortController } = useAptorApi();
  const match = useRouteMatch<{ companyUnitId?: string }>();
  const companyUnitId = match.params.companyUnitId ? parseInt(match.params.companyUnitId) : undefined;
  const [state, loadState] = useState<IState>();
  const history = useHistory();

  const returnLink = companyUnitId ? `/organization/company-units/${companyUnitId}` : '/organization';

  useEffect(() => {
    Promise.all([
      api.getNewCustomerUser<{ permissions: IPermissions }>(),
      api.getLanguages(),
      api.getOrganizationCompanyUnits(),
    ]).then(([newUserInfo, lang, units]) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      loadState({
        newUserInfo,
        languages: lang.items.map(entityToSelectOption),
        companyUnits: companyUnitId
          ? units.items.filter((i) => i.id === companyUnitId).map(entityToSelectOption)
          : units.items.map(entityToSelectOption),
      });
    });
  }, [api, companyUnitId, abortController]);

  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => {
    const action = (a: AptorApi) =>
      a.inviteCustomerUser(companyUnitId, data).then(() => {
        onSuccess('form.successfullyInvitedUser');
        history.push(returnLink);
      });

    await api.invoke(action, abortController.current, notifyValidationErrors, notifyUnhandledException);
  };

  if (state === undefined) {
    return <Loading />;
  }

  return (
    <Container>
      <BackButton link={returnLink} />
      <Panel titleKey="admin.users.invite.addUser">
        <UserForm
          companyUnit={companyUnitId ? state.companyUnits[0] : undefined}
          permissions={state.newUserInfo.permissions}
          submit={handleSubmit}
          submitLabelKey="admin.users.invite.invite"
          availableLanguages={state.languages}
          companyUnits={state.companyUnits}
        />
      </Panel>
    </Container>
  );
};
