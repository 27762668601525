import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
    },
  },
  cardMedia: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      width: '40%',
    },
  },
  cardContent: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  link: {
    textDecoration: 'none',
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));
