import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStyles } from './Home.styles';
import { ArrowBackIos } from '@material-ui/icons';
import { ButtonLink } from '../ButtonLink/ButtonLink';

export const Forbidden = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      <Typography className={classes.heading} variant="h1" align="center" color="primary" gutterBottom>
        <FormattedMessage id="component.forbidden" />
      </Typography>
      <Typography className={classes.subHeading} variant="h2" align="center">
        <FormattedMessage id="component.forbidden-description" />
      </Typography>
      <Grid container justify="center">
        <ButtonLink
          to={'/'}
          text={formatMessage({ id: 'component.back-button.back', defaultMessage: 'Back' })}
          variant="text"
          icon={<ArrowBackIos />}
        />
      </Grid>
    </>
  );
};
