import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Panel, BackButton } from '../../../../../Components';
import { CompanyUsers } from '../Users/Users.List';
import { ProcessList } from './Processes';
import { AspectList } from './Aspects';
import { TagList } from './Tags';
import { EditNameInline } from '../EditName';
import { LogotypeForm } from '../LogotypeForm';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { useAptorApi } from '../../../../../Api';
import { AptorApi, INamedEntity } from '../../../../../Api/AptorApi';
import { FormValues } from '../../../../../Components/Form/Form.types';

interface ICompanyUnit extends INamedEntity {
  logotype: string;
}

export const CompanyUnitEditForm = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api, abortController } = useAptorApi();
  const { formatMessage } = useIntl();
  const { getCompanyUnitName } = useContext(UserContext);
  const initFlag = useRef(false);
  const [unitName, setUnitName] = useState<string>();
  const [logotype, setLogotype] = useState<string>();

  //Initialization
  const initializeUnit = useCallback(async () => {
    const info = await api.getCompanyUnit<ICompanyUnit>(id);
    initFlag.current = true;
    setUnitName(info.name);
    setLogotype(info.logotype);
  }, [id, api]);

  useEffect(() => {
    if (!initFlag.current) {
      initializeUnit();
    }
  }, [initializeUnit]);

  const submitEditUnit = async (data: FormValues, api: AptorApi) => {
    await api.updateCompanyUnit(id, data);
    if (abortController.current.signal.aborted) {
      return;
    }
    initializeUnit();
  };

  return (
    <>
      <BackButton disableMargin link="/organization" />
      <Grid container spacing={4} direction="column" justify="space-evenly" wrap="nowrap">
        <Grid item>
          <Panel defaultExpanded accordion titleKey={getCompanyUnitName(formatMessage)}>
            <EditNameInline name={unitName} submit={submitEditUnit} />
          </Panel>
        </Grid>
        <Grid item>
          <Panel titleKey="organization.users" accordion>
            <CompanyUsers companyUnitId={id} />
          </Panel>
        </Grid>
        <Grid item>
          <Panel titleKey="organization.processes" accordion>
            <ProcessList unitId={id} />
          </Panel>
        </Grid>
        <Grid item>
          <Panel titleKey="organization.aspects" accordion>
            <AspectList unitId={id} />
          </Panel>
        </Grid>
        <Grid item>
          <Panel titleKey="organization.tags" accordion>
            <TagList unitId={id} />
          </Panel>
        </Grid>
        <Grid item>
          <Panel accordion titleKey="organization.company-units.logotype">
            <LogotypeForm id={id} type="companyUnit" currentLogo={logotype} onLogoChanged={() => initializeUnit()} />
          </Panel>
        </Grid>
      </Grid>
    </>
  );
};
