import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { PropertyError } from '../Api/AptorApi';
import { notificationValidationErrors } from '../Utilities';

interface INotificationOptions {
  message?: React.ReactNode;
  content?: React.ReactNode;
}

export const useApiValidationSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const notifySuccess = (customize?: INotificationOptions) => {
    enqueueSnackbar(customize?.message ?? formatMessage({ id: 'utils.snackbar.saved' }), {
      variant: 'success',
      content: customize?.content,
    });
  };
  const notifyValidationErrors = (errors: PropertyError[]) => {
    notificationValidationErrors(errors).forEach((e) => enqueueSnackbar(e, { variant: 'error' }));
    return errors;
  };

  const notifyUnhandledException = (customize?: INotificationOptions) =>
    enqueueSnackbar(customize?.message ?? formatMessage({ id: 'utils.snackbar.somethingWentWrong' }), {
      variant: 'error',
      content: customize?.content,
    });

  return { enqueueSnackbar, notifySuccess, notifyValidationErrors, notifyUnhandledException };
};
