import { Suspense } from 'react';
import React from 'react';
import AptorAdminModule from './AptorAdminModule';

//const AptorAdmin = React.lazy(() => import('./AptorAdminModule'));

const AptorAdminModuleProxy = () => (
  <div>
    <Suspense fallback={<div />}>
      <AptorAdminModule />
    </Suspense>
  </div>
);

export default AptorAdminModuleProxy;
