import React, { FC } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useStyles } from './ConfirmationContext.styles';

interface IProps {
  name?: string;
  description?: React.ReactNode;
}

export const ConfirmationMessage: FC<IProps> = ({ name, description }) => {
  const classes = useStyles();
  if (name && !description) {
    return (
      <FormattedMessage
        id="utils.delete-confirmation.description"
        values={{
          strong: () => (
            <Paper className={classes.paper} component="blockquote" elevation={0} square>
              <Typography>{name}</Typography>
            </Paper>
          ),
        }}
      />
    );
  } else if (description && typeof description === 'string') {
    return <Typography>{description}</Typography>;
  } else if (description) {
    return <>{description}</>;
  }
  return <FormattedMessage id="utils.delete-confirmation.generic-description" />;
};
