import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  focused: {
    boxShadow: `0 0 0 1px #c4122f`, //Box-shadow instead of border to avoid div from moving
  },
  helpLabel: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  errorList: {
    padding: 0,
    '& li': {
      padding: 0,
    },
  },
  label: {
    fontSize: '1rem',
  },
  checkbox: {
    marginTop: theme.spacing(2),
  },
  checkboxError: {
    color: theme.palette.error.main,
  },
  richTextError: {
    outline: `1px solid ${theme.palette.error.main}`,
  },
  selectError: {
    outline: `1px solid ${theme.palette.error.main}`,
  },
  value: {
    fontWeight: theme.typography.fontWeightBold, //For Information.View
  },
  'sr-only': {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: 0,
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0,0,0,0)',
    border: 0,
  },
}));
