import React, { FC, useContext } from 'react';
import { Card, CardActions, CardContent, Grid, IconButton, Typography } from '@material-ui/core';
import { Comment, Delete, Edit } from '@material-ui/icons';
import { useStyles } from './Comments.styles';
import moment from 'moment';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../Context/UserContext/UserContext';
import { IInternalComment } from '../../../../Api/AptorApi';

interface IProps extends IInternalComment {
  deleteComment: (id: number) => Promise<void>;
  editComment: (id: number) => void;
}

export const InternalComment: FC<IProps> = ({
  id,
  from,
  comment,
  createdAt,
  taggedUsers,
  editComment,
  deleteComment,
}) => {
  const classes = useStyles();
  const user = useContext(UserContext);
  const localTime = moment.utc(createdAt).local();
  var timestamp =
    moment.duration(moment(new Date()).diff(localTime)) > moment.duration(1, 'day')
      ? localTime.format('lll')
      : localTime.fromNow();

  const canDelete = user.hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage) || from.id === user.id;
  const canEdit = from.id === user.id;

  return (
    <Card variant="outlined" className={classes.root}>
      <CardContent>
        <Typography variant="overline" component="h4">
          {from.name}
        </Typography>
        <Typography variant="body2" component="span">
          {comment}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Grid container direction="column" spacing={1}>
          <Grid container>
            {taggedUsers.length > 0 && (
              <Grid item>
                {taggedUsers.map((user) => (
                  <React.Fragment key={user.id}>
                    <Typography variant="body2" component="span" color="primary" className={classes.userName}>
                      {`@${user.name}`}
                    </Typography>
                  </React.Fragment>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid container justify="space-between" alignItems="flex-end">
            <Grid item>
              <Typography variant="caption">{timestamp}</Typography>
            </Grid>
            <Grid item>
              {canEdit && (
                <IconButton onClick={() => editComment(id)} aria-label="edit">
                  <Edit fontSize="small" />
                </IconButton>
              )}
              {canDelete && (
                <IconButton onClick={() => deleteComment(id)} aria-label="delete">
                  <Delete fontSize="small" />
                </IconButton>
              )}
              {canDelete === false && canEdit === false && <Comment fontSize="small" />}
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};
