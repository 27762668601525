import React, { useEffect, useState } from 'react';
import { AptorApi, ILawSettingsEntity, LawSettingsType } from '../../../../../Api/AptorApi';
import { useAptorApi } from '../../../../../Api/useAptorApi';
import { FormValues } from '../../../../../Components/Form/Form.types';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';

interface IProps {
  canDelete: (item: ILawSettingsEntity) => boolean;
  getDeleteDescription: (item: ILawSettingsEntity, canDelete: boolean) => React.ReactNode;
}

export const canDelete = (item: ILawSettingsEntity): boolean => {
  return (
    item.connectedLaws === 0 &&
    (item.connectedCustomerRequirements === undefined || item.connectedCustomerRequirements === 0)
  );
};

export const useLawSettingsState = (type: LawSettingsType, props: IProps) => {
  const { api, abortController } = useAptorApi();
  const [items, setItems] = useState<ILawSettingsEntity[]>([]);
  const [editing, setEditing] = useState<ILawSettingsEntity>();
  const { deleteConfirmation } = useConfirmation();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    api.getLawSettings(type).then((result) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setItems(result.items);
      setLoading(false);
    });
  }, [api, abortController, type]);

  const submit = async (form: FormValues, aptorApi: AptorApi, onSuccess: () => void) => {
    const item = {
      name: form['name'] as string,
      description: form['description'] as string | undefined,
    };

    if (editing) {
      await aptorApi.editLawSetting(type, editing.id, item);
      onSuccess();
      if (aptorApi.abortController?.signal.aborted) {
        return;
      }
      const updatedArray = items.replace(
        (x) => x.id === editing.id,
        (_) => ({ ...editing, ...item }),
      );
      setItems(updatedArray);
      setEditing(undefined);
    } else {
      var result = await aptorApi.addLawSetting(type, item);
      onSuccess();
      if (aptorApi.abortController?.signal.aborted) {
        return;
      }
      setItems([...items, { ...item, id: result.id, connectedLaws: 0 }].sortby((x) => x.name));
    }
  };

  const editItem = (item: ILawSettingsEntity) => setEditing(item);
  const cancelEdit = () => setEditing(undefined);

  const handleDeleteLawSetting = async (id: number) => {
    await api.deleteLawSetting(type, id);
    setItems(items.filter((x) => x.id !== id));
  };

  const removeItem = async (item: ILawSettingsEntity) => {
    const canDelete = props.canDelete(item);
    deleteConfirmation(() => handleDeleteLawSetting(item.id), {
      name: item.name,
      disabled: !canDelete,
      description: props.getDeleteDescription(item, canDelete),
    });
  };

  return { items, submit, editItem, removeItem, loading, editing, cancelEdit };
};
