const environmentKey = 'aptor-environment';
const appVersionKey = 'aptor-version';

type EnvironmentType = 'TEST' | 'PROD' | 'DEV' | 'LOCAL';

export enum Environment {
  TEST = 'TEST',
  PROD = 'PROD',
  LOCAL = 'LOCAL',
  DEV = 'DEV',
}

/** Sets environment. Defaults to LOCAL if no env is provided */
export const initEnvironment = (env?: EnvironmentType) => {
  let environment = env ? Environment[env] : Environment.LOCAL;
  (window as any)[environmentKey] = environment;
};

export const setEnvironment = async (): Promise<void> => {
  try {
    const response = await fetch('/appConfig.json');
    const config = await response.json();
    const env = (process.env.REACT_APP_CONFIG_ENV || (config.appConfig && config.appConfig.environment)) as
      | EnvironmentType
      | undefined;
    initEnvironment(env);
  } catch (exception) {
    const env = process.env.REACT_APP_CONFIG_ENV as EnvironmentType | undefined;
    initEnvironment(env);
  }
};

export const getEnvironment = () => {
  return Environment[(window as any)[environmentKey] as EnvironmentType];
};

export const setAppVersion = (version: string) => {
  (window as any)[appVersionKey] = version;
};

export const getAppVersion = () => {
  return (window as any)[appVersionKey];
};
