import React, { FC, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Query } from 'material-table';
import { MTable, Panel } from '../../../../Components';
import { UserContext } from '../../../../Context/UserContext/UserContext';
import { IGridQueryResult, IGridQueryRequest, OrderDirection } from '../../../../Api/AptorApi';

export interface ICompliance {
  companyUnit: string;
  complianceUsers: string
}

interface IProps {
  dataFetcher: (request: IGridQueryRequest) => Promise<IGridQueryResult<ICompliance>>;
}

export const ComplianceUsers: FC<IProps> = ({ dataFetcher }) => {
  const { formatMessage } = useIntl();
  const { getCompanyUnitName } = useContext(UserContext);
  
  const handleDataFetch = useCallback(
    async (query: Query<ICompliance>) => {
      return await dataFetcher({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'companyUnit',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
      } as IGridQueryRequest);
    },
    [dataFetcher],
  );
  
  return (
    <Panel titleKey="law-portal.compliance.users.label">
      <MTable
        columns={[
          { title: getCompanyUnitName(formatMessage), field: 'companyUnit' },
          { title: formatMessage({ id: 'law-portal.compliance.users.label' }), field: 'complianceUsers', sorting: false },
        ]}
        data={handleDataFetch}
      />
    </Panel>
  );
};
