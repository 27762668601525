import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface IButtonLinkProps {
  className?: string;
  text: string;
  to: string;
  icon?: React.ReactElement;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
}

export const ButtonLink = (props: IButtonLinkProps) => {
  const { className, text, to, icon, variant, disabled } = props;
  return (
    <Button
      disabled={disabled}
      className={className}
      variant={variant || 'outlined'}
      component={Link}
      to={to}
      startIcon={icon}
    >
      {text}
    </Button>
  );
};
