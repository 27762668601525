import React, { FC } from 'react';
import { ListItem, ListItemText, ListItemSecondaryAction, IconButton, Typography, Tooltip } from '@material-ui/core';
import { ArrowUpward, ArrowDownward, Delete } from '@material-ui/icons';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';
import { useStyles } from './Checklist.Item.styles';
import { useIntl } from 'react-intl';

export interface IProps {
  label: string;
  disableUp?: boolean;
  disableDown?: boolean;
  moveUp: () => void;
  moveDown: () => void;
  remove: () => void;
  skipRemoveConfirmation?: boolean;
  header?: boolean;
}

export const ChecklistItem: FC<IProps> = ({
  label,
  disableUp,
  disableDown,
  moveUp,
  moveDown,
  remove,
  skipRemoveConfirmation,
  header,
}) => {
  const { deleteConfirmation } = useConfirmation();
  const asyncRemove = () =>
    new Promise<void>((resolve) => {
      remove();
      resolve();
    });
  const onRemove = () => (skipRemoveConfirmation ? remove() : deleteConfirmation(asyncRemove, { name: label }));
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const secondaryAction = (
    <ListItemSecondaryAction>
      <Tooltip title={formatMessage({ id: 'utils.icon.delete', defaultMessage: 'Delete' })}>
        <IconButton onClick={onRemove} aria-label={formatMessage({ id: 'utils.icon.delete', defaultMessage: 'Delete' })}>
          <Delete />
        </IconButton>
      </Tooltip>
      <Tooltip title={formatMessage({ id: 'admin.law-portal.law.checklist.list.up', defaultMessage: 'Move up' })}>
        <IconButton
          disabled={disableUp}
          onClick={moveUp}
          aria-label={formatMessage({ id: 'admin.law-portal.law.checklist.list.up', defaultMessage: 'Move up' })}
        >
          <ArrowUpward />
        </IconButton>
      </Tooltip>
      <Tooltip title={formatMessage({ id: 'admin.law-portal.law.checklist.list.down', defaultMessage: 'Move down' })}>
        <IconButton
          disabled={disableDown}
          onClick={moveDown}
          aria-label={formatMessage({ id: 'admin.law-portal.law.checklist.list.down', defaultMessage: 'Move down' })}
        >
          <ArrowDownward />
        </IconButton>
      </Tooltip>
    </ListItemSecondaryAction>
  );

  return (
    <>
      {header ? (
        <ListItem className={`${classes.root} ${classes.header}`}>
          <ListItemText disableTypography>
            <Typography component="h3" variant="h4" id={label}>
              {label}
            </Typography>
          </ListItemText>
          {secondaryAction}
        </ListItem>
      ) : (
        <ListItem className={classes.root} classes={{ container: classes.container }}>
          <ListItemText>{label}</ListItemText>
          {secondaryAction}
        </ListItem>
      )}
    </>
  );
};
