import { makeStyles, Theme } from '@material-ui/core/styles';
import { sidebarWidth } from '../Components/Sidebar/Sidebar.styles';

export const useStyles = makeStyles((theme: Theme) => ({
  content: {
    flexGrow: 1,
    width: `calc(100% - ${theme.spacing(8)})`,
  },
  contentShift: {
    width: `calc(100% - ${sidebarWidth}rem)`,
  },
}));
