import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { OptionsType } from 'react-select';
import { useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { Container, Panel, EditablePanel, Form, Loading, BackButton } from '../../../../../Components';
import { FormValues, SelectOption } from '../../../../../Components/Form/Form.types';
import { useFormField } from '../../../../../Components/Form/FormField';
import { RequirementCompanyUnits } from './CompanyUnits/RequirementCompanyUnits.List';
import { RequirementChangesView } from './Changes/Changes.View';
import { RequirementAddChange, IRequirementChange } from './Changes/Changes.Form';
import { LawHeader } from '../LawHeader';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { AptorApi, useAptorApi, useFetchSelectOptions } from '../../../../../Api';
import { INamedEntity } from '../../../../../Api/AptorApi';
import { entityToSelectOption } from '../../../../../Utilities';

interface IRequirement extends INamedEntity {
  name: string;
  area: INamedEntity;
  effectiveFrom: Date;
  effectiveTo: Date | null;
  effectiveToSendoutTime: Date | null;
  description: string;
  changes: IRequirementChange[];
}

interface IProps {
  initState?: IRequirement;
  areas: OptionsType<SelectOption>;
  submit: (form: FormValues, api: AptorApi, onSuccess: () => void) => Promise<void>;
}

const EditRequirementForm: FC<IProps> = ({ initState, areas, submit }) => {
  const { formatMessage } = useIntl();

  const nameField = useFormField({
    label: formatMessage({ id: 'form.name' }),
    name: 'name',
    type: 'text',
    required: true,
    initialState: initState ? { value: initState.name } : undefined,
  });

  const areaField = useFormField({
    label: formatMessage({ id: 'utils.filters.area' }),
    name: 'area',
    type: 'single-select',
    options: areas,
    required: true,
    initialState: initState ? entityToSelectOption(initState.area) : undefined,
  });

  const effectiveFrom = useFormField({
    label: formatMessage({ id: 'law-portal.manage.requirements.effectiveFrom' }),
    name: 'effectiveFrom',
    type: 'date',
    required: true,
    initialState: initState ? { value: initState.effectiveFrom } : undefined,
  });

  const effectiveTo = useFormField({
    label: formatMessage({ id: 'law-portal.manage.requirements.effectiveTo' }),
    name: 'effectiveTo',
    type: 'date',
    required: false,
    initialState: initState && initState.effectiveTo ? { value: initState.effectiveTo } : undefined,
  });

  const effectiveToSendoutTime = useFormField({
    label: formatMessage({ id: 'law-portal.manage.requirements.effective-to-sendout-time' }),
    name: 'effectiveToSendoutTime',
    type: 'datetime',
    required: false,
    fieldRequirements: [effectiveTo],
    initialState:
      initState && initState.effectiveToSendoutTime ? { value: initState.effectiveToSendoutTime } : undefined,
  });

  const descriptionField = useFormField({
    label: formatMessage({ id: 'law-portal.manage.requirements.description' }),
    name: 'description',
    type: 'rich-text',
    required: true,
    initialState: initState ? { value: initState.description } : undefined,
  });

  return (
    <Form
      submit={submit}
      groups={[
        {
          grid: {
            type: 'row',
            items: [
              {
                type: 'column',
                items: [
                  { type: 'row', items: [nameField] },
                  { type: 'row', items: [areaField] },
                  { type: 'row', items: [effectiveFrom] },
                  { type: 'row', items: [effectiveTo] },
                  { type: 'row', items: [effectiveToSendoutTime] },
                ],
              },
              {
                type: 'column',
                flex: 2,
                items: [descriptionField],
              },
            ],
          },
        },
      ]}
    />
  );
};

export const EditRequirement = () => {
  const match = useRouteMatch<{ reqid: string }>();
  const id = parseInt(match.params.reqid);
  const { formatMessage } = useIntl();
  const { getCompanyUnitName } = useContext(UserContext);
  const { api, abortController } = useAptorApi();
  const [refetch, setRefetch] = useState(false);
  const [revision, setRevision] = useState<number>(0);
  const [initState, setInitState] = useState<IRequirement>();
  const [areas, , initA] = useFetchSelectOptions(
    useCallback(() => api.getCustomerLawSettings('areas'), [api]),
    abortController,
  );

  const updateRequirementState = (update: Partial<IRequirement>) => {
    if (initState) {
      setInitState({ ...initState, ...update });
      setTimeout(() => setRevision(revision + 1), 250);
    }
  };

  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: () => void) => {
    await api.editCustomerRequirement(id, data);
    onSuccess();
    if (api.abortController?.signal.aborted) {
      return;
    }
    setRefetch(true);
  };

  //Initialization
  useEffect(() => {
    const initializeFields = async () => {
      const info = await api.getRequirement<IRequirement>(id);
      if (abortController.current.signal.aborted) {
        return;
      }
      setInitState(info);
      setRefetch(false);
    };
    if (refetch || initState === undefined) {
      initializeFields();
    }
  }, [refetch, id, api, abortController, initState]);

  return (
    <Container>
      <Grid container spacing={4} direction="column">
        <Grid item>
          <BackButton link="/organization/law-portal/other-requirements/" />
        </Grid>
        {initState && (
          <>
            <Grid item>
              <LawHeader name={initState?.name || ''} />
            </Grid>
            <Grid item>
              <Panel titleKey="law-portal.manage.requirements.editRequirement" accordion>
                {((initState === undefined || !initA) && <Loading />) || (
                  <EditRequirementForm areas={areas} initState={initState} submit={handleSubmit} />
                )}
              </Panel>
            </Grid>
            <Grid item>
              <EditablePanel
                iconType="add"
                titleKey="law-portal.manage.requirements.latest-change"
                dependencies={[revision]}
                view={
                  <RequirementChangesView
                    id={id}
                    changes={initState?.changes || []}
                    onChange={updateRequirementState}
                  />
                }
                form={<RequirementAddChange id={id} changes={initState?.changes} onChange={updateRequirementState} />}
              />
            </Grid>
            <Grid item>
              <Panel
                titleKey="law-portal.manage.requirements.connectedCompanyUnits"
                titleValues={{ alias: getCompanyUnitName(formatMessage, true) }}
                accordion
              >
                <RequirementCompanyUnits reqId={id} />
              </Panel>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};
