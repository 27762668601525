import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Query } from 'material-table';
import { Grid, TablePagination, Typography } from '@material-ui/core';
import { MTable } from '../../../../../Components';
import { Form } from '../../../../../Components/Form';
import { FormValues } from '../../../../../Components/Form/Form.types';
import { useFormField } from '../../../../../Components/Form/FormField';
import { AccessLevel, OrganizationFeature, UserContext } from '../../../../../Context/UserContext/UserContext';
import { AptorApi, useAptorApi } from '../../../../../Api';
import { INamedEntity, IGridQueryRequest, OrderDirection, PropertyError } from '../../../../../Api/AptorApi';
import { useApiValidationSnackbar } from '../../../../../Api/useValidationSnackbar';
import { useStyles } from '../Organization.styles';

interface ICompanyUnit extends INamedEntity {
  processes: string;
  aspects: string;
  active: boolean;
}

const AliasForm = () => {
  const { api, abortController } = useAptorApi();
  const { fetchUser, getCompanyUnitName } = useContext(UserContext);
  const { formatMessage } = useIntl();
  const initFlag = useRef(false);
  const classes = useStyles();

  const aliasField = useFormField({
    label: formatMessage({ id: 'organization.company-units.alias.label' }),
    name: 'alias',
    type: 'text',
    required: false,
  });
  const setAlias = aliasField.setValue;
  useEffect(() => {
    const initializeFields = async () => {
      initFlag.current = true;
      const name = await getCompanyUnitName(formatMessage);
      setAlias({
        value: name,
      });
    };
    if (!initFlag.current) initializeFields();
  }, [api, abortController, setAlias, getCompanyUnitName, formatMessage]);

  const handleCompanyUnitAliasSubmit = async (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => {
    await api.updateCompanyUnitAlias(data);
    if (abortController.current.signal.aborted) {
      return;
    }
    fetchUser();
    onSuccess('organization.company-units.successfullyUpdatedAlias');
  };

  return <Form className={classes.form} submit={handleCompanyUnitAliasSubmit} singleField={aliasField} />;
};

const NewCompanyUnitForm = (props: { alias: string; onComplete: () => void; disabled?: boolean }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const companyUnitNameField = useFormField({
    label: formatMessage({ id: 'organization.company-units.addCompanyUnit' }, { alias: props.alias }),
    name: 'name',
    type: 'text',
    required: false,
    customValidation: () => props.disabled !== true,
  });

  const handleAddCompanyUnit = async (data: FormValues, api: AptorApi, onSuccess: () => void) => {
    await api.createCompanyUnit(data);
    onSuccess();
    props.onComplete();
    companyUnitNameField.clear();
  };

  return (
    <Form
      className={classes.form}
      submit={handleAddCompanyUnit}
      singleField={companyUnitNameField}
      submitLabel={formatMessage({ id: 'form.add' })}
    />
  );
};

interface ICompanyUnitBalance {
  totalAmount: number;
  currentAmount: number;
}

export const CompanyUnits = () => {
  const { api, abortController } = useAptorApi();
  const history = useHistory();
  const tableRef = React.createRef();
  const { formatMessage } = useIntl();
  const { getCompanyUnitName, hasAccessToFeature } = useContext(UserContext);
  const { notifyUnhandledException, notifyValidationErrors } = useApiValidationSnackbar();
  const alias = getCompanyUnitName(formatMessage, true);
  const canManage = hasAccessToFeature(OrganizationFeature.Manage, AccessLevel.Manage);
  const classes = useStyles();

  const handleDataFetch = useCallback(
    async (query: Query<ICompanyUnit>) => {
      return await api.searchCompanyUnits<ICompanyUnit>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
      } as IGridQueryRequest);
    },
    [api],
  );

  const edit = (unit: ICompanyUnit) => ({
    tooltip: formatMessage({ id: 'component.mtable.editTooltip' }),
    icon: 'edit',
    onClick: () => history.push(`/organization/company-units/${unit.id}`),
  });

  const activate = (unit: ICompanyUnit) => ({
    tooltip: formatMessage({ id: 'component.mtable.activateTooltip' }),
    icon: 'check',
    onClick: async () => {
      const action = (i: AptorApi) => i.toggleCompanyUnitActivation(unit.id, true);
      const onValidation = (errors: PropertyError[]) => notifyValidationErrors(errors);
      await api.invoke(action, abortController.current, onValidation, notifyUnhandledException);
      reloadTable();
    },
  });

  const inactivate = (unit: ICompanyUnit) => ({
    tooltip: formatMessage({ id: 'component.mtable.inactivateTooltip' }),
    icon: 'block',
    onClick: async () => {
      await api.toggleCompanyUnitActivation(unit.id, false);
      reloadTable();
    },
  });

  const activation = (unit: ICompanyUnit) => {
    if (unit.active) return inactivate(unit);
    else return activate(unit);
  };

  const reloadTable = () => {
    fetchCompanyUnitBalance();
    (tableRef.current as any).onQueryChange();
  };

  const fetchCompanyUnitBalance = useCallback(async () => {
    var balance = await api.getCompanyUnitBalance<ICompanyUnitBalance>();
    if (abortController.current.signal.aborted) {
      return;
    }
    setBalance(balance);
  }, [api, abortController]);

  useEffect(() => {
    fetchCompanyUnitBalance();
  }, [fetchCompanyUnitBalance]);

  const [balance, setBalance] = useState<{ totalAmount: number; currentAmount: number }>();

  return (
    <>
      {canManage && <AliasForm />}
      {canManage && (
        <NewCompanyUnitForm
          alias={alias}
          onComplete={reloadTable}
          disabled={balance && balance.currentAmount >= balance.totalAmount}
        />
      )}
      {balance && balance.currentAmount >= balance.totalAmount && (
        <Grid item md={6} xs={12} className={classes.companyUnitsExceeded}>
          <Typography variant="caption" color="error" component="p">
            {formatMessage({ id: 'organization.company-units.notAvailable' }, { alias: alias })}
          </Typography>
          <Typography variant="caption" color="error" component="p">
            {formatMessage({ id: 'organization.company-units.contactSupport' }, { alias: alias })}
          </Typography>
        </Grid>
      )}
      <MTable
        hideToolbar
        tableRef={tableRef}
        columns={[
          { title: formatMessage({ id: 'organization.company-units.table.name' }), field: 'name' },
          {
            title: formatMessage({ id: 'organization.company-units.table.processes' }),
            field: 'processes',
            sorting: false,
          },
          {
            title: formatMessage({ id: 'organization.company-units.table.aspects' }),
            field: 'aspects',
            sorting: false,
          },
        ]}
        data={handleDataFetch}
        actions={canManage ? [edit, activation] : undefined}
        components={{
          Pagination: (props: any) => (
            <Grid component="td" container className={classes.companyUnitFooterContainer}>
              <Grid item xs={6} className={classes.companyUnitFooterLabel}>
                {balance && (
                  <Typography variant="overline">
                    {formatMessage({ id: 'organization.company-units.createdCompanyUnits' }, { alias: alias })}:{' '}
                    {balance.currentAmount}/{balance.totalAmount}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} className={classes.companyUnitFooterPagination}>
                <TablePagination component="div" {...props} />
              </Grid>
            </Grid>
          ),
        }}
      />
    </>
  );
};
