import React, { useCallback, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Checkbox } from '@material-ui/core';
import { Query } from 'material-table';
import MTable from '../../../../../Components/MTable/MTable';
import { useFilter } from '../../../../../Components/ListFilter';
import { HtmlView } from '../../../../../Components/Html/Html.View';
import { RepealableName } from '../../../../../Components';
import { OwnNotes } from '../../../../../Components/OwnNotes';
import { LawListFilter } from './Filter';
import { ComplianceActions } from './ComplianceAction';
import { useComplianceState } from './ComplianceState';
import { ModuleContext } from '../../../../../Context/ModuleContext/ModuleContext';
import { useAptorApi } from '../../../../../Api';
import { IGridQueryRequest, INamedEntity, OrderDirection } from '../../../../../Api/AptorApi';
import { useIsMounted } from '../../../../../Hooks/useIsMounted';
import { useStyles } from '../../../LawPortal.styles';
import moment from 'moment';

type LawData = {
  id: number;
  number: string;
  name: string;
  description: string;
  area: string;
  changeNumber?: string;
  changeEffectiveFrom?: Date;
  effectiveTo?: Date;
  companyUnits: Array<INamedEntity & { note: string | null }>;
};

export const LawList = () => {
  const { formatMessage } = useIntl();
  const {
    enableCompliance,
    complianceEnabled,
    disableCompliance,
    startComplianceSession,
    hasCompliance,
    isComplianceUserFor,
    toggleCompliance,
  } = useComplianceState();
  const { api } = useAptorApi();
  const [filters, setFilter] = useFilter();
  const tableRef = React.createRef();
  const history = useHistory();
  const isMounted = useIsMounted();
  const { setHeader } = useContext(ModuleContext);
  const classes = useStyles();

  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.our-laws.header', defaultMessage: 'Our laws' }));
  }, [setHeader, formatMessage]);

  useEffect(() => {
    if (isMounted) {
      tableRef.current && (tableRef.current as any).onQueryChange();
    }
  }, [filters, tableRef, isMounted]);

  const handleDataFetch = useCallback(
    async (query: Query<LawData>) => {
      return await api.searchLawPortalLaws<LawData>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'number',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  const renderDate = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
  };

  return (
    <div className={classes.listWrapper}>
      <LawListFilter filters={filters} setFilter={setFilter}>
        <ComplianceActions
          complianceEnabled={complianceEnabled}
          enableCompliance={enableCompliance}
          disableCompliance={disableCompliance}
          hasCompliance={hasCompliance}
          startComplianceSession={startComplianceSession}
        />
      </LawListFilter>
      <MTable
        tableRef={tableRef}
        columns={[
          { title: formatMessage({ id: 'law-portal.our-laws.table.number' }), field: 'number' },
          {
            title: formatMessage({ id: 'law-portal.our-laws.table.law' }),
            field: 'name',
            render: (row) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
          },
          {
            title: formatMessage({ id: 'law-portal.our-laws.table.description' }),
            field: 'description',
            render: (row) => <HtmlView variant="body2" value={row.description} />,
            sorting: false,
          },
          { title: formatMessage({ id: 'law-portal.our-laws.table.area' }), field: 'area' },
          { title: formatMessage({ id: 'law-portal.our-laws.table.changeNumber' }), field: 'changeNumber' },
          {
            title: formatMessage({ id: 'law-portal.our-laws.table.changeEffectiveFrom' }),
            field: 'changeEffectiveFrom',
            render: (row) => renderDate(row.changeEffectiveFrom),
          },
          {
            title: formatMessage({ id: 'law-portal.our-laws.table.notes' }),
            field: 'notes',
            render: (row) => <OwnNotes {...row} />,
            sorting: false,
          },
        ]}
        data={handleDataFetch}
        onRowClick={(_, row) => row && history.push(`/law-portal/our-laws/${row.id}`)}
        actionLabel={enableCompliance ? formatMessage({ id: 'law-portal.our-laws.table.compliance' }) : undefined}
        actions={
          complianceEnabled
            ? [
                (row: LawData) => ({
                  icon: () => <Checkbox checked={hasCompliance((law) => law.id === row.id)} />,
                  onClick: () => toggleCompliance(row),
                  hidden: !isComplianceUserFor(row.id),
                }),
              ]
            : []
        }
      />
    </div>
  );
};
