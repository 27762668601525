import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

export interface IListItemLinkProps {
  to: string;
  text: React.ReactNode;
  icon?: ReactElement;
  selected?: boolean;
  onClick?: () => void;
}

export const ListItemLink = (props: IListItemLinkProps) => {
  const { to, text, icon, selected, onClick } = props;

  return (
    <ListItem button component={Link} to={to} selected={selected} onClick={onClick}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText>{text}</ListItemText>
    </ListItem>
  );
};
