import { Button, Grid } from '@material-ui/core';
import React, { FC, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ICompanyUnitLaw } from '.';
import { useAptorApi } from '../../../../../../Api';
import { SelectField } from '../../../../../../Components/Field';
import { SelectOption } from '../../../../../../Components/Form/Form.types';
import { useConfirmation } from '../../../../../../Context/ConfirmationContext/ConfirmationContext';
import { UserContext } from '../../../../../../Context/UserContext/UserContext';
import { entityToSelectOption } from '../../../../../../Utilities';

interface IProps {
  lawId: number;
  companyUnits: ICompanyUnitLaw[];
  onCancel: () => void;
  onChange: (state: { companyUnits: ICompanyUnitLaw[] }) => void;
}

export const ApplicationHiddenForm: FC<IProps> = ({ lawId, companyUnits, onChange, onCancel }) => {
  const { formatMessage } = useIntl();
  const { getCompanyUnitName } = useContext(UserContext);
  const { api, abortController } = useAptorApi();
  const { deleteConfirmation } = useConfirmation();
  const [hiddenUnits, setHiddenUnits] = useState<SelectOption[]>([]);
  const confirmation = {
    title: formatMessage({ id: 'law-portal.our-laws.overview.application.label' }),
    description: formatMessage({ id: 'law-portal.our-laws.overview.application.toggle-visibility.confirmation' }),
  };

  const changeVisibility = () =>
    deleteConfirmation(async () => {
      const update = companyUnits.map((unit) => {
        return { ...unit, ...{ applicationHidden: hiddenUnits?.some((x) => +x.value === unit.id) ?? false } };
      });
      api.updateLawPortalLawApplicationHidden(lawId, update).then(() => {
        if (abortController.current.signal.aborted) {
          return;
        }
        onChange({ companyUnits: update });
      });
    }, confirmation);

  return (
    <Grid container justify="space-between" alignItems="flex-end">
      <Grid item xs={8}>
        <SelectField
          isMulti
          label={formatMessage(
            { id: 'law-portal.our-laws.overview.application.toggle-visibility.label' },
            { unit: getCompanyUnitName(formatMessage) },
          )}
          options={companyUnits.map(entityToSelectOption)}
          initialState={companyUnits.filter((x) => x.applicationHidden).map(entityToSelectOption)}
          errorTexts={[]}
          onChange={setHiddenUnits}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <Button variant="outlined" onClick={onCancel}>
              <FormattedMessage id="form.cancel" />
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={changeVisibility}>
              <FormattedMessage id="form.save" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
