import { IAppConfiguration } from './IAppConfiguration';

export default {
  logrocketKey: undefined,
  aptorApi: 'https://aptoronline.b2clogin.com/aptoronline.onmicrosoft.com/B2C_1A_signup_signin',
  clientId: 'c09591bc-b2bf-4cad-862f-6cd5152f045e',
  tenantName: 'aptoronline',
  tinyMCEApiKey: '1njdfilpf3cwf9ws8gnrvdcmzt6pufa2fd1bj3165lcs8spl',
  redirectUrl: 'http://localhost:3000/',
  policyName: 'B2C_1_signup_signin',
  inivtePolicy: 'B2C_1A_signup_invitation',
  forgotPasswordPolicy: 'B2C_1_forgot_password',
  redirectLogoutUrl: 'http://localhost:3000/',
  tenantId: '4cf894e2-2dde-41ca-865c-b4f8e0e4717a',
  enableImpersonation: true,
  knownAuthority: 'https://aptoronline.b2clogin.com',
  scopes: ['https://aptoronline.onmicrosoft.com/c09591bc-b2bf-4cad-862f-6cd5152f045e/user_impersonation'],
} as IAppConfiguration;
