import React, { FC, useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Send } from '@material-ui/icons';
import { IconButton, TextField, InputAdornment, Tooltip, Card, CardContent, Typography, Grid } from '@material-ui/core';
import { useStyles } from './Comments.styles';
import { ICompanyUnit } from '.';
import { SelectField } from '../../../../Components/Field';
import { entityToSelectOption } from '../../../../Utilities';
import { SelectOption } from '../../../../Components/Form/Form.types';
import { IInternalComment, INamedEntity } from '../../../../Api/AptorApi';
import { UserContext } from '../../../../Context/UserContext/UserContext';

interface IProps {
  companyUnits: ICompanyUnit[];
  editComment?: IInternalComment;
  submit: (companyUnitId: number, message: string, taggedUsers: INamedEntity[]) => Promise<void>;
}

export const InternalCommentForm: FC<IProps> = ({ submit, editComment, companyUnits }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { getCompanyUnitName } = useContext(UserContext);
  const [message, setMessage] = useState<string>(editComment?.comment ?? '');
  const [companyUnit, setCompanyUnit] = useState<SelectOption | undefined>();
  const [taggedUsers, setTaggedUsers] = useState<SelectOption[] | undefined>();
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (companyUnit) {
      const unit = +companyUnit.value;
      const users = taggedUsers?.map((x) => ({ id: +x.value, name: x.label })) ?? [];
      await submit(unit, message, users);
    }
  };

  const enableSend = useMemo(() => {
    return message.length > 0 && companyUnit !== undefined;
  }, [message, companyUnit]);

  const taggableUsers = useMemo(() => {
    if (companyUnit) {
      var selectedUnit = companyUnits.find((x) => x.id === +companyUnit.value);
      if (selectedUnit) {
        setTaggedUsers([]);
        return selectedUnit.taggableUsers;
      }
    }

    return [];
  }, [companyUnit, companyUnits]);

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {formatMessage({ id: 'law-portal.internal-comments.form.title' })}
        </Typography>
        <form onSubmit={onSubmit}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <SelectField
                placeholder={`${getCompanyUnitName(formatMessage)} *`}
                options={companyUnits.map(entityToSelectOption)}
                onChange={setCompanyUnit}
                errorTexts={[]}
                initialState={editComment ? entityToSelectOption(editComment.companyUnit) : undefined}
              />
            </Grid>
            <Grid item>
              <SelectField
                isMulti
                clearable
                placeholder={formatMessage({ id: 'law-portal.internal-comments.form.tag-user.placeholder' })}
                options={taggableUsers.map(entityToSelectOption)}
                onChange={setTaggedUsers}
                errorTexts={[]}
                initialState={editComment?.taggedUsers.map(entityToSelectOption) ?? []}
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.textField}
                name="message"
                value={message}
                placeholder={formatMessage({ id: 'law-portal.internal-comments.form.message.placeholder' })}
                multiline
                rows={5}
                onChange={(e) => setMessage(e.target.value)}
                InputProps={{
                  className: classes.input,
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment className={classes.adornment} position="end">
                      <Tooltip title={formatMessage({ id: 'form.send', defaultMessage: 'Send' })}>
                        <span>
                          <IconButton
                            aria-label={formatMessage({ id: 'form.send', defaultMessage: 'Send' })}
                            color="primary"
                            disabled={enableSend === false}
                            type="submit"
                          >
                            <Send />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};
