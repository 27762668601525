import React from 'react';
import { Panel } from '../../../../Components/Panel/Panel';
import { AptorApi } from '../../../../Api/AptorApi';
import { CreateLawForm } from './Create.Form';
import Loading from '../../../../Components/Loading';
import { useOptions } from './Options';
import { FormValues } from '../../../../Components/Form/Form.types';
import { useHistory } from 'react-router-dom';
import { Container, BackButton } from '../../../../Components';

export const CreateLaw = () => {
  const [options, loading] = useOptions();
  const history = useHistory();

  const handleSubmit = async (form: FormValues, api: AptorApi, onSuccess: () => void) => {
    const { id } = await api.createLaw(form);
    onSuccess();
    history.push(`/admin/law-portal/laws/${id}`);
  };

  return (
    (loading && <Loading />) || (
      <Container>
        <BackButton link="/admin/law-portal/laws" />
        <Panel titleKey="admin.law-portal.law.form.create.header" accordion={false}>
          <CreateLawForm submit={handleSubmit} {...options!} />
        </Panel>
      </Container>
    )
  );
};
