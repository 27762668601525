import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AptorApi, useAptorApi } from '../../../../../../Api';
import { EditChecklist, IChecklistItem } from '../../../../../AptorAdmin/Components/Checklist';

interface IRequirementChecklist {
  requirement: string;
  items: IChecklistItem[];
}

export const EditRequirementChecklist = () => {
  const match = useRouteMatch<{ reqid: string, unitid: string }>();
  const reqid = parseInt(match.params.reqid);
  const unitid = parseInt(match.params.unitid);
  const { api, abortController } = useAptorApi();
  const fetcher = useCallback(
    () =>
      api.getRequirementChecklist<IRequirementChecklist>(reqid, unitid).then((x) => {
        if (abortController.current.signal.aborted) {
          return undefined;
        }
        return { name: x.requirement, items: x.items };
      }),
    [api, abortController, reqid, unitid],
  );

  const editor = (api: AptorApi, checklist: { items: IChecklistItem[] }) =>
    api.editRequirementChecklist(reqid, unitid, checklist);

  return (
    <EditChecklist
      fetcher={fetcher}
      editor={editor}
      backTo={`/organization/law-portal/other-requirements/${reqid}/company-units/${unitid}`}
    />);
};
