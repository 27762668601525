import React, { useCallback, useContext, useEffect } from 'react';
import MTable from '../../../../../Components/MTable/MTable';
import { useIntl } from 'react-intl';
import { useAptorApi } from '../../../../../Api';
import { Query } from 'material-table';
import { IGridQueryRequest, OrderDirection } from '../../../../../Api/AptorApi';
import { ChangeFilter } from '../Filters';
import { Link } from 'react-router-dom';
import { useIsMounted } from '../../../../../Hooks/useIsMounted';
import moment from 'moment';
import { useFilter } from '../../../../../Components/ListFilter';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../Context/UserContext/UserContext';
import { Check } from '@material-ui/icons';

type CompliancecReport = {
  id: number;
  lawId: number;
  lawName: string;
  effectiveFrom: Date;
  customerCompanyUnitId: number;
  customerCompanyUnit: string;
  userId: number;
  userName: string;
  controlDate: Date;
  unplanned: boolean;
};

export const CompliancesDone = () => {
  const { formatMessage } = useIntl();
  const { api } = useAptorApi();
  const { complianceUserFor, hasAccessToFeature, getCompanyUnitName } = useContext(UserContext);
  const [filters, setFilter] = useFilter();
  const tableRef = React.createRef();
  const isMounted = useIsMounted();
  const canDownloadAllReports = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);

  useEffect(() => {
    if (isMounted) {
      tableRef.current && (tableRef.current as any).onQueryChange();
    }
  }, [filters, tableRef, isMounted]);

  const handleDataFetch = useCallback(
    async (query: Query<CompliancecReport>) => {
      return await api.searchStatisticsCompliances<CompliancecReport>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'lawName',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  const renderDate = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
  };

  const canDownloadReport = (row: CompliancecReport) => {
    return (
      canDownloadAllReports ||
      complianceUserFor.laws.some((x) => x.customerCompanyUnitId === row.customerCompanyUnitId && x.id === row.lawId)
    );
  };

  const renderDownloadAction = (row: CompliancecReport) => {
    return {
      tooltip: formatMessage({ id: 'law-portal.statistics.table.download-compliance' }),
      icon: 'picture_as_pdf',
      onClick: () => {
        api.downloadLawComplianceReport(row.lawId, row.id);
      },
      disabled: !canDownloadReport(row),
    };
  };

  return (
    <>
      <ChangeFilter filters={filters} setFilter={setFilter} />
      <MTable
        tableRef={tableRef}
        columns={[
          {
            title: formatMessage({ id: 'law-portal.statistics.table.law' }),
            field: 'lawName',
            render: (row) => <Link to={`/law-portal/our-laws/${row.lawId}`}>{row.lawName}</Link>,
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.effectiveFrom' }),
            field: 'effectiveFrom',
            render: (row) => renderDate(row.effectiveFrom),
          },
          {
            title: getCompanyUnitName(formatMessage),
            field: 'customerCompanyUnit',
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.user' }),
            field: 'userName',
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.control-date' }),
            field: 'controlDate',
            render: (row) => renderDate(row.controlDate),
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.unplanned' }),
            field: 'unplanned',
            render: (row) => (row.unplanned ? <Check /> : ''),
          },
        ]}
        data={handleDataFetch}
        actionLabel={''}
        actions={[renderDownloadAction]}
      />
    </>
  );
};
