import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import clsx from 'clsx';
import { FormControl, Typography } from '@material-ui/core';
import AsyncSelect from '../Select/SelectAsync';
import { FieldHelperOrErrors } from './FieldHelperOrErrors';
import { useStyles } from './Field.styles';
import { FormFieldValueType, SelectOption, TypedFormFieldValue, TypedSelectOption } from '../Form/Form.types';
import { AsyncSelectFieldProps, FormFieldRef } from './Field.props';
import { shallowCompare } from './shallowCompare';
import { ValueType } from 'react-select';
import { useIntl } from 'react-intl';

const getInitialState = <T extends FormFieldValueType>(
  state: TypedFormFieldValue<T> | TypedFormFieldValue<T>[] | undefined,
) => {
  if (state && Array.isArray(state)) {
    return state.map((x) => ({ value: x.value, label: x.label || '' } as TypedSelectOption<T>));
  } else if (state) {
    return { value: state.value, label: state.label || '' } as TypedSelectOption<T>;
  } else {
    return null;
  }
};

export const AsyncSelectField = memo(
  forwardRef(
    <T extends FormFieldValueType>(
      props: AsyncSelectFieldProps<T>,
      ref: ((instance: FormFieldRef<T> | null) => void) | React.MutableRefObject<FormFieldRef<T> | null> | null,
    ) => {
      const classes = useStyles();
      const { formatMessage } = useIntl();
      const { label, helperText, errorTexts, onChange, required, initialState, hideRequiredAsterisk, ...rest } = props;
      const [isFocused, setIsFocused] = useState(false);
      const [field, setField] = useState<TypedSelectOption<T> | TypedSelectOption<T>[] | null>(
        getInitialState(initialState) ?? props.isMulti ? [] : null,
      );

      useImperativeHandle(
        ref,
        () => ({
          setValue: (field: TypedFormFieldValue<T> | TypedFormFieldValue<T>[] | undefined) => {
            const fieldToOption = (field: TypedFormFieldValue<T>) => {
              return { label: field.label ?? '', value: field.value } as TypedSelectOption<T>;
            };

            setField(Array.isArray(field) ? field.map(fieldToOption) : (field && fieldToOption(field)) || null);
          },
        }),
        [],
      );

      const handleChange = (option: ValueType<SelectOption>) => {
        setField(option as TypedSelectOption<T> | TypedSelectOption<T>[] | null);
      };

      useEffect(() => {
        if (Array.isArray(field)) {
          onChange(field.map((x) => ({ value: x.value, label: x.label, isValid: true } as TypedFormFieldValue<T>)));
        } else if (field !== null) {
          onChange({ value: field.value, label: field.label, isValid: true } as TypedFormFieldValue<T>);
        } else {
          onChange(undefined);
        }
      }, [field, onChange, required]);

      return (
        <FormControl error={props.error} className={classes.formControl}>
          <Typography
            htmlFor={props.name}
            className={clsx(classes.label, { [classes['sr-only']]: props.card })}
            component="label"
          >
            {props.label}
            {props.required && !hideRequiredAsterisk && <span>*</span>}
          </Typography>
          <AsyncSelect
            {...rest}
            className={clsx({ [classes.selectError]: rest.error && !isFocused })}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={rest.placeholder || formatMessage({ id: 'utils.select.select' })}
          />

          <FieldHelperOrErrors helperText={helperText} errorTexts={errorTexts} />
        </FormControl>
      );
    },
  ),
  (newProps, oldProps) => {
    return shallowCompare(newProps, oldProps, ['errorTexts', 'loadOptions']);
  },
);

export default AsyncSelectField;
