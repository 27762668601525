import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '3vh',
    marginBottom: '10rem', //To account for ImpersonationBar
    [theme.breakpoints.up('lg')]: {
      width: `${theme.breakpoints.values.md}rem`,
      marginLeft: `calc((100% - ${theme.breakpoints.values.md}rem) / 2)`,
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 4rem)',
      marginLeft: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 2rem)',
      marginLeft: '1rem',
    },
  },
  label: {
    marginBottom: '1rem',
  },
  linkButtonContainer: {
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  secondaryLinkButtonContainer: {
    marginRight: '1em',
  },
  fullWidth: {
    width: '100%',
    marginLeft: 0,
    marginTop: 0,
    padding: theme.spacing(4),
  },
}));
