import React, { useCallback, useEffect } from 'react';
import MTable from '../../../../../Components/MTable/MTable';
import { useIntl } from 'react-intl';
import { useAptorApi } from '../../../../../Api';
import { Query } from 'material-table';
import { IGridQueryRequest, OrderDirection } from '../../../../../Api/AptorApi';
import { ChangeFilter } from '../Filters';
import { Link } from 'react-router-dom';
import { useIsMounted } from '../../../../../Hooks/useIsMounted';
import moment from 'moment';
import { useFilter } from '../../../../../Components/ListFilter';

type UnacknowledgedChange = {
  id: number;
  number: string;
  effectiveFrom: Date;
  lawReqId: number;
  name: string;
  userId: number;
  userName: string;
  type: 'Law' | 'Requirement';
};

export const ChangesToAcknowledge = () => {
  const { formatMessage } = useIntl();
  const { api } = useAptorApi();
  const [filters, setFilter] = useFilter();
  const tableRef = React.createRef();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted) {
      tableRef.current && (tableRef.current as any).onQueryChange();
    }
  }, [filters, tableRef, isMounted]);

  const handleDataFetch = useCallback(
    async (query: Query<UnacknowledgedChange>) => {
      return await api.searchStatisticsUnacknowledgedChanges<UnacknowledgedChange>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'number',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  const renderDate = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
  };

  return (
    <>
      <ChangeFilter filters={filters} setFilter={setFilter} />
      <MTable
        tableRef={tableRef}
        columns={[
          {
            title: formatMessage({ id: 'law-portal.statistics.table.law-and-requirement' }),
            field: 'name',
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.number' }),
            field: 'number',
            render: (row) => (
              <Link
                to={`/law-portal/${row.type === 'Law' ? 'our-laws' : 'other-requirements'}/${row.lawReqId}/changes`}
              >
                {row.number}
              </Link>
            ),
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.effectiveFrom' }),
            field: 'effectiveFrom',
            render: (row) => renderDate(row.effectiveFrom),
          },
          {
            title: formatMessage({ id: 'law-portal.statistics.table.user' }),
            field: 'userName',
          },
        ]}
        data={handleDataFetch}
      />
    </>
  );
};
