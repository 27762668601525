import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Form } from '..';
import { AptorApi } from '../../Api';
import { FormValues } from '../Form/Form.types';
import { useFormField } from '../Form/FormField';

interface IProps {
  value: string;
  propertyNameTranslationKey: string;
  submit: (
    value: string,
    api: AptorApi,
    onSuccess: (successMessage?: string | undefined | null) => void,
  ) => Promise<void>;
}

export const HtmlForm: FC<IProps> = ({ value, submit, propertyNameTranslationKey }) => {
  const { formatMessage } = useIntl();
  const onSubmit = (form: FormValues, api: AptorApi, onSuccess: (successMessage?: string | undefined | null) => void) =>
    submit(form['value'] as string, api, onSuccess);
  const field = useFormField({
    label: formatMessage({ id: 'form.update.field' }, { field: formatMessage({ id: propertyNameTranslationKey }) }),
    name: 'value',
    type: 'rich-text',
    required: true,
    initialState: { value },
  });

  return <Form submit={onSubmit} groups={[{ grid: { type: 'row', items: [field] } }]} />;
};
