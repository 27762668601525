import { Options } from 'material-table';
import theme from '../../Theme/theme';

export const options: Options = {
  actionsColumnIndex: -1,
  columnsButton: true,
  debounceInterval: 500,
  draggable: false,
  emptyRowsWhenPaging: false,
  headerStyle: {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
  },
  loadingType: 'linear',
  pageSize: 10,
  pageSizeOptions: [5,10,20,50,100],
  showTitle: false,
  sorting: true,
};
