import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingRight: theme.spacing(48),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(2),
    },
  },
  container: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
  hidden: {
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.grey[100],
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      position: 'unset',
      transform: 'none',
      justifyContent: 'center',
      paddingBottom: theme.spacing(2),
    },
  },
  textField: {
    width: '100%',
  },
}));
