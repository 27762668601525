import React, { FC, memo, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { ListItem, ListItemSecondaryAction, RadioGroup, IconButton, Tooltip } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { TextField } from '../../../../../Components/Field';
import { ComplianceReportCheckbox } from '../Checkbox';
import { TypedFormFieldValue } from '../../../../../Components/Form/Form.types';
import { compliance } from '../CreateReport';
import { useStyles } from './Question.styles';
import { FormFieldRef } from '../../../../../Components/Field/Field.props';

interface IQuestionProps {
  id: number;
  name: string;
  hidden: boolean;
  disableHide?: boolean;
  comment?: string;
  compliance?: compliance;
  onChange: (questionId: number, groupId: number | undefined, state: IQuestionBaseState & { hidden: boolean }) => void;
}

export const ComplianceReportQuestion: FC<IQuestionProps> = memo(
  ({ id, name, hidden, disableHide, compliance, comment, onChange }) => {
    const { formatMessage } = useIntl();

    const buttonTranslationKey = hidden
      ? 'law-portal.compliance.question.show-button'
      : 'law-portal.compliance.question.hide-button';

    const buttonIcon = hidden ? <VisibilityOff /> : <Visibility />;

    const onToggleHidden = () => {
      const newHiddenValue = !hidden;
      onChange(id, undefined, {
        compliance: newHiddenValue ? undefined : compliance,
        comment: newHiddenValue ? '' : comment,
        hidden: newHiddenValue,
      });
    };

    const handleOnChange = (state: IQuestionBaseState) => {
      onChange(id, undefined, { ...state, ...{ hidden } });
    };

    return (
      <ComplianceReportQuestionBase
        name={name}
        hidden={hidden}
        compliance={compliance}
        comment={comment}
        onChange={handleOnChange}
      >
        {!disableHide && (
          <Tooltip title={formatMessage({ id: buttonTranslationKey })}>
            <IconButton onClick={onToggleHidden} aria-label={formatMessage({ id: buttonTranslationKey })}>
              {buttonIcon}
            </IconButton>
          </Tooltip>
        )}
      </ComplianceReportQuestionBase>
    );
  },
);

interface ICustomerQuestionProps {
  index: number;
  name: string;
  comment?: string;
  compliance?: compliance;
  onChange: (index: number, state: IQuestionBaseState) => void;
}

export const ComplianceReportCustomerQuestion: FC<ICustomerQuestionProps> = memo(
  ({ index, name, compliance, comment, onChange }) => {
    const handleOnChange = (state: IQuestionBaseState) => onChange(index, state);
    return (
      <ComplianceReportQuestionBase name={name} compliance={compliance} comment={comment} onChange={handleOnChange} />
    );
  },
);

interface ICustomerQuestionBaseProps {
  name: string;
  hidden?: boolean;
  comment?: string;
  compliance?: compliance;
  onChange: (state: IQuestionBaseState) => void;
}

interface IQuestionBaseState {
  compliance: compliance | undefined;
  comment: string | undefined;
}

const ComplianceReportQuestionBase: FC<ICustomerQuestionBaseProps> = memo(
  ({ name, hidden, compliance, comment, onChange, children }) => {
    let commentRef: FormFieldRef<string> | null = null;
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const [errorText, setErrorText] = useState<string>();

    useEffect(() => {
      if (hidden === true) {
        commentRef?.setValue({ value: '' });
      }
    }, [hidden, commentRef]);

    const onCommentChange = (newComment: TypedFormFieldValue<string> | undefined) => {
      onChange({
        compliance,
        comment: newComment?.value,
      });
      updateErrorState(compliance, newComment?.value);
    };
    const onComplianceChange = (newCompliance: compliance) => {
      onChange({
        compliance: newCompliance,
        comment,
      });
      updateErrorState(newCompliance, comment);
    };

    const updateErrorState = (compliance?: compliance, comment?: string) => {
      const handleErrorText = (text?: string) => errorText !== text && setErrorText(text);
      if (compliance === undefined || compliance === 'yes' || (comment && comment.length > 0)) {
        handleErrorText(undefined);
      } else {
        handleErrorText(formatMessage({ id: 'law-portal.compliance.comment-required.error-message' }));
      }
    };

    return (
      <ListItem className={clsx(classes.root, { [classes.hidden]: hidden })} classes={{ container: classes.container }}>
        <div className={classes.textField}>
          <TextField
            className={clsx({ [classes.hidden]: hidden })}
            ref={(r) => (commentRef = r)}
            name={name}
            label={name}
            required={compliance === 'deficiency' || compliance === 'no'}
            isDisabled={hidden}
            initialState={comment ? { value: comment } : undefined}
            errorTexts={errorText ? [errorText] : []}
            onChange={onCommentChange}
          />
        </div>
        <ListItemSecondaryAction className={classes.actions}>
          <RadioGroup row onChange={(e) => onComplianceChange(e.target.value as compliance)}>
            <ComplianceReportCheckbox type="yes" value={compliance} hidden={hidden} />
            <ComplianceReportCheckbox type="deficiency" value={compliance} hidden={hidden} />
            <ComplianceReportCheckbox type="no" value={compliance} hidden={hidden} />
          </RadioGroup>
          {children}
        </ListItemSecondaryAction>
      </ListItem>
    );
  },
);
