import React from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';

interface ILawHeaderProps {
  name: string;
}

export const LawHeader = (props: ILawHeaderProps) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h2" component="h2">
          {props.name}
        </Typography>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
    </Grid>
  );
};
