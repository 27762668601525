import React, { useContext } from 'react';
import { Typography, Grid, Hidden, Divider } from '@material-ui/core';
import { Container } from './../Container/Container';
import { ChangeLocaleButton } from '../ChangeLocaleButton/ChangeLocaleButton';
import { CardLink } from './Components/CardLink/CardLink';
import lagportalenImg from '../../Assets/Images/lagportalen.png';
import organisationImg from '../../Assets/Images/organisation.png';
import verksamhetsutvecklingImg from '../../Assets/Images/verksomhetsutveckling.png';
import { FormattedMessage } from 'react-intl';
import {
  UserContext,
  Module,
  AccessLevel,
  LawPortalFeature,
  AdminFeature,
} from '../../Context/UserContext/UserContext';
import { NotAuthorized } from './NotAuthorized';
import { ServerError } from './ServerError';
import logo from '../../Assets/Images/aptor_logo.svg';
import { ImpersonationForm } from './Components/ImpersonationForm/ImpersonationForm';
import { LogoutButton } from './Components/LogoutButton/LogoutButton';
import { Logo } from '../Logo/Logo';
import { useStyles } from './Home.styles';
import { Forbidden } from './Forbidden';
import { getAppConfiguration } from '../../Config';
import { EnterName } from './EnterName';

const Home = (props: { forbidden?: boolean; notAuthorized?: boolean; serverError?: boolean }) => {
  const classes = useStyles();
  const { firstName, hasAccessToModule, hasAccessToFeature, lastName } = useContext(UserContext);
  const { enableImpersonation } = getAppConfiguration();
  return (
    <div>
      <Hidden smDown>
        {/* Desktop */}
        <Grid className={classes.topMenu} container justify="space-between" alignItems="center">
          <Grid item>
            <Logo src={logo} alt="Aptor logo" disablePadding />
          </Grid>
          {enableImpersonation && hasAccessToFeature(AdminFeature.Administrate, AccessLevel.Read) && (
            <Grid item sm={6}>
              <ImpersonationForm />
            </Grid>
          )}

          <Grid item>
            <ChangeLocaleButton />
            <LogoutButton />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        {/* Tablet & Mobile */}
        <Grid className={classes.topMenuMobile} container justify="space-between" alignItems="center">
          <Grid item>
            <Logo src={logo} alt="Aptor logo" disablePadding small />
          </Grid>
          <Grid item>
            <ChangeLocaleButton />
            <LogoutButton />
          </Grid>
        </Grid>
        <Divider />
        {enableImpersonation && hasAccessToFeature(AdminFeature.Administrate, AccessLevel.Read) && (
          <ImpersonationForm />
        )}
      </Hidden>
      <Container>
        {(props.forbidden && <Forbidden />) ||
          (props.notAuthorized && <NotAuthorized />) ||
          (props.serverError && <ServerError />) ||
          ((!firstName || !lastName) && <EnterName />) || (
            <>
              <Typography className={classes.heading} variant="h1" align="center" color="primary" gutterBottom>
                <FormattedMessage
                  id="component.home.welcome"
                  defaultMessage="Start page"
                  description="Welcome message"
                />
                {` ${firstName}!`}
              </Typography>
              <Typography className={classes.subHeading} variant="h2" align="center">
                <FormattedMessage
                  id="component.home.chooseModule"
                  defaultMessage="Choose module"
                  description="Choose module message"
                />
              </Typography>

              <Grid container spacing={2} justify="center">
                {hasAccessToModule(Module.Administration) && (
                  <CardLink link="/admin" label="component.home.aptor-admin" image={verksamhetsutvecklingImg} />
                )}
                {hasAccessToModule(Module.LawPortal) && (
                  <CardLink link="/law-portal" label="component.home.law-portal" image={lagportalenImg} />
                )}
                {(hasAccessToModule(Module.Organization) ||
                  hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Read)) && (
                  <CardLink link="/organization" label="component.home.administration" image={organisationImg} />
                )}
              </Grid>
            </>
          )}
      </Container>
    </div>
  );
};
export default Home;
