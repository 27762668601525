import React, { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { Query } from 'material-table';
import { HtmlView, MTable, BackButton } from '../../../../../Components';
import { IGridQueryRequest, OrderDirection } from '../../../../../Api/AptorApi';
import { useAptorApi } from '../../../../../Api';
import moment from 'moment';

type RelatedLawData = {
  id: number;
  number: string;
  name: string;
  area: string;
  description: string;
  changeNumber?: string;
  changeEffectiveFrom?: Date;
  hasAccessToLaw: boolean;
};

export const RelatedLaws = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api } = useAptorApi();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleDataFetch = useCallback(
    async (query: Query<RelatedLawData>) => {
      return await api.searchLawPortalRelatedLaws<RelatedLawData>(id, {
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'number',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
      } as IGridQueryRequest);
    },
    [id, api],
  );

  const renderDate = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
  };

  return (
    <>
      <Grid item xs={12}>
        <BackButton disableMargin link="/law-portal/our-laws/" />
      </Grid>
      <Grid item md={9} xs={12}>
        <MTable
          columns={[
            { title: formatMessage({ id: 'law-portal.our-laws.table.number' }), field: 'number' },
            { title: formatMessage({ id: 'law-portal.our-laws.table.law' }), field: 'name' },
            {
              title: formatMessage({ id: 'law-portal.our-laws.table.description' }),
              field: 'description',
              render: (row) => <HtmlView variant="body2" value={row.description} />,
              sorting: false,
            },
            { title: formatMessage({ id: 'law-portal.our-laws.table.area' }), field: 'area' },
            { title: formatMessage({ id: 'law-portal.our-laws.table.changeNumber' }), field: 'changeNumber' },
            {
              title: formatMessage({ id: 'law-portal.our-laws.table.changeEffectiveFrom' }),
              field: 'changeEffectiveFrom',
              render: (row) => renderDate(row.changeEffectiveFrom),
            },
          ]}
          data={handleDataFetch}
          onRowClick={(_, row) => row && row.hasAccessToLaw && history.push(`/law-portal/our-laws/${row.id}`)}
          options={{
            toolbar: false,
            paging: false,
            rowStyle: (row) => (row.hasAccessToLaw === false ? { pointerEvents: 'none' } : {}),
          }}
        />
      </Grid>
    </>
  );
};
