import React, { useCallback, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Query } from 'material-table';
import { useHistory } from 'react-router-dom';
import { Container } from '../../../../../Components/Container/Container';
import { IGridQueryRequest, OrderDirection } from '../../../../../Api/AptorApi';
import { useAptorApi } from '../../../../../Api';
import { MTable } from '../../../../../Components';
import moment from 'moment';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { useSnackbar } from 'notistack';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';

type SupportInviteData = {
  id: string;
  user: string;
  customer: string;
  description: string;
  validFromUTC: Date;
  validToUTC: Date;
};

export const SupportInvitesTable = () => {
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const { deleteConfirmation } = useConfirmation();

  const tableRef = React.createRef();
  const history = useHistory();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const { impersonateUserWithInvitation } = useContext(UserContext);

  useEffect(() => {
    tableRef.current && (tableRef.current as any).onQueryChange();
  }, [tableRef]);

  const handleDataFetch = useCallback(
    async (query: Query<SupportInviteData>) => {
      return await api.searchInvitations<SupportInviteData>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'validToUTC',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
      } as IGridQueryRequest);
    },
    [api],
  );

  const handleCloseInvite = (id: string) => async () => {
    await api.closeInvitation(id);
    if (abortController.current.signal.aborted) {
      return;
    }
    enqueueSnackbar(intl.formatMessage({ id: 'admin.support.table.closed' }));
    tableRef.current && (tableRef.current as any).onQueryChange();
  };

  return (
    <Container fullWidth>
      <MTable
        tableRef={tableRef}
        columns={[
          { title: formatMessage({ id: 'admin.support.table.customer' }), field: 'customer' },

          { title: formatMessage({ id: 'admin.support.table.user' }), field: 'user' },

          { title: formatMessage({ id: 'admin.support.table.description' }), field: 'description' },
          {
            title: formatMessage({ id: 'admin.support.table.validFrom' }),
            field: 'validFromUTC',
            render: (data) => moment.utc(data.validFromUTC).local().format('lll'),
          },

          {
            title: formatMessage({ id: 'admin.support.table.validTo' }),
            field: 'validToUTC',
            render: (data) => moment.utc(data.validToUTC).local().format('lll'),
          },
        ]}
        data={handleDataFetch}
        actionLabel={''}
        actions={[
          (row) => ({
            tooltip: formatMessage({ id: 'admin.support.table.impersonate' }),
            icon: 'people-alt',
            onClick: () => {
              impersonateUserWithInvitation({ id: row.id, validTo: row.validToUTC });
              history.push(`/`);
            },
          }),
          (row) => ({
            tooltip: formatMessage({ id: 'admin.support.table.close' }),
            icon: 'check',
            onClick: () => {
              deleteConfirmation(handleCloseInvite(row.id), {
                title: formatMessage({ id: 'admin.support.table.close' }),
                description: formatMessage({ id: 'admin.support.table.close-case-confirmation' }),
              });
            },
          }),
        ]}
      />
    </Container>
  );
};
