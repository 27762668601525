import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: theme.palette.success.main,
    opacity: '90%',
    color: theme.palette.common.white,
    zIndex: 1400,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
  },
  countdown: {
    lineHeight: 'inherit',
  },
}));
