import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useContext } from 'react';
import { Container } from '../../../../Components';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';
import { useStyles } from './Dashboard.styles';
import { NewLawChangesWidget } from './Widget.NewLawChanges';
import { NewLawsWidget } from './Widget.NewLaws';
import { NewUsersWidget } from './NewUsers';
import { TaggedItemsWidget } from './Tagged';
import { AccessLevel, OrganizationFeature, useUser } from '../../../../Context/UserContext/UserContext';

export const Dashboard = () => {
  const classes = useStyles();

  const { setHeader } = useContext(ModuleContext);
  const { formatMessage } = useIntl();
  const { hasAccessToFeature } = useUser();
  const showNewUsersWidget =
    hasAccessToFeature(OrganizationFeature.Manage, AccessLevel.Administer) &&
    hasAccessToFeature(OrganizationFeature.AAD, AccessLevel.Manage);

  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.dashboard.header', defaultMessage: 'Dashboard' }));
  }, [setHeader, formatMessage]);

  return (
    <Container fullWidth>
      <Grid container justify="space-evenly" spacing={2}>
        <Grid item sm={5}>
          <Typography variant="h1" component="h2" gutterBottom>
            <FormattedMessage id="law-portal.dashboard.welcome"></FormattedMessage>
          </Typography>
          <Typography>
            <FormattedMessage
              id="law-portal.dashboard.information"
              values={{
                i: (text: string) => <span className={classes.italics}>{text}</span>,
                b: (text: string) => <span className={classes.bold}>{text}</span>,
                mailTo: (text: string) => <a href={`mailto:${text}`}>{text}</a>,
                tel: (text: string) => <a href={`tel:${text}`}>{text}</a>,
                oldLink: (text: string) => <a href="https://old.lagportalen.se">{text}</a>,

                brDbl: () => (
                  <>
                    <br></br>
                    <br></br>
                  </>
                ),
                br: () => <br></br>,
              }}
            />
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <NewLawChangesWidget />
            </Grid>
            <Grid item>
              <TaggedItemsWidget />
            </Grid>
            <Grid item>
              <NewLawsWidget />
            </Grid>
            {showNewUsersWidget && (
              <Grid item>
                <NewUsersWidget />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
