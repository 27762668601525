import React, { FC, useCallback } from 'react';
import { AptorApi, useAptorApi } from '../../../../../../Api';
import { IGridQueryRequest } from '../../../../../../Api/AptorApi';
import { FormValues } from '../../../../../../Components/Form/Form.types';
import { Tags, ITag } from '../../Tags';

export const LawTags : FC<{ unitid: number, lawid: number }> = ({ unitid, lawid }) => {
  const { api } = useAptorApi();
  const optFetcher = useCallback(() => api.getCustomerLawTags(lawid, unitid), [lawid, unitid, api]);
  const dataFetcher = useCallback((request: IGridQueryRequest) => api.searchLawTags<ITag>(lawid, unitid, request), [lawid, unitid, api]);

  return (
    <Tags
      optionFetcher={optFetcher}
      dataFetcher={dataFetcher}
      addNew={(api: AptorApi, data: FormValues) => api.addLawTag(lawid, unitid, data)}
      remove={(api: AptorApi, tagid: number) => api.removeLawTag(lawid, unitid, tagid)}
    />
  );
};
