import React, { FC } from 'react';
import clsx from 'clsx';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { useStyles } from './Panel.styles';

interface ITitleProps {
  title?: string;
  titleKey?: string;
  titleValues?: any;
}

interface IProps extends ITitleProps {
  autoHeight?: boolean;
  accordion?: boolean;
  actionIcon?: React.ReactNode;
  defaultExpanded?: boolean;
}

const Title: FC<ITitleProps> = ({ title, titleKey, titleValues }) => {
  if (title || titleKey) {
    return (
      <Typography variant="h3" component="h2">
        {title || <FormattedMessage id={titleKey} values={titleValues} />}
      </Typography>
    );
  }
  return null;
};

export const Panel: FC<IProps> = ({
  autoHeight,
  defaultExpanded,
  title,
  titleKey,
  children,
  accordion,
  actionIcon,
  titleValues,
}) => {
  const classes = useStyles();

  return (
    (accordion && (
      <ExpansionPanel defaultExpanded={defaultExpanded}>
        <ExpansionPanelSummary expandIcon={actionIcon ?? <ExpandMore />}>
          <Title title={title} titleKey={titleKey} titleValues={titleValues} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panelDetails}>{children}</ExpansionPanelDetails>
      </ExpansionPanel>
    )) || (
      <Card className={clsx(classes.root, { [classes.autoHeight]: autoHeight })}>
        {(title || titleKey || actionIcon) && (
          <CardHeader
            title={<Title title={title} titleKey={titleKey} titleValues={titleValues} />}
            action={actionIcon}
          />
        )}
        <CardContent>{children}</CardContent>
      </Card>
    )
  );
};
