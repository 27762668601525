import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > .MuiCard-root + .MuiCard-root': {
      marginTop: theme.spacing(2), //Add margin when two groups are next to each other
    },
    '& .MuiCard-root + .MuiListItem-container': {
      border: 'none', //Remove border-top when item follows a group
    },
    marginBottom: theme.spacing(2),
  },
}));
