import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { startVersionDetector } from './versionDetector';
import { setEnvironment, setAppVersion } from './Config/Environment';
import { getAppConfiguration } from './Config';
import * as Package from '../package.json';
import LogRocket from 'logrocket';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core';
import theme from './Theme/theme';

import moment from 'moment';
import 'moment/locale/sv';
import 'moment/locale/en-gb';
import './Prototypes/Array';

var locale = (window.navigator as any).userLanguage || window.navigator.language;
moment.locale(locale);

startVersionDetector();

setEnvironment().then(() => {
  setAppVersion(Package.version);
  init();
});

const init = () => {
  const logrocketKey = getAppConfiguration().logrocketKey;
  if (logrocketKey) {
    LogRocket.init(logrocketKey, {
      network: {
        requestSanitizer: (request) => {
          if (request.url.toLowerCase().indexOf('meta') !== -1) {
            return null;
          }

          return request;
        },
      },
    });
  }

  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>,
    document.getElementById('root'),
  );

  serviceWorker.unregister();
};
