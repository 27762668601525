import { IconButton, Tooltip } from '@material-ui/core';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Check } from '@material-ui/icons';
import { useAptorApi } from '../../../../../Api';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';

interface IProps {
  id: number;
  onHandled: (id: number) => void;
}

export const MarkAsHandled: FC<IProps> = ({ id, onHandled }) => {
  const { api, abortController } = useAptorApi();
  const { formatMessage } = useIntl();
  const { deleteConfirmation } = useConfirmation();

  const onMarkAsHandled = () =>
    deleteConfirmation(
      () =>
        api.markDashboardNewUserAsHandled(id).then(() => {
          if (abortController.current.signal.aborted) {
            return;
          }
          onHandled(id);
        }),
      {
        title: formatMessage({
          id: 'law-portal.dashboard.new-users.mark-as-handled',
        }),
        description: formatMessage({
          id: 'law-portal.dashboard.new-users.mark-as-handled.confirmation',
        }),
      },
    );

  return (
    <Tooltip
      title={formatMessage({
        id: 'law-portal.dashboard.new-users.mark-as-handled',
      })}
    >
      <IconButton onClick={onMarkAsHandled}>
        <Check />
      </IconButton>
    </Tooltip>
  );
};
