import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LawChanges, LawCompliance, RelatedLaws, LawOverview, LawInternalComments } from './Law';
import { LawList } from './List/index';
import { Grid } from '@material-ui/core';
import { ConfirmationSessionProvider } from '../../../../Context/ComplianceSessionContext';
import { Container } from '../../../../Components';
import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';
import { useAptorApi } from '../../../../Api';
import { useIntl } from 'react-intl';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../Context/UserContext/UserContext';

export interface ILawInfo {
  name: string;
  number: string;
  category: string;
}
const Law = ({ info, id }: { id: number; info: ILawInfo }) => {
  const { formatMessage } = useIntl();
  const { setHeader } = useContext(ModuleContext);
  const user = useContext(UserContext);
  const isManager = user.hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  const isComplianceUser = user.complianceUserFor.laws.some((x) => x.id === id);

  useEffect(() => {
    const header = info ? `${info.category} ${info.number} ${info.name}` : '';
    const subMenu = [
      {
        title: formatMessage({ id: 'law-portal.our-laws.sub-menu.overview' }),
        to: `/law-portal/our-laws/${id}`,
      },
      {
        title: formatMessage({ id: 'law-portal.our-laws.sub-menu.changes' }),
        to: `/law-portal/our-laws/${id}/changes`,
      },
      {
        title: formatMessage({ id: 'law-portal.our-laws.sub-menu.related-laws' }),
        to: `/law-portal/our-laws/${id}/related-laws`,
      },
    ];
    if (isComplianceUser || isManager) {
      subMenu.splice(2, 0, {
        title: formatMessage({ id: 'law-portal.our-laws.sub-menu.compliance' }),
        to: `/law-portal/our-laws/${id}/compliance`,
      });
    }
    setHeader(header, subMenu);
  }, [id, info, isComplianceUser, isManager, setHeader, formatMessage]);

  return (
    <Switch>
      <Route exact path="/law-portal/our-laws/:id(\d+)" component={LawOverview} />
      <Route path="/law-portal/our-laws/:id(\d+)/changes" component={LawChanges} />
      <Route path="/law-portal/our-laws/:id(\d+)/compliance" component={LawCompliance} />
      <Route path="/law-portal/our-laws/:id(\d+)/related-laws" component={RelatedLaws} />
    </Switch>
  );
};

const LawView = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api, abortController } = useAptorApi();
  const [info, setInfo] = useState<ILawInfo>();
  useEffect(() => {
    api.getLawPortalLawInfo<ILawInfo>(id).then((result) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setInfo(result);
    });
  }, [api, abortController, id]);

  if (info === undefined) {
    return <></>;
  }

  return (
    <Grid container direction="row" spacing={2}>
      <Law info={info} id={id} />
      <LawInternalComments id={id} />
    </Grid>
  );
};

export const OurLaws = () => {
  return (
    <ConfirmationSessionProvider>
      <Container fullWidth>
        <Switch>
          <Route exact path="/law-portal/our-laws" component={LawList} />
          <Route path="/law-portal/our-laws/:id(\d+)" component={LawView} />
        </Switch>
      </Container>
    </ConfirmationSessionProvider>
  );
};
