import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { Container, Panel } from '../../../../Components';
import { CompanyUnits } from './CompanyUnits/CompanyUnits.List';
import { CompanyUnitEditForm } from './CompanyUnits/CompanyUnits.Edit';
import { EditProcess } from './CompanyUnits/Processes';
import { EditAspect } from './CompanyUnits/Aspects';
import { EditTag } from './CompanyUnits/Tags';
import { CompanyUsers } from './Users/Users.List';
import { UserEditForm } from './Users/Users.Edit';
import { UserInviteForm } from './Users/Users.Create';
import { CustomerLogotypeForm } from './LogotypeForm/CustomerLogotypeForm';
import { AzureADSettings } from './AzureAD';
import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';
import { AccessLevel, OrganizationFeature, UserContext } from '../../../../Context/UserContext/UserContext';

export const Organization = () => {
  const { formatMessage } = useIntl();
  const { setHeader } = useContext(ModuleContext);
  const { hasAccessToFeature } = useContext(UserContext);
  const canManage = hasAccessToFeature(OrganizationFeature.Manage, AccessLevel.Manage);
  const canManageAAD = hasAccessToFeature(OrganizationFeature.AAD, AccessLevel.Manage);

  const canAdminister = hasAccessToFeature(OrganizationFeature.Manage, AccessLevel.Administer);

  useEffect(() => {
    setHeader(formatMessage({ id: 'organization.header', defaultMessage: 'Organization' }));
  }, [setHeader, formatMessage]);

  return (
    <Container>
      <Switch>
        <Route exact path="/organization">
          <Grid container spacing={4} direction="column" justify="space-evenly" wrap="nowrap">
            <Grid item>
              <Panel titleKey="organization.company-division" accordion>
                <CompanyUnits />
              </Panel>
            </Grid>
            {canAdminister && (
              <Grid item>
                <Panel titleKey="organization.users" accordion>
                  <CompanyUsers />
                </Panel>
              </Grid>
            )}
            {canAdminister && (
              <Grid item>
                <Panel titleKey="organization.logotype" accordion>
                  <CustomerLogotypeForm />
                </Panel>
              </Grid>
            )}
            {canManageAAD && (
              <Grid item>
                <Panel titleKey="organization.azure-ad" accordion>
                  <AzureADSettings />
                </Panel>
              </Grid>
            )}
          </Grid>
        </Route>
        {canManage && (
          <Route exact path="/organization/company-units/:id(\d+)">
            <CompanyUnitEditForm />
          </Route>
        )}
        {canManage && (
          <Route path="/organization/company-units/:companyUnitId(\d+)/users/new">
            <UserInviteForm />
          </Route>
        )}
        {canManage && (
          <Route path="/organization/company-units/:companyUnitId(\d+)/users/:id(\d+)">
            <UserEditForm />
          </Route>
        )}
        {canAdminister && (
          <Route path="/organization/users/new">
            <UserInviteForm />
          </Route>
        )}
        {canAdminister && (
          <Route path="/organization/users/:id(\d+)">
            <UserEditForm />
          </Route>
        )}
        {canManage && (
          <Route path="/organization/company-units/:unitId/processes/:id(\d+)">
            <EditProcess />
          </Route>
        )}
        {canManage && (
          <Route path="/organization/company-units/:unitId/aspects/:id(\d+)">
            <EditAspect />
          </Route>
        )}
        {canManage && (
          <Route path="/organization/company-units/:unitId/tags/:id(\d+)">
            <EditTag />
          </Route>
        )}
        <Route path="/organization">
          <Redirect to="/organization" />
        </Route>
      </Switch>
    </Container>
  );
};
