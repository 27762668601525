import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { Panel, BackButton } from '../../../../../../Components';
import { RequirementChange } from './RequirementChange';
import { LatestChange } from './LatestChange';
import { UnacknowledgedChanges } from './Unacknowledged';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../../Context/UserContext/UserContext';
import { IListResult } from '../../../../../../Api/AptorApi';
import { useAptorApi } from '../../../../../../Api';

export interface IRequirementChange {
  id: number;
  name: string;
  effectiveFrom: Date;
  shortDescription: string;
  unacknowledgedByUsers: number[];
}

const RequirementChanges = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api, abortController } = useAptorApi();
  const user = useContext(UserContext);
  const displayUnacknowledgedChangesPanel = user.hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  const [revision, setRevision] = useState<number>(0);
  const [state, setState] = useState<IListResult<IRequirementChange>>();

  useEffect(() => {
    api.getLawPortalRequirementChanges<IRequirementChange>(id).then((state) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setState(state);
    });
  }, [api, abortController, id]);

  const handleAcknowledge = async (changeId: number, comment?: string) => {
    if (state?.items) {
      await api.acknowledgeLawPortalRequirementChange(id, changeId, comment);
      if (abortController.current.signal.aborted) {
        return;
      }
      const items = [...state.items].replace(
        (c) => c.id === changeId,
        (change) => ({
          ...change,
          ...{ unacknowledgedByUsers: change.unacknowledgedByUsers.filter((x) => x !== user.id) },
        }),
      );
      setState({ ...state, ...{ items } });
      setRevision(revision + 1);
    }
  };

  const latestChange = state?.items[0];

  return (
    <>
      <Grid item xs={12}>
        <BackButton disableMargin link="/law-portal/other-requirements" />
      </Grid>
      <Grid item md={9} xs={12}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Panel
              titleKey="law-portal.requirements.changes.latest-change.label"
              titleValues={latestChange && { name: latestChange.name }}
            >
              {latestChange ? (
                <RequirementChange
                  reqId={id}
                  change={latestChange}
                  acknowledge={(comment?: string) => handleAcknowledge(latestChange.id, comment)}
                />
              ) : (
                <FormattedMessage id="law-portal.requirements.changes.no-changes.message" />
              )}
            </Panel>
          </Grid>
          {state && state.items.length > 1 && (
            <Grid item xs={12}>
              <Panel titleKey="law-portal.our-laws.changes.older-changes.label">
                {state.items
                  .filter((_, index) => index !== 0)
                  .map((change) => (
                    <Card variant="outlined" key={change.id}>
                      <CardHeader title={<Typography variant="h4">{change.name}</Typography>} />
                      <CardContent>
                        <RequirementChange
                          reqId={id}
                          change={change}
                          acknowledge={(comment?: string) => handleAcknowledge(change.id, comment)}
                        />
                      </CardContent>
                    </Card>
                  ))}
              </Panel>
            </Grid>
          )}
          {displayUnacknowledgedChangesPanel && (
            <Grid item xs={12}>
              <UnacknowledgedChanges reqId={id} key={revision} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export { RequirementChanges, LatestChange, UnacknowledgedChanges };
