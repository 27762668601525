import React, { useCallback, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Query } from 'material-table';
import MTable from '../../../../../../Components/MTable/MTable';
import { UserContext } from '../../../../../../Context/UserContext/UserContext';
import { useAptorApi } from '../../../../../../Api';
import { IGridQueryRequest, OrderDirection } from '../../../../../../Api/AptorApi';
import moment from 'moment';
import { Typography } from '@material-ui/core';

type ReportData = {
  id: number;
  law: string;
  controller: string;
  companyUnit: string;
  date: Date;
};

export const Reports = (props: { lawId: number }) => {
  const { getCompanyUnitName } = useContext(UserContext);
  const { formatMessage } = useIntl();
  const { api } = useAptorApi();
  const { lawId } = props;

  const handleDataFetch = useCallback(
    async (query: Query<ReportData>) => {
      return await api.searchLawComplianceReports<ReportData>(lawId, {
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'date',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
      } as IGridQueryRequest);
    },
    [lawId, api],
  );

  return (
    <>
      <Typography gutterBottom variant="h3" component="h2">
        <FormattedMessage id="law-portal.our-laws.compliance.table.reports" />
      </Typography>
      <MTable
        hideToolbar
        columns={[
          { title: formatMessage({ id: 'law-portal.our-laws.compliance.table.law' }), field: 'law' },

          { title: formatMessage({ id: 'law-portal.our-laws.compliance.table.controller' }), field: 'controller' },

          {
            title: getCompanyUnitName(formatMessage),
            field: 'companyUnit',
          },
          {
            title: formatMessage({ id: 'law-portal.our-laws.compliance.table.date' }),
            field: 'date',
            render: (data: ReportData) => moment.utc(data.date).format('ll'),
          },
        ]}
        data={handleDataFetch}
        actionLabel={''}
        actions={[
          (row) => ({
            tooltip: formatMessage({ id: 'law-portal.our-laws.compliance.table.download' }),
            icon: 'picture_as_pdf',
            onClick: () => {
              api.downloadLawComplianceReport(lawId, row.id);
            },
          }),
        ]}
      />
    </>
  );
};
