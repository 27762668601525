import React, { FC, useCallback } from 'react';
import { AptorApi, useAptorApi } from '../../../../../../Api';
import { IGridQueryRequest } from '../../../../../../Api/AptorApi';
import { FormValues } from '../../../../../../Components/Form/Form.types';
import { Processes, IProcess } from '../../Processes';

export const RequirementProcesses : FC<{ unitid: number, reqid: number }> = ({ unitid, reqid }) => {
  const { api } = useAptorApi();
  const optFetcher = useCallback(() => api.getCustomerRequirementProcesses(reqid, unitid), [reqid, unitid, api]);
  const dataFetcher = useCallback((request: IGridQueryRequest) => api.searchRequirementProcesses<IProcess>(reqid, unitid, request), [reqid, unitid, api]);

  return (
    <Processes
      optionFetcher={optFetcher}
      dataFetcher={dataFetcher}
      addNew={(api: AptorApi, data: FormValues) => api.addRequirementProcess(reqid, unitid, data)}
      remove={(api: AptorApi, processid: number) => api.removeRequirementProcess(reqid, unitid, processid)}
    />
  );
};
