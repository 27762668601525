import React, { FC, useState, useEffect } from 'react';
import { Panel } from './Panel';
import { IconButton, Tooltip } from '@material-ui/core';
import { Edit, Add, Close } from '@material-ui/icons';
import { useIntl } from 'react-intl';

type mode = 'view' | 'editing';

interface IProps {
  /** Translation key */
  titleKey: string;
  iconType?: string;
  view: React.ReactNode;
  form: React.ReactNode;
  /** Changes to dependencies causes the form to reset to view mode */
  dependencies?: any[];
  setEditMode?: boolean;
}

export const EditablePanel: FC<IProps> = ({ titleKey, view, form, children, iconType = 'edit', dependencies, setEditMode }) => {
  const { formatMessage } = useIntl();
  const [mode, setMode] = useState<mode>('view');
  const toggleMode = () => {
    setMode(mode === 'view' ? 'editing' : 'view');
  };

  useEffect(() => {
    if (dependencies && dependencies.length > 0) {
      /* Reset to view mode if any dependencies change. 
      Seems a bit hacky, maybe there's a better way? */
      setMode('view');
    }
  }, [dependencies]);

  useEffect(() => {
    if (setEditMode) {
      setMode('editing');
    }
  }, [setEditMode]);

  let title = '';
  if (mode === 'editing') {
    title = formatMessage({ id: 'utils.icon.close', defaultMessage: 'Close' });
  }
  if (mode === 'view' && iconType === 'edit') {
    title = formatMessage({ id: 'utils.icon.edit', defaultMessage: 'Edit' });
  }
  if (mode === 'view' && iconType === 'add') {
    title = formatMessage({ id: 'utils.icon.add', defaultMessage: 'Add' });
  }

  return (
    <Panel
      titleKey={titleKey}
      actionIcon={
        <Tooltip title={title}>
          <IconButton aria-label={title} onClick={toggleMode}>
            {mode === 'editing' && <Close fontSize="small" />}
            {mode === 'view' && iconType === 'edit' && <Edit fontSize="small" />}
            {mode === 'view' && iconType === 'add' && <Add fontSize="small" />}
          </IconButton>
        </Tooltip>
      }
    >
      {mode === 'view' && view}
      {mode === 'editing' && form}
      {children}
    </Panel>
  );
};
