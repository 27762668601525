import React, { FC, memo, useCallback } from 'react';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ListItemText,
  ListItem,
  List,
  Card,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { ComplianceReportQuestion, ComplianceReportCustomerQuestion } from '../Question';
import { IQuestion, compliance } from '../CreateReport';
import { useStyles } from './Group.styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';

interface IProps {
  id: number;
  name: string;
  groupItems: IQuestion[];
  disableHide?: boolean;
  onChange: (
    questionId: number,
    groupId: number,
    state: { compliance: compliance | undefined; comment: string | undefined; hidden: boolean },
  ) => void;
}

export const ComplianceReportGroup: FC<IProps> = memo(({ id, name, groupItems, disableHide, onChange }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const onQuestionChange = useCallback((questionId, _, state) => onChange(questionId, id, state), [onChange, id]);
  const groupIsHidden = !groupItems.some((x) => !x.hidden);
  const buttonIcon = groupIsHidden ? <VisibilityOff /> : <Visibility />;
  const buttonTranslationKey = groupIsHidden
    ? 'law-portal.compliance.question.show-button'
    : 'law-portal.compliance.question.hide-button';

  const onToggleGroupHidden = () => {
    const hidden = !groupIsHidden;
    groupItems.forEach((question) => {
      if (question.hidden !== hidden) {
        onQuestionChange(question.id, id, { ...question, ...{ hidden } });
      }
    });
  };

  return (
    <Card className={classes.card} variant="outlined" component="li">
      <List
        className={classes.list}
        aria-labelledby={name}
        subheader={
          <ListItem className={clsx(classes.header, { [classes.hidden]: groupIsHidden })}>
            <ListItemText disableTypography>
              <Typography id={name} component="h3" variant="h4">
                {name}
              </Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              {disableHide === false && (
                <Tooltip title={formatMessage({ id: buttonTranslationKey })}>
                  <IconButton onClick={onToggleGroupHidden} aria-label={formatMessage({ id: buttonTranslationKey })}>
                    {buttonIcon}
                  </IconButton>
                </Tooltip>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        }
      >
        {groupIsHidden === false &&
          groupItems.map((gi) => (
            <ComplianceReportQuestion
              key={`group-question-${gi.id}`}
              {...gi}
              disableHide={disableHide}
              onChange={onQuestionChange}
            />
          ))}
      </List>
    </Card>
  );
});

interface ICustomerQuestionsProps {
  questions: IQuestion[];
  onChange: (index: number, state: { compliance: compliance | undefined; comment: string | undefined }) => void;
}

export const ComplianceReportCustomerQuestions: FC<ICustomerQuestionsProps> = memo(({ questions, onChange }) => {
  const classes = useStyles();
  return (
    <Card variant="outlined" component="li">
      <List
        className={classes.list}
        aria-labelledby="customerQuestions"
        subheader={
          <ListItem className={classes.header}>
            <ListItemText>
              <Typography id="customerQuestions" component="h3" variant="h4">
                <FormattedMessage
                  id="law-portal.compliance.customer-questions.label"
                  defaultMessage="Customer questions"
                />
              </Typography>
            </ListItemText>
          </ListItem>
        }
      >
        {questions.map((q, index) => (
          <ComplianceReportCustomerQuestion key={`group-question-${index}`} {...q} index={index} onChange={onChange} />
        ))}
      </List>
    </Card>
  );
});
