import { OptionsEntity } from '../../Utilities';

export const updateFilterOptionsDependentOnCompanyUnit = <TOptions extends OptionsEntity>(
  field: string,
  values: string[] | { min?: Date; max?: Date } | undefined,
  setOptions: (value: TOptions) => void,
  allOptions: TOptions | null,
  filters: any,
  setFilter: (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => void,
  userCompanyUnits: { [userId: number]: string[] },
) => {
  if (!allOptions) {
    return;
  }

  if (field === 'companyUnit') {
    if (!values || (values && Array.isArray(values) && values.length === 0)) {
      // companyUnit filter is cleared. We need to show all options again.
      const newOptions = {
        ...allOptions,
      };
      setOptions(newOptions);
    } else if (values && Array.isArray(values) && values.length > 0) {
      // companyUnit filter is set. We need to filter other options affected by this filter.
      const newOptions = {
        ...allOptions,
        processes: allOptions.processes?.filter((x) => values.some((value) => value === x.label)),
        aspects: allOptions.aspects?.filter((x) => values.some((value) => value === x.label)),
        tags: allOptions.tags?.filter((x) => values.some((value) => value === x.label)),
        complianceUsers: allOptions.complianceUsers?.filter((complianceUser) =>
          userCompanyUnits[complianceUser.value as number]?.some((companyUnit) =>
            values.some((value) => value === companyUnit),
          ),
        ),
      };
      setOptions(newOptions);
      deselectNestedIncompatibleFilterValues(filters, setFilter, 'processes', newOptions);
      deselectNestedIncompatibleFilterValues(filters, setFilter, 'aspects', newOptions);
      deselectNestedIncompatibleFilterValues(filters, setFilter, 'tags', newOptions);
      deselectIncompatibleFilterValues(filters, setFilter, 'complianceUsers', newOptions);
    }
  }
};

const deselectNestedIncompatibleFilterValues = (
  filters: any,
  setFilter: (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => void,
  filterField: string,
  options: OptionsEntity,
) => {
  if (filters[filterField] && Array.isArray(filters[filterField]) && filters[filterField].length > 0) {
    const validFilterValues: string[] = filters[filterField].filter((p: any) =>
      options[filterField].some((option) => option.options?.some((x) => x.value.toString() === p)),
    );
    if (validFilterValues.length !== filters[filterField].length) {
      setFilter(filterField, validFilterValues);
    }
  }
};

const deselectIncompatibleFilterValues = (
  filters: any,
  setFilter: (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => void,
  filterField: string,
  options: OptionsEntity,
) => {
  if (filters[filterField] && Array.isArray(filters[filterField]) && filters[filterField].length > 0) {
    const validFilterValues: string[] = filters[filterField].filter((p: any) =>
      options[filterField].some((option) => option.value.toString() === p),
    );
    if (validFilterValues.length !== filters[filterField].length) {
      setFilter(filterField, validFilterValues.length === 0 ? undefined : validFilterValues);
    }
  }
};
