import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { FormFieldRef, RadioFieldProps } from './Field.props';
import { FieldHelperOrErrors } from './FieldHelperOrErrors';
import { useStyles } from './Field.styles';
import { FormFieldValueType, TypedFormFieldValue } from '../Form/Form.types';
import { shallowCompare } from './shallowCompare';

export const RadioField = memo(
  forwardRef(
    <T extends FormFieldValueType>(
      props: RadioFieldProps<T>,
      ref: ((instance: FormFieldRef<T> | null) => void) | React.MutableRefObject<FormFieldRef<T> | null> | null,
    ) => {
      const classes = useStyles();
      const { isDisabled, label, helperText, errorTexts, onChange, initialState, ...rest } = props;
      const [field, setField] = useState<T>((initialState as TypedFormFieldValue<T>).value);

      useImperativeHandle(
        ref,
        () => ({
          setValue: (field: TypedFormFieldValue<T> | TypedFormFieldValue<T>[] | undefined) => {
            setField((field as TypedFormFieldValue<T>).value);
          },
        }),
        [],
      );

      const handleChange = (value: string) => {
        if (typeof field === 'number') {
          setField(+value as T);
        } else {
          setField(value as T);
        }
      };

      useEffect(() => {
        onChange({ value: field, isValid: true } as TypedFormFieldValue<T>);
      }, [field, onChange]);

      return (
        <FormControl error={props.error} className={`${classes.formControl} ${rest.error ? classes.error : ''}`}>
          <Typography htmlFor={props.name} className={`${classes.label}`} component="label">
            {props.label}
          </Typography>
          <RadioGroup name={props.name} value={field} onChange={(e) => handleChange(e.target.value)}>
            {props.options.map((opt) => (
              <FormControlLabel
                key={`${opt.value}`}
                value={opt.value}
                control={<Radio color="primary" />}
                label={opt.label}
              />
            ))}
          </RadioGroup>

          <FieldHelperOrErrors helperText={helperText} errorTexts={errorTexts} />
        </FormControl>
      );
    },
  ),
  (newProps, oldProps) => shallowCompare(newProps, oldProps, ['errorTexts']),
);

export default RadioField;
