import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import Form from '../../../../../Components/Form/Form';
import { FormValues } from '../../../../../Components/Form/Form.types';
import { useFormField } from '../../../../../Components/Form/FormField';
import { AptorApi } from '../../../../../Api';
import { ILawChange } from '.';

interface IFormProps {
  submit: (
    form: FormValues,
    api: AptorApi,
    onSuccess: (successMessage?: string | undefined | null) => void,
  ) => Promise<void>;
  cancel?: () => void;
  initialState?: ILawChange;
}

const EditLawChangesForm: FC<IFormProps> = ({ submit, cancel, initialState }) => {
  const { formatMessage } = useIntl();
  const number = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.change.form.number.label', defaultMessage: 'Number' }),
    name: 'number',
    type: 'text',
    required: true,
    initialState: initialState && { value: initialState.number },
  });

  const extent = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.change.form.extent.label', defaultMessage: 'Extent' }),
    name: 'extent',
    type: 'text',
    multilineRows: 10,
    required: false,
    initialState: (initialState?.extent && { value: initialState.extent }) || undefined,
  });

  const effectiveFrom = useFormField({
    label: formatMessage({
      id: 'admin.law-portal.law.change.form.effectiveFrom.label',
      defaultMessage: 'Effective from',
    }),
    name: 'effectiveFrom',
    type: 'date',
    required: true,
    initialState: initialState && { value: initialState.effectiveFrom },
  });

  const description = useFormField({
    label: formatMessage({
      id: 'admin.law-portal.law.change.form.description.label',
      defaultMessage: 'Short description',
    }),
    name: 'description',
    type: 'rich-text',
    richTextEditorHeight: 'small',
    required: true,
    initialState: initialState && { value: initialState.shortDescription },
  });

  const url = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.change.form.url.label', defaultMessage: 'Url' }),
    name: 'url',
    type: 'text',
    required: true,
    initialState: initialState && { value: initialState.urlAddress },
  });

  const reprint = useFormField({
    label: formatMessage({ id: 'admin.law-portal.law.change.form.reprint.label', defaultMessage: 'Reprint' }),
    name: 'reprint',
    type: 'text',
    required: false,
    initialState: (initialState?.reprint && { value: initialState.reprint }) || undefined,
  });

  const transitionalRegulation = useFormField({
    label: formatMessage({
      id: 'admin.law-portal.law.change.form.transitionalRegulation.label',
      defaultMessage: 'Has transitional regulation',
    }),
    name: 'hasTransitionalRegulation',
    type: 'checkbox',
    required: false,
    initialState: { value: initialState?.hasTransitionalRegulation ?? false },
  });

  const includedInSendouts = useFormField({
    label: formatMessage({
      id: 'admin.law-portal.law.change.form.includeInSendouts.label',
      defaultMessage: 'Include in sendouts',
    }),
    name: 'includeInSendouts',
    type: 'checkbox',
    required: false,
    initialState: { value: initialState?.includeInSendouts ?? false },
  });

  return (
    <Form
      submit={submit}
      cancel={cancel}
      groups={[
        {
          grid: {
            type: 'column',
            items: [
              {
                type: 'row',
                items: [
                  { type: 'column', flex: 2, items: [number, effectiveFrom, url, reprint] },
                  { type: 'column', flex: 2, items: [extent, transitionalRegulation, includedInSendouts] },
                  { type: 'column', flex: 3, items: [description] },
                ],
              },
            ],
          },
        },
      ]}
    />
  );
};

interface IProps {
  id: number;
  change: ILawChange;
  changes: ILawChange[];
  onChange: (params: { changes: ILawChange[] }) => void;
  cancel?: () => void;
}

const parseForm = (form: FormValues, id: number, removable: boolean = true) => {
  return {
    id: id,
    number: form['number'] as string,
    extent: form['extent'] as string,
    effectiveFrom: (form['effectiveFrom'] as unknown) as Date,
    shortDescription: form['description'] as string,
    urlAddress: form['url'] as string,
    reprint: form['reprint'] as string,
    hasTransitionalRegulation: (form['hasTransitionalRegulation'] as unknown) as boolean,
    isPosibleToDelete: removable,
    includeInSendouts: form['includeInSendouts'] as boolean,
  };
};

export const EditLawAddChange: FC<Omit<IProps, 'change' | 'cancel'>> = ({ id, changes, onChange }) => {
  const submit = async (form: FormValues, api: AptorApi, onSuccess: () => void) => {
    const response = await api.addLawChange(id, form);
    onSuccess();
    onChange({
      changes: [parseForm(form, response.id), ...changes],
    });
  };

  return <EditLawChangesForm submit={submit} />;
};

export const EditLawEditChange: FC<IProps> = ({ id, change, changes, onChange, cancel }) => {
  const submit = async (form: FormValues, api: AptorApi, onSuccess: () => void) => {
    await api.updateLawChange(id, change.id, form);
    onSuccess();
    onChange({
      changes: [
        parseForm(form, change.id, change.isPosibleToDelete),
        ...changes.filter((c) => c.id !== change.id),
      ].sort((a, b) => {
        return new Date(b.effectiveFrom).getTime() - new Date(a.effectiveFrom).getTime();
      }),
    });
  };

  return <EditLawChangesForm submit={submit} initialState={change} cancel={cancel} />;
};
