import React, { useContext } from 'react';
import { Sidebar, ISidebarProps } from '../../../../Components/Sidebar/Sidebar';
import { Gavel, Business } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { AccessLevel, LawPortalFeature, Module, UserContext } from '../../../../Context/UserContext/UserContext';

export const AdministrationSidebar = (props: ISidebarProps) => {
  const { formatMessage } = useIntl();
  const { hasAccessToFeature, hasAccessToModule } = useContext(UserContext);

  const items = [];

  if (hasAccessToModule(Module.Organization)) {
    items.push({
      to: '/organization',
      text: formatMessage({ id: 'organization.header', defaultMessage: 'Organization' }),
      icon: <Business />,
    });
  }

  if (hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage)) {
    items.push({
      to: '/organization/law-portal',
      text: formatMessage({ id: 'law-portal.manage.header', defaultMessage: 'Laws and other requirements' }),
      icon: <Gavel />,
    });
  }

  return (
    <Sidebar
      title={formatMessage({ id: 'component.home.administration', defaultMessage: 'Administration' })}
      items={items}
      sidebarOpen={props.sidebarOpen}
      handleSidebarToggle={props.handleSidebarToggle}
    />
  );
};
