import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { AssignmentTurnedIn } from '@material-ui/icons';
import { BackButton, ButtonLink, Panel, Container } from '../../../../../../Components';
import { FormValues } from '../../../../../../Components/Form/Form.types';
import { CompanyUnitEditForm, ICompanyUnit } from '../../CompanyUnits.Edit';
import { RequirementProcesses } from './RequirementProcesses';
import { RequirementAspects } from './RequirementAspects';
import { RequirementTags } from './RequirementTags';
import { LawHeader } from '../../LawHeader';
import { IRequirement } from '../CustomerRequirements.List';
import { UserContext } from '../../../../../../Context/UserContext/UserContext';
import { AptorApi, useAptorApi, useFetchSelectOptions } from '../../../../../../Api';
import { useSnackbar } from 'notistack';

const RequirementCompanyUnitForm = (props: { reqId: number; isNew?: boolean; unitId?: number }) => {
  const { reqId, isNew, unitId } = props;
  const { api, abortController } = useAptorApi();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { getCompanyUnitName } = useContext(UserContext);
  const [units] = useFetchSelectOptions(
    useCallback(() => api.getCustomerRequirementCompanyUnits(reqId), [reqId, api]),
    abortController,
  );
  const [users] = useFetchSelectOptions(
    useCallback(() => {
      if (isNew) return api.getCustomerUsers();
      return api.getCustomerCompanyUnitUsers(unitId!);
    }, [isNew, unitId, api]),
    abortController,
  );
  const fetcher = useCallback(() => api.getRequirementCompanyUnit<ICompanyUnit>(reqId, unitId!), [reqId, unitId, api]);

  const handleSubmit = async (data: FormValues, api: AptorApi) => {
    var ok = true;
    if (isNew) {
      ok = await api.addRequirementCompanyUnits(reqId, data);
    } else await api.updateRequirementCompanyUnit(reqId, unitId!, { complianceUsers: data.complianceUsers });
    if (!ok && !abortController.current.signal.aborted)
      enqueueSnackbar(
        formatMessage({ id: 'organization.user-unit-mismatch' }, { alias: getCompanyUnitName(formatMessage, true) }),
        { variant: 'warning' },
      );
    history.push(`/organization/law-portal/other-requirements/${reqId}`);
  };

  return (
    <CompanyUnitEditForm
      isNew={!!isNew}
      units={units}
      users={users}
      dataFetcher={fetcher}
      handleSubmit={handleSubmit}
    />
  );
};

export const AddRequirementCompanyUnit = () => {
  const match = useRouteMatch<{ reqid: string }>();
  const id = parseInt(match.params.reqid);
  return (
    <Container>
      <BackButton link={`/organization/law-portal/other-requirements/${id}`} />
      <RequirementCompanyUnitForm reqId={id} isNew />
    </Container>
  );
};

export const EditRequirementCompanyUnit = () => {
  const match = useRouteMatch<{ reqid: string; unitid: string }>();
  const reqid = parseInt(match.params.reqid);
  const unitid = parseInt(match.params.unitid);
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const [name, setName] = useState('');

  //Initialization
  useEffect(() => {
    const initializeName = async () => {
      const info = await api.getRequirement<IRequirement>(reqid);
      if (abortController.current.signal.aborted) {
        return;
      }
      setName(info.name);
    };
    if (name === '') {
      initializeName();
    }
  }, [reqid, name, api, abortController]);

  return (
    <Container>
      <BackButton link={`/organization/law-portal/other-requirements/${reqid}`} />
      <Grid container spacing={4} direction="column">
        <Grid item>
          <LawHeader name={name} />
        </Grid>
        <Grid item>
          <ButtonLink
            to={`/organization/law-portal/other-requirements/${reqid}/company-units/${unitid}/checklist`}
            text={formatMessage({ id: 'law-portal.manage.requirements.checklist' })}
            icon={<AssignmentTurnedIn />}
          />
        </Grid>
        <Grid item>
          <RequirementCompanyUnitForm reqId={reqid} unitId={unitid} />
        </Grid>
        <Grid item>
          <Panel titleKey="law-portal.manage.requirements.connectedProcesses" accordion>
            <RequirementProcesses reqid={reqid} unitid={unitid} />
          </Panel>
        </Grid>
        <Grid item>
          <Panel titleKey="law-portal.manage.requirements.connectedAspects" accordion>
            <RequirementAspects reqid={reqid} unitid={unitid} />
          </Panel>
        </Grid>
        <Grid item>
          <Panel titleKey="law-portal.manage.requirements.connectedTags" accordion>
            <RequirementTags reqid={reqid} unitid={unitid} />
          </Panel>
        </Grid>
      </Grid>
    </Container>
  );
};
