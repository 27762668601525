import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Container, Panel, BackButton } from '../../../../../Components';
import { LawHeader } from '../LawHeader';
import { LawCompanyUnits } from './CompanyUnits/LawCompanyUnits.List';
import { ICustomerLaw } from './CustomerLaws.List';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { useAptorApi } from '../../../../../Api';

export const EditCustomerLaw = () => {
  const match = useRouteMatch<{ lawid: string }>();
  const id = parseInt(match.params.lawid);
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const { getCompanyUnitName } = useContext(UserContext);
  const [name, setName] = useState<string>();

  //Initialization
  useEffect(() => {
    const initializeName = async () => {
      const law = await api.getCustomerLaw<ICustomerLaw>(id);
      if (abortController.current.signal.aborted) {
        return;
      }
      setName(law.number + ' ' + law.name);
    };
    if (name === undefined) {
      initializeName();
    }
  }, [id, name, api, abortController]);

  return (
    <Container>
      <BackButton link="/organization/law-portal/laws/" />
      {name && (
        <Grid container spacing={4} direction="column">
          <Grid item>
            <LawHeader name={name} />
          </Grid>
          <Grid item>
            <Panel
              titleKey="law-portal.manage.edit-law.connectedCompanyUnits"
              titleValues={{ alias: getCompanyUnitName(formatMessage, true) }}
            >
              <LawCompanyUnits lawId={id} />
            </Panel>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};
