import { IAppConfiguration } from './IAppConfiguration';

export default {
  logrocketKey: undefined,
  tenantName: 'aptordev',
  aptorApi: 'https://localhost:44321',
  clientId: '2c45454e-3eec-48fe-9bb3-87409a59d8a0',
  redirectUrl: 'http://localhost:3000/',
  policyName: 'B2C_1A_signup_signin',
  tinyMCEApiKey: '1njdfilpf3cwf9ws8gnrvdcmzt6pufa2fd1bj3165lcs8spl',
  inivtePolicy: 'B2C_1A_signup_invitation',
  forgotPasswordPolicy: 'B2C_1A_passwordreset',
  redirectLogoutUrl: 'http://localhost:3000/',
  enableImpersonation: true,
  knownAuthority: 'https://aptordev.b2clogin.com',
  scopes: [
    'https://aptordev.onmicrosoft.com/d9e7182b-0c50-43f1-b4e2-bd087eeeffc6/user_impersonation'
  ],
} as IAppConfiguration;