import LOCAL from "./environment.local";
import PROD from "./environment.prod";
import TEST from "./environment.test";
import DEV from "./environment.dev";

import { IAppConfiguration } from "./IAppConfiguration";
import { getEnvironment, Environment } from "./Environment";

export const getAppConfiguration = () => {
  let env = {};
  const NODE_ENV = process.env.REACT_APP_API_BASE_URL
  console.log(NODE_ENV,"node env")
  if (NODE_ENV === Environment.PROD) {
    env = PROD;
  } else if (NODE_ENV === Environment.TEST) {
    env = TEST;
  } else if (NODE_ENV === Environment.DEV) {
    env = DEV;
  } else if (NODE_ENV === Environment.LOCAL) {
    env = LOCAL;
  }

  return { ...env } as IAppConfiguration;
};
