import { IconButton, Tooltip } from '@material-ui/core';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Check } from '@material-ui/icons';
import { useAptorApi } from '../../../../../Api';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';
import { Metadata, EntityTypes } from '.';

interface IProps {
  id: number;
  type: EntityTypes;
  onMarkedAsRead: (id: number, type: EntityTypes) => void;
}

export const MarkAsRead: FC<IProps> = ({ id, type, onMarkedAsRead }) => {
  const { api, abortController } = useAptorApi();
  const { formatMessage } = useIntl();
  const { deleteConfirmation } = useConfirmation();

  const onMarkAsRead = () =>
    deleteConfirmation(
      () =>
        Metadata[type].markAsRead(id, api).then(() => {
          if (abortController.current.signal.aborted) {
            return;
          }
          onMarkedAsRead(id, type);
        }),
      {
        title: formatMessage({
          id: `law-portal.dashboard.tagged-in-laws.mark-as-read`,
        }),
        description: formatMessage({
          id: 'law-portal.dashboard.tagged-in-laws.mark-as-read.confirmation',
        }),
      },
    );

  return (
    <Tooltip
      title={formatMessage({
        id: `law-portal.dashboard.tagged-in-laws.mark-as-read`,
      })}
    >
      <IconButton onClick={onMarkAsRead}>
        <Check />
      </IconButton>
    </Tooltip>
  );
};
