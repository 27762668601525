import React, { FC, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Grid, Icon, Typography } from '@material-ui/core';
import { LawLink } from '..';
import { useConfirmation } from '../../Context/ConfirmationContext/ConfirmationContext';
import { UserContext } from '../../Context/UserContext/UserContext';
import moment from 'moment';
import { TextField } from '../Field/TextField';

export interface IChange {
  id: number;
  number: string;
  effectiveFrom: Date;
  shortDescription: string;
  reprint?: string;
  extent?: string;
  urlAddress: string;
  unacknowledgedByUsers?: number[];
  hasTransitionalRegulation: boolean;
  includeInSendouts: boolean;
}

interface IProps {
  lawId: number;
  change: IChange;
  acknowledge?: (comment?: string) => Promise<void>;
}

export const LawChange: FC<IProps> = ({ lawId, change, acknowledge }) => {
  const { complianceUserFor, id } = useContext(UserContext);
  const { deleteConfirmation } = useConfirmation();
  const { formatMessage } = useIntl();

  const [comment, setComment] = useState<string>('');

  const shouldAcknowledge =
    acknowledge &&
    complianceUserFor.laws.some((x) => x.id === lawId) &&
    change.unacknowledgedByUsers &&
    change.unacknowledgedByUsers.some((x) => x === id);

  const confirmation = {
    title: formatMessage({ id: 'component.law-change.acknowledge.label' }),
    description: formatMessage({ id: 'component.law-change.acknowledge.confirmation' }),
  };

  const ack = () => {
    return acknowledge!(comment);
  };

  const handleAcknowledge = () => {
    if (acknowledge) {
      deleteConfirmation(ack, confirmation);
    }
  };

  return (
    <Grid container direction="column">
      <Typography dangerouslySetInnerHTML={{ __html: change.shortDescription }} />
      {change.extent && <Typography>{change.extent}</Typography>}
      <LawLink type="law-change" url={change.urlAddress} />
      <Typography>
        <FormattedMessage
          id="component.law-change.effectiveFrom"
          values={{ date: moment(change.effectiveFrom).format('ll') }}
        />
      </Typography>
      {change.hasTransitionalRegulation === true && (
        <Typography>
          <FormattedMessage id="component.law-change.has-transitional-regulation" />
        </Typography>
      )}
      {change.reprint && (
        <Typography>
          <FormattedMessage id="component.law-change.reprint" values={{ reprint: change.reprint }} />
        </Typography>
      )}

      {(change.includeInSendouts && (
        <Typography>
          <FormattedMessage id="component.law-change.included-in-sendouts" />
        </Typography>
      )) || (
        <Typography>
          <FormattedMessage id="component.law-change.not-included-in-sendouts" />
        </Typography>
      )}
      {shouldAcknowledge && (
        <>
          <Grid md={6} xs={12} item style={{ marginTop: '2em', marginBottom: '1em' }}>
            <TextField
              label={formatMessage({ id: 'component.law-change.acknowledge.comment' })}
              name="comment"
              errorTexts={[]}
              onChange={(val) => setComment(val?.value || '')}
            />
          </Grid>
          <Grid item>
            <Grid container justify="flex-end">
              <Button variant="outlined" onClick={handleAcknowledge} startIcon={<Icon>check</Icon>}>
                <FormattedMessage id="component.law-change.acknowledge.button" defaultMessage="Acknowledge" />
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
