import React, { forwardRef, memo, useCallback, useState } from 'react';
import TextField from './TextField';
import { useIntl } from 'react-intl';
import { FormFieldRef, InputFieldProps } from './Field.props';
import { TypedFormFieldValue } from '../Form/Form.types';
import { shallowCompare } from './shallowCompare';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isEmailValid = (email: string | undefined, required?: boolean) => {
  if (email === undefined || email.length === 0) {
    if (required !== undefined) {
      return !required;
    }
    return true;
  }
  return email !== undefined && email.match(EMAIL_REGEX) !== null;
};

export const EmailField = memo(
  forwardRef<FormFieldRef<string>, InputFieldProps>((props, ref) => {
    const intl = useIntl();
    const { required, onChange } = props;
    const [isValid, setValid] = useState<boolean>(false);
    const [errorTexts, setErrorTexts] = useState<string[]>([]);
    const handleChange = useCallback(
      (email: TypedFormFieldValue<string> | undefined) => {
        const validFormat = isEmailValid(email?.value);
        setValid(validFormat);
        setErrorTexts(!validFormat ? [intl.formatMessage({ id: 'form.wrongFormat' })] : []);
        if (onChange) {
          if (!required && (email === undefined || email.value === '')) {
            onChange(undefined);
          } else {
            onChange({ value: email?.value || '', isValid: isEmailValid(email?.value, required) });
          }
        }
      },
      [onChange, required, intl],
    );

    return (
      <TextField
        {...props}
        ref={ref}
        error={props.error || !isValid}
        errorTexts={[...errorTexts, ...props.errorTexts]}
        onChange={handleChange}
        type="email"
      />
    );
  }),
  (oldProps, newProps) => shallowCompare(oldProps, newProps, ['errorTexts']),
);

export default EmailField;
