import React, { FC } from 'react';
import clsx from 'clsx';
import { AppBar, Typography, Toolbar, Button, IconButton, Tooltip, Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Menu } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { useStyles } from './Header.styles';

export interface IHeaderSubMenuItem {
  title: React.ReactNode;
  to: string;
}

interface IHeaderProps {
  title: React.ReactNode;
  subMenu?: IHeaderSubMenuItem[];
  sidebarOpen?: boolean;
  handleSidebarToggle?: (open: boolean) => void;
}

export const Header: FC<IHeaderProps> = ({ title, subMenu, sidebarOpen, handleSidebarToggle }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const activeSubMenu = subMenu
    ?.filter((menu) => window.location.pathname.indexOf(menu.to) >= 0)
    .sort((a, b) => b.to.length - a.to.length)
    .find((_) => true);
  const getClassName = (menu: IHeaderSubMenuItem) => {
    return menu === activeSubMenu ? classes.active : classes.inactive;
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        className={clsx(classes.root, { [classes.shift]: sidebarOpen })}
        classes={{ positionFixed: clsx({ [classes.positionFixed]: !sidebarOpen }) }}
      >
        <Toolbar>
          <Hidden lgUp>
            <Tooltip title={formatMessage({ id: 'component.sidebar.show', defaultMessage: 'Show menu' })}>
              <IconButton
                color="inherit"
                aria-label={formatMessage({ id: 'component.sidebar.show', defaultMessage: 'Show menu' })}
                edge="start"
                onClick={() => handleSidebarToggle && handleSidebarToggle(true)}
                className={clsx(classes.menuButton, { [classes.hide]: sidebarOpen })}
              >
                <Menu />
              </IconButton>
            </Tooltip>
          </Hidden>
          <Typography variant="h2" component="h1">
            {title}
          </Typography>
        </Toolbar>
        {subMenu && (
          <Toolbar className={classes.subMenu} component="nav" variant="dense" disableGutters>
            <div className={classes.subMenuWrapper}>
              {subMenu.map((menu, index) => (
                <Button
                  key={`menu-item-${index}`}
                  className={`${classes.subMenuButton} ${getClassName(menu)}`}
                  component={Link}
                  to={menu.to}
                  variant="text"
                >
                  {menu.title}
                </Button>
              ))}
            </div>
          </Toolbar>
        )}
      </AppBar>
      {/* Needed for page content to be placed below header */}
      <div className={classes.toolbar} />
      {subMenu && <div className={classes.subMenuToolbar} />}
    </>
  );
};
