import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from '../../../../Components';
import { CompliancesDue as LawCompliancesDue } from './LawCompliances/Due';
import { CompliancesDone as LawCompliancesDone } from './LawCompliances/Done';
import { CompliancesDue as RequirementCompliancesDue } from './RequirementCompliances/Due';
import { CompliancesDone as RequirementCompliancesDone } from './RequirementCompliances/Done';
import { ChangesToAcknowledge } from './Changes/Unacknowledged';
import { AcknowledgedChanges } from './Changes/Acknowledged';
import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';

export const Statistics = () => {
  const { formatMessage } = useIntl();
  const { setHeader } = useContext(ModuleContext);
  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.statistics.header', defaultMessage: 'Statistics' }), [
      {
        title: formatMessage({ id: 'law-portal.statistics.sub-menu.changes.unacknowledged' }),
        to: `/law-portal/statistics/changes/unacknowledged`,
      },
      {
        title: formatMessage({ id: 'law-portal.statistics.sub-menu.changes.acknowledged' }),
        to: `/law-portal/statistics/changes/acknowledged`,
      },
      {
        title: formatMessage({ id: 'law-portal.statistics.sub-menu.compliances.due' }),
        to: `/law-portal/statistics/law-compliances/due`,
      },
      {
        title: formatMessage({ id: 'law-portal.statistics.sub-menu.compliances.done' }),
        to: `/law-portal/statistics/law-compliances`,
      },
      {
        title: formatMessage({ id: 'law-portal.statistics.sub-menu.requirement-compliances.due' }),
        to: `/law-portal/statistics/requirement-compliances/due`,
      },
      {
        title: formatMessage({ id: 'law-portal.statistics.sub-menu.requirement-compliances.done' }),
        to: `/law-portal/statistics/requirement-compliances`,
      },
    ]);
  }, [setHeader, formatMessage]);
  return (
    <Container fullWidth>
      <Switch>
        <Route path="/law-portal/statistics/changes/unacknowledged" component={ChangesToAcknowledge} />
        <Route path="/law-portal/statistics/changes/acknowledged" component={AcknowledgedChanges} />
        <Route path="/law-portal/statistics/law-compliances/due" exact component={LawCompliancesDue} />
        <Route path="/law-portal/statistics/law-compliances" exact component={LawCompliancesDone} />
        <Route path="/law-portal/statistics/requirement-compliances/due" exact component={RequirementCompliancesDue} />
        <Route path="/law-portal/statistics/requirement-compliances" exact component={RequirementCompliancesDone} />
        <Route path="/law-portal/statistics">
          <Redirect to="/law-portal/statistics/changes/unacknowledged" />
        </Route>
      </Switch>
    </Container>
  );
};
