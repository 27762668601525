import { Typography } from '@material-ui/core';
import moment from 'moment';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useStyles } from './RepealableName.styles';

interface IProps {
  name: string;
  effectiveTo: Date | undefined | null;
}
export const RepealableName: FC<IProps> = ({ name, effectiveTo }) => {
  const classes = useStyles();
  if (effectiveTo && moment(effectiveTo).toDate() < new Date()) {
    return (
      <div className={classes.root}>
        <Typography className={classes.name} variant="body2">
          {name}
        </Typography>
        <Typography className={classes.repealedLabel} color="primary" variant="overline" component="div">
          <FormattedMessage
            id="component.repealable-name.effective-to"
            values={{ effectiveTo: moment(effectiveTo).format('ll') }}
          />
        </Typography>
      </div>
    );
  }

  return <Typography variant="body2">{name}</Typography>;
};
