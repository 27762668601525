import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { CustomerTable } from './CustomerTable';
import { CreateCustomer } from './CreateCustomer';
import { UpdateCustomer } from './UpdateCustomer';
import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';
import { AccessLevel, AdminFeature, UserContext } from '../../../../Context/UserContext/UserContext';
import { ReinviteCustomerUser } from './ReinviteCustomerUser';
export const Customers = () => {
  const { formatMessage } = useIntl();
  const { setHeader } = useContext(ModuleContext);
  const { hasAccessToFeature } = useContext(UserContext);
  useEffect(() => {
    setHeader(formatMessage({ id: 'admin.customers.header', defaultMessage: 'Customers' }));
  });
  return (
    <Switch>
      <Route exact path="/admin/customers" component={CustomerTable} />
      {hasAccessToFeature(AdminFeature.Administrate, AccessLevel.Manage) && (
        <Route exact path="/admin/customers/create" component={CreateCustomer} />
      )}
      {hasAccessToFeature(AdminFeature.Administrate, AccessLevel.Manage) && (
        <Route exact path="/admin/customers/:id(\d+)" component={UpdateCustomer} />
      )}
      {hasAccessToFeature(AdminFeature.Administrate, AccessLevel.Manage) && (
        <Route exact path="/admin/customers/reinvite" component={ReinviteCustomerUser} />
      )}
      <Route path="/admin/customers">
        <Redirect to="/admin/customers" />
      </Route>
    </Switch>
  );
};
