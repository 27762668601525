import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Loading } from '../../../../../Components';
import {
  ListFilter,
  createSelectFilter,
  createMultiSelectFilter,
} from '../../../../../Components/ListFilter/ListFilter';
import { SelectOption } from '../../../../../Components/Form/Form.types';
import { useAptorApi } from '../../../../../Api';
import { entityToSelectOption } from '../../../../../Utilities';

interface IOptions {
  areas: SelectOption[];
  categories: SelectOption[];
  tags: SelectOption[];
  statuses: SelectOption[];
}

interface IProps {
  setFilter: (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => void;
}

export const LawListFilter: FC<IProps> = ({ setFilter }) => {
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const [options, setOptions] = useState<IOptions | null>(null);

  useEffect(() => {
    Promise.all([
      api.getCustomerLawSettings('areas'),
      api.getCustomerLawSettings('categories'),
      api.getCustomerLawSettings('tags'),
      api.getCustomerLawSettings('status'),
    ]).then(([areas, categories, tags, statuses]) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setOptions({
        areas: areas.items.map(entityToSelectOption),
        categories: categories.items.map(entityToSelectOption),
        tags: tags.items.map(entityToSelectOption),
        statuses: statuses.items.map(entityToSelectOption),
      });
    });
  }, [api, abortController]);

  const areaFilter = createSelectFilter('area', formatMessage({ id: 'utils.filters.area' }), options?.areas);
  const categoryFilter = createSelectFilter('category', formatMessage({ id: 'utils.filters.category' }), options?.categories);
  const tagFilter = createMultiSelectFilter(
    'tag',
    formatMessage({ id: 'utils.filters.tags' }),
    options?.tags,
    (option) => option.value.toString(),
  );
  const statusFilter = createSelectFilter(
    'status',
    formatMessage({ id: 'utils.filters.status' }),
    options?.statuses,
    (opt) => opt.value?.toString(),
    options?.statuses?.find((x) => x.value === 1 /* Active */),
  );

  if (options === null) {
    return <Loading />;
  }

  return <ListFilter filters={[areaFilter, categoryFilter, tagFilter, statusFilter]} setFilter={setFilter} />;
};
