import { makeStyles, Theme, fade } from '@material-ui/core';
import { inputHeight } from '../../../../../Theme/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  imageUploader: {
    '& .fileContainer': { boxShadow: 'none' },
    '& .deleteImage': {
      backgroundColor: theme.palette.grey[700],
      fontSize: '1rem',
    },
    '& p': {
      fontSize: '1rem',
    },
    '& .uploadPictureContainer': {
      width: '100%',
    },
    '& .uploadPicturesWrapper': {
      maxWidth: '25rem',
    },
    '& .chooseFileButton': {
      padding: '6px 16px', //Same as MuiButton
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      fontFamily: theme.typography.fontFamily,
      fontSize: '1rem',
      fontWeight: theme.typography.fontWeightBold,
      height: inputHeight,
      borderRadius: theme.shape.borderRadius,
      textTransform: 'uppercase',
      transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.short,
      }),
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, theme.palette.action.hoverOpacity),
      },
      '&:focus': {
        backgroundColor: fade(theme.palette.primary.main, theme.palette.action.focusOpacity),
      },
    },
    '& .errorsContainer': {
      color: theme.palette.error.main,
    },
  },

  image: {
    maxWidth: '25rem',
    border: `1px solid ${theme.palette.grey[300]}`,
  },
}));
