import { createMuiTheme, fade } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

// For overriding datepicker css
type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const baseFontSize = 14;
const borderRadius = '4px';
export const inputHeight = '38px'; //Same height as Select

const primaryMain = '#c4122f';
const primaryLight = '#f7f0f1';
const primaryDark = '#a41129';

const theme = createMuiTheme({
  breakpoints: {
    unit: 'rem',
    values: {
      xs: 0,
      sm: 600 / baseFontSize,
      md: 960 / baseFontSize,
      lg: 1280 / baseFontSize,
      xl: 1920 / baseFontSize,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        '&$focusVisible': {
          backgroundColor: fade(primaryMain, 0.08),
        },
        height: inputHeight,
      },
      label: {
        fontWeight: 700,
        whiteSpace: 'nowrap',
      },
    },
    MuiCard: {
      root: {
        overflow: 'visible',
      },
    },
    MuiDialog: {
      // Issue with dialog widths and custom breakpoint units: https://github.com/mui-org/material-ui/issues/21476
      paperWidthSm: {
        maxWidth: `${600 / baseFontSize}rem`,
      },
      paperWidthMd: {
        maxWidth: `${960 / baseFontSize}rem`,
      },
      paperWidthLg: {
        maxWidth: `${1480 / baseFontSize}rem`,
      },
      paperWidthXl: {
        maxWidth: `${1920 / baseFontSize}rem`,
      },
    },
    MuiIconButton: {
      root: {
        padding: '0.5rem',
      },
    },
    MuiInputAdornment: {
      root: {
        '& svg': {
          fontSize: '1.5rem',
        },
        '& button:hover': {
          color: '#000',
          backgroundColor: 'unset',
        },
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          '&:hover': {
            borderColor: grey[400],
          },
        },
      },
      input: {
        borderRadius: borderRadius,
        fontSize: '1rem',
      },
      formControl: {
        backgroundColor: '#fff',
        borderRadius: borderRadius,
        border: `1px solid ${grey[400]}`,
        transition: `all 100ms`,
        paddingLeft: '0.5rem',
        '&:hover': {
          borderColor: primaryMain,
        },
        height: inputHeight,
      },
      inputMultiline: {
        paddingLeft: '0.5rem',
      },
      multiline: {
        height: 'auto',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
      },
    },
    MuiListItem: {
      secondaryAction: {
        paddingRight: '7.5rem',
      },
      root: {
        '&$selected': {
          backgroundColor: primaryLight,
          color: primaryMain,
          '&:hover': {
            backgroundColor: fade(primaryMain, 0.12),
          },
          '&$focusVisible': {
            backgroundColor: fade(primaryMain, 0.12),
            color: primaryDark,
          },
        },
        '&$focusVisible': {
          backgroundColor: fade(primaryMain, 0.12),
          color: primaryDark,
        },
      },
      button: {
        '&:hover': {
          backgroundColor: fade(primaryMain, 0.04),
          color: primaryDark,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        textTransform: 'capitalize',
      },
      switchHeader: {
        textTransform: 'capitalize',
      },
    },
    MuiPickerDTToolbar: {
      separator: {
        marginBottom: '0.25rem',
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        textTransform: 'capitalize',
      },
    },
    MuiTypography: {
      root: {
        overflowWrap: 'break-word',
      },
    },
  },
  palette: {
    primary: {
      main: primaryMain,
      light: primaryLight,
      dark: primaryDark,
    },
    secondary: {
      main: grey[300],
    },
    background: {
      default: '#fbfbfb',
    },
  },
  props: {
    MuiButton: {
      color: 'primary',
      disableElevation: true,
      disableFocusRipple: true,
      variant: 'contained',
    },
    MuiCheckbox: {
      color: 'primary',
    },
  },
  spacing: (factor) => `${(8 / baseFontSize) * factor}rem`,
  typography: {
    fontFamily: "'Work Sans', 'Arial', 'sans-serif'",
    fontSize: baseFontSize,
    fontWeightBold: 600,
    h1: {
      fontSize: '3rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    overline: {
      fontWeight: 600,
    },
  },
});

export default theme;
