import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAptorApi } from '../../../../../../Api';
import { IGridQueryRequest } from '../../../../../../Api/AptorApi';
import { CompanyUnits, ICompanyUnit } from '../../CompanyUnits.List';

export const RequirementCompanyUnits: FC<{ reqId: number }> = ({ reqId }) => {
  const { api } = useAptorApi();
  const history = useHistory();
  const dataFetcher = useCallback(
    async (request: IGridQueryRequest) => {
      const result = await api.searchRequirementCompanyUnits<ICompanyUnit>(reqId, request);
      return result;
    },
    [reqId, api],
  );

  const remove = useCallback(
    async (id: number) => {
      await api.removeRequirementCompanyUnit(reqId, id);
    },
    [reqId, api],
  );

  return (
    <CompanyUnits
      addLink={`/organization/law-portal/other-requirements/${reqId}/company-units/new`}
      dataFetcher={dataFetcher}
      edit={(id: number) => history.push(`/organization/law-portal/other-requirements/${reqId}/company-units/${id}`)}
      remove={remove}
    />
  );
};
