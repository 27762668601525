import React from 'react';
import clsx from 'clsx';
import { useStyles } from './Logo.styles';

interface ILogoProps {
  src: string;
  alt?: string;
  small?: boolean;
  disablePadding?: boolean;
}

export const Logo = (props: ILogoProps) => {
  const { src, alt, small, disablePadding } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        className={clsx(classes.img, { [classes.small]: small }, { [classes.disablePadding]: disablePadding })}
        src={src}
        alt={alt}
      />
    </div>
  );
};
