import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderWidth: '2px',
  },
  list: {
    padding: 0,
  },
}));
