import React, { useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { Panel, Container } from '../../../../Components';
import { General } from './General';
import { Help } from './Help';
import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';

export const Settings = () => {
  const { setHeader } = useContext(ModuleContext);
  const { formatMessage } = useIntl();
  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.settings.header', defaultMessage: 'Settings' }));
  }, [setHeader, formatMessage]);
  return (
    <>
      <Container>
        <Grid container spacing={4} direction="column" justify="space-evenly">
          <Grid item>
            <Panel titleKey="law-portal.settings.general" accordion>
              <General />
            </Panel>
          </Grid>
          <Grid item>
            <Panel titleKey="law-portal.settings.help" accordion>
              <Help />
            </Panel>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
