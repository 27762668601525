import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Query } from 'material-table';
import { MTable, Panel } from '../../../../../../Components';
import { IGridQueryRequest, OrderDirection } from '../../../../../../Api/AptorApi';
import { useAptorApi } from '../../../../../../Api';

interface IUnacknowledgedChange {
  changeId: number;
  changeNumber: string;
  complianceUser: string;
}

export const UnacknowledgedChanges: FC<{ lawId: number }> = ({ lawId }) => {
  const { api } = useAptorApi();
  const { formatMessage } = useIntl();

  const handleDataFetch = useCallback(
    async (query: Query<IUnacknowledgedChange>) => {
      return await api.searchUnacknowledgedLawChanges<IUnacknowledgedChange>(lawId, {
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'effectiveFrom',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
      } as IGridQueryRequest);
    },
    [lawId, api],
  );

  return (
    <Panel titleKey="law-portal.our-laws.changes.unacknowledged-changes.label">
      <MTable
        hideToolbar
        columns={[
          {
            title: formatMessage({ id: 'law-portal.our-laws.changes.unacknowledged-changes.table.number' }),
            field: 'changeNumber',
          },
          {
            title: formatMessage({ id: 'law-portal.our-laws.changes.unacknowledged-changes.table.complianceUsers' }),
            field: 'complianceUser',
            sorting: false,
          },
        ]}
        data={handleDataFetch}
      />
    </Panel>
  );
};
