import { Suspense } from 'react';
import React from 'react';
import AdministrationModule from './AdministrationModule';

// const AdministrationModule = React.lazy(() => import("./AdministrationModule"));

const AdministrationModuleProxy = () => (
  <div>
    <Suspense fallback={<div />}>
      <AdministrationModule />
    </Suspense>
  </div>
);

export default AdministrationModuleProxy;
