import { List, ListItem, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useRouteMatch } from 'react-router-dom';
import { useAptorApi } from '../../../../Api';
import { INamedEntity } from '../../../../Api/AptorApi';
import { Container, Loading, Panel, BackButton } from '../../../../Components';

type AuditType = 'create' | 'update' | 'delete';
type PropertyChange = IPropertyChange | IListPropertyChange;
interface IAudit {
  entity: INamedEntity;
  user: INamedEntity;
  impersonator?: INamedEntity;
  type: AuditType;
  state: PropertyChange[];
  relatedAudits: IRelatedAudit[];
  createdDateUtc: Date;
}

type auditValueType = boolean | Date | string | number;
interface IPropertyChange {
  displayName: string;
  previousValue?: auditValueType;
  newValue?: auditValueType;
  valueType: 'stateful';
}

interface IListPropertyChange {
  displayName: string;
  added: string[];
  removed: string[];
  valueType: 'list';
}

interface IRelatedAudit {
  id: number;
  type: AuditType;
  entity: INamedEntity;
}

const AuditStatefulPropertyChange: FC<IPropertyChange & { type: AuditType }> = ({
  displayName,
  previousValue,
  newValue,
  type,
}) => {
  if (previousValue === undefined && newValue === undefined) {
    return null;
  }
  return (
    <FormattedMessage
      id={`admin.audit.view.property-change.stateful.${type}`}
      values={{ displayName, previousValue: `${previousValue}`, newValue: `${newValue}` }}
    />
  );
};

const AuditListPropertyChange: FC<IListPropertyChange & { type: AuditType }> = ({
  displayName,
  added,
  removed,
  type,
}) => {
  const { formatMessage } = useIntl();

  if (added.length === 0 && removed.length === 0) {
    return null;
  }

  const wasAdded =
    added.length > 0
      ? added.join(', ')
      : formatMessage({ id: 'admin.audit.view.property-change.list.none-placeholder' });
  const wasRemoved =
    removed.length > 0
      ? removed.join(', ')
      : formatMessage({ id: 'admin.audit.view.property-change.list.none-placeholder' });
  return (
    <FormattedMessage
      id={`admin.audit.view.property-change.list.${type}`}
      values={{ displayName, added: wasAdded, removed: wasRemoved }}
    />
  );
};

export const AuditView = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api, abortController } = useAptorApi();
  const [audit, setAudit] = useState<IAudit>();

  useEffect(() => {
    api.getAudit<IAudit>(id).then((data) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setAudit(data);
    });
  }, [api, abortController, id]);

  if (audit === undefined) {
    return <Loading />;
  }

  return (
    <Container>
      <BackButton link="/admin/audit" />
      <Panel titleKey="admin.audit.view.header" titleValues={{ entity: audit.entity.name, type: audit.type }}>
        <Typography>
          <FormattedMessage id={`admin.audit.view.property-changes.${audit.type}.label`} />
        </Typography>
        <List>
          {audit.state.map((property) => (
            <ListItem>
              {property.valueType === 'stateful' && <AuditStatefulPropertyChange {...property} type={audit.type} />}
              {property.valueType === 'list' && <AuditListPropertyChange {...property} type={audit.type} />}
            </ListItem>
          ))}
        </List>
        {audit.relatedAudits.length > 0 && (
          <>
            <Typography>
              <FormattedMessage id="admin.audit.view.related-audits.label" />
            </Typography>
            <List>
              {audit.relatedAudits.map((audit) => (
                <ListItem>
                  <Link to={`/admin/audit/${audit.id}`}>
                    <FormattedMessage
                      id="admin.audit.view.related-audits.link"
                      values={{ entity: audit.entity.name }}
                    />
                  </Link>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Panel>
    </Container>
  );
};
