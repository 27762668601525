import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  loadingContainer: {
    position: 'absolute',
    width: '100%',
    marginTop: theme.spacing(20),
    pointerEvents: 'none',
  },
}));
