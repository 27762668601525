import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Form } from '../../../../Components/Form';
import { AptorApi, useAptorApi } from '../../../../Api';
import { useIntl } from 'react-intl';
import { useFormField } from '../../../../Components/Form/FormField';
import { FormValues, SelectOption } from '../../../../Components/Form/Form.types';
import { UserContext } from '../../../../Context/UserContext/UserContext';
import { OptionsType } from 'react-select';

export const General = () => {
  const intl = useIntl();
  const { api, abortController } = useAptorApi();
  const { mailSettings, language, fetchUser} = useContext(UserContext)
  const [availableLanguages, setAvailableLanguages] = useState<OptionsType<SelectOption>>([]);
  const [availableMailSettings, setAvailableMailSettings] = useState<OptionsType<SelectOption>>([]);

  const formatMessage = useCallback(
    (id: string) => {
      return intl.formatMessage({ id: id, defaultMessage: id });
    },
    [intl],
  );

  const languageField = useFormField({
    label: formatMessage('form.language'),
    name: 'language',
    type: 'single-select',
    placeholder: formatMessage('form.selectLanguage'),
    options: availableLanguages,
    initialState: { value: language.value, label: language.displayName },
    required: true,
    hideRequiredAsterisk: true,
  });

  const mailSettingsField = useFormField({
    label: formatMessage('form.mailSettings'),
    name: 'mailSettings',
    type: 'radio-select',
    placeholder: formatMessage('form.selectMailSettings'),
    options: availableMailSettings,
    initialState: { value: mailSettings.value, label: mailSettings.displayName },
    required: true,
  });

  const setMailSettings = mailSettingsField.setValue;
  const setLanguage = languageField.setValue;

  useEffect(() => {
    setMailSettings({ value: mailSettings.value, label: mailSettings.displayName });
    setLanguage({ value: language.value, label: language.displayName });
  }, [setMailSettings, setLanguage, mailSettings, language]);

  useEffect(() => {
    const fetchAvailableLanguages = async () => {
      const result = await api.getLanguages();
      const languages = result.items.map((l) => ({
        value: l.id,
        label: l.name,
      }));

      setAvailableLanguages(languages);
    };

    const fetchAvailableMailSettings = async () => {
      const result = await api.getMailSettings();
      const mailSettings = result.items.map((l) => ({
        value: l.id,
        label: l.name,
      }));

      setAvailableMailSettings(mailSettings);
    };

    fetchAvailableLanguages();
    fetchAvailableMailSettings();
  }, [formatMessage, api, abortController]);

  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: () => void) => {
    await api.updateSettings(data);
    onSuccess();
    if (api.abortController?.signal.aborted){
      return;
    }
    await fetchUser();
  };

  return (
    <>
      <Form
        submit={handleSubmit}
        groups={[
          {
            grid: {
              type: 'column',

              items: [
                { type: 'row', items: [languageField, null, null] },
                { items: [mailSettingsField], type: 'row' },
              ],
            },
          },
        ]}
      />
    </>
  );
};
