import { Button, FormControl, Grid, Typography } from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import { ValueType } from 'react-select';
import { Select } from '../../../../../Components';
import { SelectOption } from '../../../../../Components/Form/Form.types';
import { useStyles } from '../../../../../Components/Field/Field.styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { IState } from './CustomerLaws.Update.State';
import { entityToSelectOption } from '../../../../../Utilities';

interface IProps {
  state: IState;
  onTypeSelected: (value: SelectOption) => void;
  onConnectionSelected: (value?: SelectOption) => void;
  onUpdateClicked: () => void;
}

export const UpdateCustomerLawsActions: FC<IProps> = ({
  state,
  onTypeSelected,
  onConnectionSelected,
  onUpdateClicked,
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const connectionsHaveChanged = useMemo(
    () => state.connectionAddedToLaws.length > 0 || state.connectionRemovedFromLaws.length > 0,
    [state.connectionAddedToLaws, state.connectionRemovedFromLaws],
  );

  const disableUpdate = useMemo(
    () => state.connection === undefined || connectionsHaveChanged === false,
    [state.connection, connectionsHaveChanged],
  );

  const onChangeType = (selected: ValueType<SelectOption>) => {
    onTypeSelected(selected as SelectOption);
  };

  const onChangeSelectedOption = (selected: ValueType<SelectOption>) => {
    onConnectionSelected(selected as SelectOption);
  };

  return (
      <Grid container direction="row" spacing={1} alignItems="flex-end">
        <Grid item xs={3}>
          <FormControl className={classes.formControl}>
            <Typography className={classes.label} component="label">
              <FormattedMessage id="law-portal.manage.update-laws.type" />
            </Typography>
            <Select
              selectProps={{
                options: state.typeOptions,
                placeholder: formatMessage({ id: 'law-portal.manage.update-laws.type.placeholder' }),
                value: state.type ? { label: state.type.name, value: state.type.key } : null,
                onChange: onChangeType,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl className={classes.formControl}>
            <Typography className={classes.label} component="label">
              <FormattedMessage id="law-portal.manage.update-laws.connection" />
            </Typography>
            <Select
              selectProps={{
                isClearable: true,
                options: state.connectionOptions,
                placeholder: formatMessage({ id: 'law-portal.manage.update-laws.connection.placeholder' }),
                value: state.connection ? entityToSelectOption(state.connection) : null,
                onChange: onChangeSelectedOption,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <Button disabled={disableUpdate} onClick={onUpdateClicked}>
            <FormattedMessage id="law-portal.manage.update-laws.update.button" />
          </Button>
        </Grid>
      </Grid>
  );
};
