import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingRight: theme.spacing(20),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  hidden: {
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.grey[100],
  },
  container: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
}));
