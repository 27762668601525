import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ImageUploader from 'react-images-upload';
import { Button, Grid, Typography } from '@material-ui/core';
import { Delete, Publish } from '@material-ui/icons';
import { Loading } from '../../../../../Components';
import { useAptorApi } from '../../../../../Api';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../Context/UserContext/UserContext';
import { useSnackbar } from 'notistack';
import { useStyles } from './LogotypeForm.styles';

export const LogotypeForm = (props: {
  type: 'customer' | 'companyUnit';
  id?: number;
  currentLogo?: string | null;
  onLogoChanged: () => void;
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { api, abortController } = useAptorApi();
  const { hasAccessToFeature } = useContext(UserContext);
  const [replace, setReplace] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const canManage = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);

  const handleChange = (files: File[]) => {
    setFile(files[0]);
  };

  const deleteLogo = async () => {
    setLoading(true);

    if (props.type === 'companyUnit' && props.id) {
      await api.deleteCompanyUnitLogotype(props.id);
    }
    if (props.type === 'customer') {
      await api.deleteCustomerLogotype();
    }
    if (abortController.current.signal.aborted) {
      return;
    }

    enqueueSnackbar(formatMessage({ id: 'utils.image-upload.deleted' }));
    setLoading(false);
    setReplace(false);
    props.onLogoChanged();
  };

  const handleUpload = async () => {
    if (!file) {
      return;
    }

    setLoading(true);
    if (props.type === 'companyUnit' && props.id) {
      await api.uploadCompanyUnitLogotype(props.id, file);
    }
    if (props.type === 'customer') {
      await api.uploadCustomerLogotype(file);
    }
    if (abortController.current.signal.aborted) {
      return;
    }

    enqueueSnackbar(formatMessage({ id: 'utils.image-upload.success' }), { variant: 'success' });
    setLoading(false);
    setReplace(false);
    props.onLogoChanged();
  };

  const toggleReplace = () => {
    setReplace(!replace);
  };

  const getInfoKey = () => {
    if (props.type === 'customer') {
      return 'utils.image-upload.customer.info';
    }
    if (props.type === 'companyUnit') {
      return 'utils.image-upload.unit.info';
    }
  };

  return (
    (loading && <Loading />) || (
      <>
        <Typography variant="caption" align="center" component="p">
          <FormattedMessage id={getInfoKey()} />
        </Typography>
        {props.currentLogo && !replace && (
          <Grid container direction="column" spacing={8}>
            <Grid item>
              <Grid container justify="center">
                <img className={classes.image} alt="logo" src={`data:image/png;base64, ${props.currentLogo}`}></img>
              </Grid>
            </Grid>
            {canManage && (
              <Grid item>
                <Grid container justify="flex-end" spacing={2}>
                  <Grid item>
                    <Button color="secondary" onClick={deleteLogo} startIcon={<Delete />}>
                      {formatMessage({ id: 'utils.image-upload.delete' })}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={toggleReplace}>{formatMessage({ id: 'utils.image-upload.replace' })}</Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        {canManage && (!props.currentLogo || replace) && (
          <>
            <ImageUploader
              singleImage
              withIcon
              withPreview
              buttonText={formatMessage({ id: 'utils.image-upload.chooseImage' })}
              label={formatMessage({ id: 'utils.image-upload.description' })}
              fileSizeError={formatMessage({ id: 'utils.image-upload.too-big' })}
              fileTypeError={formatMessage({ id: 'utils.image-upload.wrong-fileformat' })}
              className={classes.imageUploader}
              imgExtension={['.jpg', '.gif', '.png']}
              accept="image/x-png,image/gif,image/jpeg"
              maxFileSize={200000}
              onChange={handleChange}
            />
            <Grid container justify="flex-end" spacing={2}>
              {props.currentLogo && (
                <Grid item>
                  <Button color="secondary" onClick={toggleReplace}>
                    {formatMessage({ id: 'utils.image-upload.cancel' })}
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button disabled={file === undefined} onClick={handleUpload} startIcon={<Publish />}>
                  {formatMessage({ id: 'utils.image-upload.upload' })}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </>
    )
  );
};
