export default {
  /* empty export in order to be import this file */
};

declare global {
  interface Array<T> {
    sortby: (predicate: (item: T) => string | Date, descending?: boolean) => Array<T>;
    replace: (predicate: (item: T) => boolean, getReplacement: (item: T) => T) => Array<T>;
    exclude: (predicate: (item: T) => boolean) => Array<T>;
  }
}

// eslint-disable-next-line
Array.prototype.sortby = function <T>(this: T[], predicate: (item: T) => string | Date, descending?: boolean) {
  return this.sort((a, b) => {
    if (predicate(a) < predicate(b)) {
      return descending ? 1 : -1;
    }
    if (predicate(a) > predicate(b)) {
      return descending ? -1 : 1;
    }
    return 0;
  });
};

// eslint-disable-next-line
Array.prototype.replace = function <T>(this: T[], predicate: (item: T) => boolean, getReplacement: (item: T) => T) {
  const newArray = [...this];
  const matches = newArray.filter(predicate);
  matches.forEach((item) => {
    const index = newArray.findIndex((x) => x === item);
    newArray.splice(index, 1, getReplacement(item));
  });
  return newArray;
};

// eslint-disable-next-line
Array.prototype.exclude = function <T>(this: T[], predicate: (item: T) => boolean) {
  return this.filter(x => !predicate(x));
};
